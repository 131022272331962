import { getRequest } from "../../helpers/request";
// import { ENV } from "../../../config";
import {
  SET_NFTS,
  SET_POPULAR_NFTS,
  SET_COMING_SOON_NFTS,
  SHOW_NFTS_LIST_LOADER,
  SHOW_POPULAR_NFTS_LIST_LOADER,
  SHOW_COMING_SOON_NFTS_LIST_LOADER,
  HIDE_COMING_SOON_NFTS_LIST_LOADER,
  HIDE_NFTS_LIST_LOADER,
  HIDE_POPULAR_NFTS_LIST_LOADER,
  SET_NFT,
  SHOW_NFT_LIST_LOADER,
  HIDE_NFT_LIST_LOADER,
} from "../constants";
import { ENV } from "../../config";
export const getNFTs = (data) => (dispatch) => {
  //   let url = ENV.serverUrl + "/client/activity/live";
  let url = ENV.apiBaseUrl + "/nfts/";
  dispatch({ type: SHOW_NFTS_LIST_LOADER });
  getRequest(url, data)
    .then((res) => {
      console.log(res, "res of NFTs");
      dispatch({ type: SET_NFTS, payload: res.data });
      dispatch({ type: HIDE_NFTS_LIST_LOADER });
    })
    .catch((err) => {
      console.log(err, "err=>");
      dispatch({ type: HIDE_NFTS_LIST_LOADER });
    });
};

export const getNFT =
  (id, params = null) =>
  (dispatch) => {
    //   let url = ENV.serverUrl + "/client/activity/live";
    let url = ENV.apiBaseUrl + `/nfts/${id}`;
    dispatch({ type: SHOW_NFT_LIST_LOADER });
    getRequest(url, params)
      .then((res) => {
        console.log(res, "res of single NFT");
        dispatch({ type: SET_NFT, payload: res.data });
        dispatch({ type: HIDE_NFT_LIST_LOADER });
      })
      .catch((err) => {
        console.log(err, "err=>");
        dispatch({ type: HIDE_NFT_LIST_LOADER });
      });
  };

export const getPopularNFTs = (data) => (dispatch) => {
  let url = ENV.apiBaseUrl + "/nfts/popular-nfts";
  dispatch({ type: SHOW_POPULAR_NFTS_LIST_LOADER });
  getRequest(url, data)
    .then((res) => {
      console.log(res, "res of NFTs");
      dispatch({ type: SET_POPULAR_NFTS, payload: res.data });
      dispatch({ type: HIDE_POPULAR_NFTS_LIST_LOADER });
    })
    .catch((err) => {
      console.log(err, "err=>");
      dispatch({ type: HIDE_POPULAR_NFTS_LIST_LOADER });
    });
};

export const getComingSoonNFTs = (data) => (dispatch) => {
  let url = ENV.apiBaseUrl + "/nfts/coming-soon-nfts";
  dispatch({ type: SHOW_COMING_SOON_NFTS_LIST_LOADER });
  getRequest(url, data)
    .then((res) => {
      console.log(res, "res of NFTs");
      dispatch({ type: SET_COMING_SOON_NFTS, payload: res.data });
      dispatch({ type: HIDE_COMING_SOON_NFTS_LIST_LOADER });
    })
    .catch((err) => {
      console.log(err, "err=>");
      dispatch({ type: HIDE_COMING_SOON_NFTS_LIST_LOADER });
    });
};
