// @ts-ignore
import { WALLET_PROVIDER, SET_WALLET_ADDRESS ,SET_WALLET_ERROR} from '../constants';

const walletProvider = ({ provider }) => {
  return {
    type: WALLET_PROVIDER,
    payload: {
      provider,
    },
  };
};
export const setConnectedWalletAddress = (address) => (dispatch) => {
  dispatch({ type: SET_WALLET_ADDRESS, payload: address });
};
export const setWalletAddressError = (isError) => (dispatch) => {
  dispatch({ type: SET_WALLET_ERROR, payload: isError });
};

export const thunkWallet = (data, callback) => {
  return async (dispatch) => {
    try {
      await dispatch(
        walletProvider({
          provider: data?.provider,
        })
      );
      callback();
    } catch (error) {
      console.log('Error: ', error.message);
      console.log('Error: ', error);
    }
  };
};
