import HeroSection from "../../HeroSection";
import { Container } from "react-bootstrap";

import LandingLayout from "../../layout/LandingLayout";
import LeaderBoard from "../../LeaderBoard";

function LeaderBoardPage() {
  return (
    <>
      <LandingLayout>
        <HeroSection />
        <Container className='mt-5'>
          <LeaderBoard />
        </Container>
      </LandingLayout>
    </>
  );
}

export default LeaderBoardPage;
