import React, { useEffect, useState } from "react";
import Classes from "../Activity/activity.module.scss";
import { Container } from "react-bootstrap";
import MainCard from "../MainCard";
import Slider from "react-slick";
import { Link } from "react-router-dom";
import { getNFTs } from "../../store/_actions/nft_actions";
import { useSelector, useDispatch } from "react-redux";

const New = () => {
  const [limit,setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    causeFilter: "",
    typeFilter: "",
    priceFilter: "",
    dateFilter: -1,
    search: "",
    page,
    limit
  });
  const dispatch = useDispatch();
  const nfts = useSelector((state) => state.nft.nfts?.[0]);

  const settings = {
    dots: false,
    infinite: nfts?.data?.length === 1 ? false : true,
    speed: 500,
    slidesToShow: nfts?.data?.length >= 4 ? 4 : nfts?.data?.length,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    lazyLoad: "ondemand",
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    dispatch(getNFTs(params));
  }, [params.causeFilter]);
  return (
    <div className={`${Classes.activity} `}>
      <Container>
        <div className="d-flex align-items-center gap-3 mb-4">
          <h1 className={Classes.heading}>New Additions</h1>
          <Link to="/nft-listing" className={Classes.seeAllBtn}>
            See More
          </Link>
        </div>
        <Slider {...settings}>
          {nfts && nfts?.data?.length > 0 &&
            nfts?.data.map((item) => (
              <MainCard
                item={item}
                key={item?._id}
                params={params}
                setParams={setParams}
                videoKey={item?.file}
              />
            ))}
        </Slider>
      </Container>
    </div>
  );
};

export default New;
