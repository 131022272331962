import React from "react";
import Header from "../../layout/LandingLayout/Header";
import FeaturedNftDetailComp from "../../FeaturedNftDetailComp";

function FeaturedNftDetail() {
  return (
    <>
      <Header />
      <FeaturedNftDetailComp />
    </>
  );
}

export default FeaturedNftDetail;
