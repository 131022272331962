import React from "react";
import { Modal } from "antd";
import Classes from "../../FriendsDetailsComp/friendsDetails.module.scss";
import { Link } from "react-router-dom";

const CreateNFTModal = ({ isModalOpen, handleCancel, message, modalType }) => {
  return (
    <Modal open={isModalOpen} footer={null} onCancel={handleCancel}>
      <div
        className={`${Classes.modalContent} d-flex justify-content-center align-items-center gap-3 flex-column py-2`}
      >
        <h1>{modalType}</h1>
        <p className="mb-3">{message}</p>
        <div className="d-flex gap-3">
          <Link
            to="/profile"
            className={`${Classes.btn} ${Classes.reject} d-flex justify-content-center align-items-center`}
            onClick={handleCancel}
          >
            Go to Profile
          </Link>
        </div>
      </div>
    </Modal>
  );
};

export default CreateNFTModal;
