import React, { Fragment, useState, useEffect } from "react";
import Classes from "../Login/auth.module.scss";
import { Col, Row } from "antd";
import { ThreeDots } from "react-loader-spinner";
import * as Yup from "yup";
import { Formik } from "formik";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import CloseEye from "../../assets/images/icons/closeEye.svg";
import OpenEye from "../../assets/images/icons/openEye.svg";
import { verifyEmailLink } from "../../services/auth.service";
import { alertMessage } from "../../constants/alertMessage";
import Logo from "../../assets/images/section/whiteLogo.svg";

const VerifyEmail = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [loading, setIsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [passwordType2, setPasswordType2] = useState("password");

  const showPassword = (type) => {
    setPasswordType(type);
  };
  const showPassword2 = (type) => {
    setPasswordType2(type);
  };

  const checkEmailLink = async () => {
    try {
      setIsLoading(true);
      const user = await verifyEmailLink(token);
      if (user && user.data.success === true) {
        setTimeout(() => {
            if(user?.status===200){
                navigate(`/set-password/${token}`);
            }else{
                alertMessage("error", user.message)
                navigate("/login");
            }
          }, 2000);
       
      } else {
        setTimeout(() => {
        if(user?.status===410){
            alertMessage("error", user.message)
            navigate("/sign-up");
        }else{
            alertMessage("error", user.message)
            navigate("/login");
        }
          }, 2000);
      }
    } catch (error) {
      alertMessage("error", "Something went wrong.");
      setIsLoading(false);
      console.log("error.response", error.response.message);
    }
  };

  useEffect(()=>{
    checkEmailLink();
  },[])

  return (
    <div className={`${Classes.emailVerification} `}>
        <div>Redirecting</div>
            <ThreeDots
                height="30"
                width="30"
                radius="9"
                color="white"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true}
        />
    </div>
  );
};

export default VerifyEmail;
