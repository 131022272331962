import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Header from '../../layout/LandingLayout/Header';
import OrgHistory from '../../OrgHistory';
import { alertMessage } from '../../../constants/alertMessage';
import { useSelector } from 'react-redux';

const OrgProfileView = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  useEffect(() => {
    if (auth?.role === 'user') {
      navigate('/user-profile');
      alertMessage('error', 'Not allowed.');
    }
  }, []);
  return (
    <div className='bgLight'>
      <Header />
      <OrgHistory />
    </div>
  );
};

export default OrgProfileView;
