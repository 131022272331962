import React from "react";
import Classes from './organizationCard.module.scss'
import OrganizaionImg from "../../assets/images/section/organisation.svg";
import UserImg from "../../assets/images/section/userImg.svg";
import Varified from "../../assets/images/icons/varified.svg";
import EthIcon from "../../assets/images/icons/ethIcon.svg";

function OrganizationCard() {
  return (
    <div className={Classes.organizationCard}>
      <img className={Classes.mainImg} src={OrganizaionImg} alt="img" />
      <div className={Classes.userContainer}>
        <img className={Classes.userImg} src={UserImg} alt="img" />
        <img className={Classes.icon} src={Varified} alt="img" />
      </div>
      <div className={Classes.bottomInfo}>
        <div className="d-flex justify-content-center align-items-center flex-column mb-3">
          <h3 className={Classes.title}>Agung Prio Rismawan</h3>
          <p className={Classes.creator}>
            Creator by <span className={Classes.name}>BYACH</span>
          </p>
        </div>
        <div className="d-flex align-items-center justify-content-between px-4">
          <div className={Classes.border_right}>
            <span className={Classes.subtitle}>Items</span>
            <p className={Classes.value}>9.2K</p>
          </div>
          <div className={Classes.border_right}>
            <span className={Classes.subtitle}>Owner</span>
            <p className={Classes.value}>6.2K</p>
          </div>
          <div className={Classes.border_right}>
            <span className={Classes.subtitle}>Floor Price</span>
            <p
              className={`${Classes.value} d-flex align-items-center justify-content-center gap-1`}
            >
              <img src={EthIcon} alt="icon" />
              9.2K
            </p>
          </div>
          <div>
            <span className={Classes.subtitle}>Volume Trade</span>
            <p
              className={`${Classes.value} d-flex align-items-center justify-content-center gap-1`}
            >
              <img src={EthIcon} alt="icon" />
              9.2K
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrganizationCard;
