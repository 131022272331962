import React, { Fragment, useState } from "react";
import Classes from "./password.module.scss";
import * as Yup from "yup";
import { Formik } from "formik";
import { changePassword } from "../../services/auth.service";
import { ThreeDots } from "react-loader-spinner";
import { alertMessage } from "../../constants/alertMessage";
import CloseEye from "../../assets/images/icons/closeEye.svg";
import OpenEye from "../../assets/images/icons/openEye.svg";

const Password = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [passwordType, setPasswordType] = useState("password");
  const [newpasswordType, setnewPasswordType] = useState("password");
  const [repPasswordType, setrepPasswordType] = useState("password");

  const showPassword = (type) => {
    setPasswordType(type);
  };
  const showPassword1 = (type) => {
    setnewPasswordType(type);
  };
  const showPassword2 = (type) => {
    setrepPasswordType(type);
  };
  const handleChangePassword = async (data, resetForm) => {
    try {
      setIsLoading(true);
      const authToken = localStorage.getItem("authenticationToken");
      const response = await changePassword(data, authToken);
      if (response?.data?.success === true) {
        alertMessage("success", `${response?.data?.message}.`);
        setIsLoading(false);
        resetForm({ values: "" });
      } else {
        alertMessage("error", `${response?.message}.`);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alertMessage("error", error);
    }
  };
  return (
    <div className={Classes.profile}>
      <h1 className={Classes.title}>Password Details</h1>

      <Formik
        initialValues={{
          oldPassword: "",
          password: "",
          confirmPassword: "",
        }}
        validationSchema={Yup.object().shape({
          oldPassword: Yup.string().required("Old Password field is required."),
          password: Yup.string()
            .trim()
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,20}$/,
              "Password must contain at least 8 characters including one lowercase letter, one uppercase letter, and one numeric digit."
            )
            .max(20, "Password should not contain more than 15 characters.")
            .min(8, "Password must have at least 8 characters.")
            .required("New Password field is required."),
          confirmPassword: Yup.string()
            .required("Confirm Password field is required.")
            .oneOf(
              [Yup.ref("password"), null],
              "New Password and Confirm Password need to be the same."
            ),
        })}
        onSubmit={(values, { resetForm }) => {
          const data = {
            oldPassword: values.oldPassword,
            password: values.password,
            confirmPassword: values.confirmPassword,
          };
          handleChangePassword(data, resetForm);
        }}
        textAlign="center"
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="mt-8 space-y-6"
            noValidate
            autoComplete="off"
          >
            <div className={Classes.formOuter}>
              <div className="mb-3">
                <p className={Classes.label}>Old Password*</p>
                <div className={`${Classes.inputOuter}`}>
                  <input
                    className="pe-3"
                    type={passwordType}
                    name="oldPassword"
                    placeholder="Enter old password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.oldPassword}
                  />
                  {passwordType === "password" ? (
                    <img
                      onClick={() => showPassword("text")}
                      src={CloseEye}
                      alt="icon"
                    />
                  ) : (
                    <img
                      onClick={() => showPassword("password")}
                      src={OpenEye}
                      alt="icon"
                    />
                  )}
                </div>
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.oldPassword && touched.oldPassword ? (
                    <Fragment>{errors.oldPassword}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>New Password*</p>
                <div className={`${Classes.inputOuter}`}>
                  <input
                    className="pe-3"
                    type={newpasswordType}
                    name="password"
                    placeholder="Enter new password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                  />
                  {newpasswordType === "password" ? (
                    <img
                      onClick={() => showPassword1("text")}
                      src={CloseEye}
                      alt="icon"
                    />
                  ) : (
                    <img
                      onClick={() => showPassword1("password")}
                      src={OpenEye}
                      alt="icon"
                    />
                  )}
                </div>
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.password && touched.password ? (
                    <Fragment>{errors.password}</Fragment>
                  ) : null}
                </span>
              </div>

              <div className="mb-3">
                <p className={Classes.label}>Confirm Password*</p>
                <div className={`${Classes.inputOuter}`}>
                  <input
                    className="pe-3"
                    type={repPasswordType}
                    name="confirmPassword"
                    placeholder="Repeat new password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                  />
                  {repPasswordType === "password" ? (
                    <img
                      onClick={() => showPassword2("text")}
                      src={CloseEye}
                      alt="icon"
                    />
                  ) : (
                    <img
                      onClick={() => showPassword2("password")}
                      src={OpenEye}
                      alt="icon"
                    />
                  )}
                </div>
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.confirmPassword && touched.confirmPassword ? (
                    <Fragment>{errors.confirmPassword}</Fragment>
                  ) : null}
                </span>
              </div>

              <div className="mt-5">
                <button className={`${Classes.blueBtn} w-100`} type="submit">
                  {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <ThreeDots
                        height="30"
                        width="30"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    "Change Password"
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default Password;
