import React from 'react';
import LandingLayout from '../../layout/LandingLayout';
import EditCauseDetailComp from '../../EditCauseDetailComp';
const EditCause = () => {
  return (
    <>
      <LandingLayout>
        <EditCauseDetailComp title='Select/Unselect Causes'/>
      </LandingLayout>
    </>
  );
};

export default EditCause;
