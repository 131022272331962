import React, { useState } from "react";
import { Modal } from "antd";
import Classes from "../FriendsDetailsComp/friendsDetails.module.scss";

const Alert = ({ title, content, visible, onClose }) => {
  // const [visible, setVisible] = useState(true);

  const showModal = () => {
    // setVisible(true);
  };

  const handleOk = () => {
    onClose();
    // setVisible(false);
  };

  const handleCancel = () => {
    onClose();
    // setVisible(false);
    sessionStorage.removeItem('showInviteSentModal');
  };

  return (
    <>
      <Modal
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        <div
          className={`${Classes.modalContent} d-flex justify-content-center align-items-center gap-3 flex-column py-2`}
        >
          <h1>{title}</h1>
          <p className="mb-3" dangerouslySetInnerHTML={{ __html: content }} />
          <button
            className={`${Classes.btn} ${Classes.reject}`}
            onClick={handleCancel}
          >
            Close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Alert;

// import React from "react";
// import { Alert, Space } from "antd";
// import { useDispatch, useSelector } from "react-redux";
// import { setWalletAddressError } from "../../store/_actions/wallet_action";
// import Classes from './alert.module.scss'
// const NotificationAlert = () => {
//   const dispatch = useDispatch();
//  const auth= useSelector((state)=>state.auth)
//   return (
//     <div className={Classes.notifAlert}>
//     <Space
//       direction="vertical"
//       style={{
//         width: "100%",
//       }}
//     >
//       <Alert
//         message={`Incorrect Wallet Detected! We have detected that you are currently using an incorrect wallet. Please switch back to ${auth?.walletAddress} to continue using this account`}
//         closable
//         onClose={()=> dispatch(setWalletAddressError(false))}
//       />
//     </Space>
//     </div>
//   );
// };
// export default NotificationAlert;
