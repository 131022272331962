import React, { useState, useEffect } from 'react';
import Classes from './document.module.scss';
import { Container } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import Uploader from '../Uploader';
import { alertMessage } from '../../constants/alertMessage';
import { useSelector } from 'react-redux';

const DocumentComp = () => {
  const [isoDocument, setIsoDocument] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  useEffect(() => {
    if (!auth?.email) {
      navigate('/login');
      alertMessage('error', 'Authentication required.');
    }
    if (auth?.username && auth?.email) {
      navigate('/profile');
    }
  }, []);
  const handleIsoVerification = () => {
    if (isoDocument) {
      navigate('/selection', {
        state: {
          ...location?.state?.userData,
          isoDocument,
        },
      });
    } else {
      alertMessage('error', 'Document is required.');
    }
  };
  return (
    <div className={Classes.document}>
      <Container>
        <div
          className={`${Classes.mainOuter} d-flex justify-content-center align-items-center flex-column`}
        >
          <h1 className={Classes.title}>Charity NFT</h1>
          <div className={Classes.content}>
            <Row gutter={[30, 30]}>
              <Col md={12}>
                <p className={`${Classes.subTitle} mb-4`}>
                {location?.state?.userData?.fiscalSponsorEmail &&
                  location?.state?.userData?.fiscalSponsorNonProfitName ? (
                    <> Upload document for verification</>
                  ) : (
                    <> Upload verification document</>
                  )}
                
                </p>
                <p className={Classes.subTxt}>
                {location?.state?.userData?.fiscalSponsorEmail &&
                  location?.state?.userData?.fiscalSponsorNonProfitName ? (
                    <> Please upload your fiscal sponsorship agreement. Your fiscal sponsor will receive a notification email to accept your verification. Please contact your fiscal sponsor to follow up with the verification.</>
                  ) : (
                    <>  Please upload your IRS Determination Letter to verify your 501(c)(3) status.</>
                  )}
                </p>
              </Col>
              <Col md={12}>
                <h2 className={`${Classes.subTitle} mb-3`}>
                  {location?.state?.userData?.fiscalSponsorEmail &&
                  location?.state?.userData?.fiscalSponsorNonProfitName ? (
                    <> Upload Fiscal Sponsorship Agreement</>
                  ) : (
                    <> Upload IRS Determination Letter</>
                  )}
                </h2>
                <div className='mb-4'>
                  <h2 className={`${Classes.subTxt} mb-0`}>
                    <b>Upload file</b>
                  </h2>
                  <Uploader setIsoDocument={setIsoDocument} isoDocument={isoDocument} />
                </div>
              </Col>
            </Row>
            <div className='d-flex justify-content-between align-items-center'>
              <button
                className={`${Classes.blueBtn} ${Classes.back}`}
                onClick={() => navigate('/user-info')}
              >
                Back
              </button>
              <button
                className={Classes.blueBtn}
                onClick={() => handleIsoVerification()}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default DocumentComp;
