import { SET_CATEGORIES_LISTING } from '../constants';

const initialWalletState = {
  categories: null,
  allCategories:null
};

export const CategoriesReducer = (state = initialWalletState, action) => {
  switch (action.type) {
    case SET_CATEGORIES_LISTING:
      return {
        ...state,
        categories:action.payload?.categories,
        allCategories:action.payload?.allCategories
      };
    default:
      return state;
  }
};
