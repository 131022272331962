import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "antd";
import Classes from "./tabs.module.scss";
import { alertMessage } from "../../constants/alertMessage";
import { updateFiscalProject } from "../../services/auth.service";
import { useSelector, useDispatch } from "react-redux";
import { thunkLogin } from "../../store/_actions/user_action";
import useSetDefaultFiscalSponsor from "../../hooks/useSetDefaultFiscalSponsorPercentage";

const FiscalProjectFeeModal = ({ isSponsorshipModalOpen, handleCancel }) => {
  const auth = useSelector((state) => state?.auth);
  const authToken = localStorage.getItem("authenticationToken");    
  const [isLoading, setIsLoading] = useState(false);
  const [defaultSponsorshipFee, setDefaultSponsorshipFee] = useState("");
  const [sponsorshipFeeValidation, setSponsorshipFeeValidation] = useState("");
  const { connectedAddress } = useSelector((state) => state.wallet);

  const dispatch = useDispatch();
  const {setFiscalSponsor,isFiscalSponsorCompleted,fiscalSponsorSentError,fiscalSponsorTxError}=useSetDefaultFiscalSponsor()
  useEffect(()=>{
    if(isFiscalSponsorCompleted){   
      const handleUpdateFiscalSponser=async ()=>{
        const editResponse = await updateFiscalProject(auth?.id, authToken, {
          defaultSponsorshipFee: defaultSponsorshipFee,
        });
        if (editResponse?.data?.success) {
          setIsLoading(false);
          handleCancel();
          dispatch(
            thunkLogin(auth, () => {
              alertMessage("success", `${editResponse?.data?.message}`);
            })
          );
        }
      }
      handleUpdateFiscalSponser()
     
    }

  },[isFiscalSponsorCompleted])
  useEffect(()=>{
    const error=fiscalSponsorSentError||fiscalSponsorTxError
    if(error){
      setIsLoading(false)
      alertMessage("error", `${error?.message}`);
    }

  },[fiscalSponsorSentError,fiscalSponsorTxError])
  const handleSave = async () => {
    setIsLoading(true);   
    if (!connectedAddress) {
      alertMessage("error", "Please connect wallet.");
      setIsLoading(false);
      return;
    }
    if (!defaultSponsorshipFee) {
      setSponsorshipFeeValidation("Sponsorship fee is required.");
      setIsLoading(false);
      return;
    }
    if (defaultSponsorshipFee < 1 || defaultSponsorshipFee > 10) {
      setSponsorshipFeeValidation("Sponsorship fee must be between 1 to 10.");
      setIsLoading(false);
      return;
    }
    alertMessage('warning','Please do not close or refresh page during transaction')
    setSponsorshipFeeValidation("");
    const data = {
      sponsorshipPercentage: defaultSponsorshipFee * 100 || 0,
    };
    setFiscalSponsor({args:[data?.sponsorshipPercentage],from:connectedAddress})
  };
  useEffect(() => {
    if (auth?.defaultSponsorshipFee) {
      setDefaultSponsorshipFee(auth?.defaultSponsorshipFee);
    }
  }, []);
  return (
    <div>
      <Modal
        title="Set Default Sponsorship Fee"
        open={isSponsorshipModalOpen}    
        footer={null}
        onCancel={handleCancel}
        className={Classes.projModal}
      >
        <div className={`${Classes.modalInner}`}>
          <div className={Classes.formInput}>
            <input
              type="number"
              placeholder="Enter sponsorship fee percentage"
              value={defaultSponsorshipFee}
              onChange={(e) => setDefaultSponsorshipFee(e.target.value)}
              className={Classes.inputField}
            />
            <span
              className="mt-1 d-block"
              size={"small"}
              style={{ color: "red" }}
            >
              {sponsorshipFeeValidation ? (
                <Fragment>{sponsorshipFeeValidation}</Fragment>
              ) : null}
            </span>
          </div>
          <button
            className={`${Classes.singleBlueBtn} mb-3`}
            onClick={handleSave}
            disabled={isLoading}

          >
            {isLoading ? "Loading..." : "Save"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default FiscalProjectFeeModal;
