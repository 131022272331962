import React from 'react';
import Classes from './notification.module.scss';
import Notifications from '../Notifications';
import { useSelector } from 'react-redux';
import { Container } from 'react-bootstrap';

const SettingComp = () => {
  const auth = useSelector((state) => state?.auth);

  return (
    <div className={Classes.notifyOuter}>
      <div className={Classes.main}>
        <Container>
          <Notifications />
        </Container>
      </div>
    </div>
  );
};

export default SettingComp;
