import axios from 'axios';

export const generalFilters = async (params) => {
  try {
    const filters = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/general/filters`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
        params,
      }
    );
    return filters;
  } catch (error) {
    return error.response.data;
  }
};
