import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Classes from './buildOrganizationProfile.module.scss';
import { useNavigate, useLocation } from 'react-router-dom';
import { Col, Row } from 'antd';
import FormFields from './FormFields';
import UploadProfile from './UploadProfile';
import { Container } from 'react-bootstrap';
import { alertMessage } from '../../constants/alertMessage';

const BuildOrganizationProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const auth = useSelector((state) => state.auth);
  const [profilePicture, setProfilePicture] = useState('');
  const [restData, setRestData] = useState({
    file: profilePicture || '',
    role: location?.state?.role || '',
    organizationType: location?.state?.organizationType || '',
    address: location?.state?.address,
  });

  const [formFieldsData, setFormFieldsData] = useState({});

  useEffect(() => {
    if (!auth?.email) {
      navigate('/login');
      alertMessage('error', 'Authentication required.');
    }

    if (auth?.username && auth?.email) {
      navigate('/profile');
    }
  }, []);
  return (
    <Container>
      <div
        className={`${Classes.mainOuter} d-flex justify-content-center align-items-center flex-column`}
      >
        <h1 className={Classes.title}>Charity NFT</h1>
        <div className={`${Classes.content}`}>
          {/* <div className='mb-4'>
            <p className={Classes.desc}>Let’s get started!</p>
          </div> */}  
          <Row gutter={[30, 30]} className='mb-4'>
            <Col lg={9} md={24} sm={24} xs={24}>
              <UploadProfile
                setProfilePicture={setProfilePicture}
                setFormFieldsData={setFormFieldsData}
              />
            </Col>
            <Col lg={15} md={24} sm={24} xs={24}>
              <FormFields
                formFieldsData={formFieldsData}
                setFormFieldsData={setFormFieldsData}
                irsOrganizationData={location?.state?.irsOrganizationData}
                restData={restData}
                accountType={location?.state?.accountType || ''}
              />
            </Col>
          </Row>
        </div>
      </div>
    </Container>
  );
};

export default BuildOrganizationProfile;
