import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "antd";
import Classes from "./tabs.module.scss";
import { alertMessage } from "../../constants/alertMessage";
import { isoDeterminationLetterVerification } from "../../services/isoDocuments.service";
import { useSelector } from "react-redux";

const IsoDeterminationLetterModal = ({
  isoDeterminationLetterModalOpen,
  handleCancel,
  setIsoDocuments,
}) => {
  const auth = useSelector((state) => state?.auth);
  const authToken = localStorage.getItem("authenticationToken");
  const [isoDocument, setIsoDocument] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isoFileValidation, setIsoFileValidation] = useState("");

  const handleSave = async () => {
    setIsLoading(true);

    if (!isoDocument) {
      setIsoFileValidation("Letter is required.");
      setIsLoading(false);
      return;
    }
    setIsoFileValidation("");

    const data = { userId: auth?.id, authToken, isoDocument };

    const isoResponse = await isoDeterminationLetterVerification(data);
    if (isoResponse?.data?.success) {
      setIsLoading(false);
      handleCancel();
      setIsoDocuments([isoResponse?.data?.data]);
      alertMessage("success", `${isoResponse?.data?.message}`);
    } else {
      setIsLoading(false);
      handleCancel();
      alertMessage("error", `${isoResponse?.data?.message}`);
    }
  };

  const handleIsoFileUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsoDocument(file);
    }
  };

  useEffect(() => {
    setIsoDocument("");
  }, []);
  return (
    <div>
      <Modal
        title={
          auth?.organizationType?.includes("IRS")
            ? "Upload IRS Determination Letter"
            : "Upload Fiscal Sponsorship Letter"
        }
        open={isoDeterminationLetterModalOpen}
        footer={null}
        onCancel={handleCancel}
        className={Classes.projModal}
      >
        <div className={`${Classes.modalInner}`}>
          <div className={Classes.formInput}>
            <input
              type="file"
              accept="image/*"
              onChange={handleIsoFileUpload}
              className={Classes.inputField}
            />
            <span
              className="mt-1 d-block"
              size={"small"}
              style={{ color: "red" }}
            >
              {isoFileValidation ? (
                <Fragment>{isoFileValidation}</Fragment>
              ) : null}
            </span>
          </div>
          <button
            className={`${Classes.singleBlueBtn} mb-3`}
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading
              ? "Loading..."
              : auth?.organizationType?.includes("IRS")
                ? "Submit IRS determination letter for verification"
                : "Submit fiscal sponsorship letter for verification"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default IsoDeterminationLetterModal;
