import React, { Suspense, useEffect, useState } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import { routes } from "../routes/index";
import SocketContext from "../context/socket";
import Socket from "../helpers/socket";
import ScrollToTop from "./ScrollToTop";
import { useListenWalletAddress } from "../hooks/useListenWalletAddress";
import { refetchUser } from "../services/auth.service";
import { thunkLogin } from "../store/_actions/user_action";
import { useDispatch } from "react-redux";
import Head from "./Head";
import RouteNotFound from "./views/Pages/RouteNotFound"; // Import the NotFound component

const App = () => {
  const [open, setOpen] = useState(true);
  const [socket, setSocket] = useState(undefined);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useListenWalletAddress();

  useEffect(() => {
    const fetchUser = async () => {
      const authToken = localStorage.getItem("authenticationToken");
      if (authToken) {
        const resp = await refetchUser();
        if (resp) {
          dispatch(thunkLogin(resp?.data?.data, () => {}));
        }
      }
    };
    fetchUser();
  }, []);

  // useEffect(() => {
  //   const getProdPassword = localStorage.getItem("passForPROD");
  //   if (!getProdPassword) {
  //     navigate("/security");
  //   }
  // }, []);

  useEffect(() => {
    if (!socket) {
      const connectedSocket = Socket.connectSocket();
      setSocket(connectedSocket);
    }

    return () => {
      if (socket) {
        socket.disconnect();
      }
    };
  }, []);

  return (
    <SocketContext.Provider value={socket}>
      <Head />
      <Suspense fallback={<>Loading...</>}>
        <Routes>
          {routes?.map((route) => (
            <Route key={route.url} path={route?.url} element={<route.component />} />
          ))}
          {/* Add the NotFound route */}
          <Route path="*" element={<RouteNotFound />} />
        </Routes>
        <ScrollToTop />
      </Suspense>
    </SocketContext.Provider>
  );
};

export default App;
