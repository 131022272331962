// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import CONTRACT_DETAILS from "../contract/contractInfo";

const marketContractAddress = CONTRACT_DETAILS.market.address;
const marketplaceABI = CONTRACT_DETAILS.market.abi;

const useAcceptBidForUser = () => {
  //approve contract
  const {
    isError: isAcceptBidUserSentError,
    data: acceptBidUserSentTx,
    isLoading: isAcceptBidUserTxInProgress,
    isSuccess: isAcceptBidUserTxSent,
    write: acceptBidForUser,
    error: acceptBidUserSentError
  } = useContractWrite({
    address: marketContractAddress,
    abi: marketplaceABI,
    functionName: "acceptOffer", // Changed the functionName to acceptBid
  });

  const {
    isLoading: isAcceptBidUserWaiting,
    isSuccess: isAcceptBidUserCompleted,
    isError: isAcceptBidUserError,
    error: acceptBidUserTxError,
    data: acceptBidUserResp
  } = useWaitForTransaction({
    hash: acceptBidUserSentTx?.hash,
  });

  return {
    //nft operation
    isAcceptBidUserSentError,
    acceptBidUserSentTx,
    isAcceptBidUserTxInProgress,
    isAcceptBidUserTxSent,
    acceptBidForUser, // Renamed the function
    acceptBidUserSentError,
    isAcceptBidUserWaiting,
    isAcceptBidUserCompleted,
    isAcceptBidUserError,
    acceptBidUserTxError,
    acceptBidUserResp
  };
};

export default useAcceptBidForUser;
