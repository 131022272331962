import { combineReducers } from "redux";
import { ThemeReducer } from "./theme_reducer";
import { AuthReducer } from "./auth_reducer";
import { NftReducer } from "./nft_reducer";
import { BidReducer } from "./bid_reducer";
import { Transaction } from "./transaction_reducer";
import { AuctionReducer } from "./auction_reducer";
import { CategoriesReducer } from "./categories_reducer";
import { PlatformSettingsReducer } from "./platformSettings_reducer";
import { WalletReducer } from "./wallet_reducer";
import { UserReducer } from "./user_reducer";
import { NotificationReducer } from "./notification_reducer";

const rootReducer = combineReducers({
  theme: ThemeReducer,
  auth: AuthReducer,
  nft: NftReducer,
  bid: BidReducer,
  transaction: Transaction,
  auction: AuctionReducer,
  wallet: WalletReducer,
  categories: CategoriesReducer,
  platformSettings: PlatformSettingsReducer,
  user: UserReducer,
  availableNotifications: NotificationReducer,
});

export default rootReducer;
