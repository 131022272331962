import React from "react";
import { Container } from "react-bootstrap";
import LandingLayout from "../../layout/LandingLayout";

function PrivacyPolicy() {
  return (
    <LandingLayout>
      <Container>
        <h1 className="main_heading pt-5 mt-5 mb-3">Privacy Policy</h1>
        <div className="py-3">
          <div className="mb-3">
            1. <b>GENERAL</b>
          </div>
          <div className="ps-4">
            <p className="mb-3">
            Your privacy is important to us. This privacy policy is intended to give you confidence in the privacy and security of the personal information that you provide us.
            </p>
          </div>
          <div className="mb-3">
            2. <b>USE OF PERSONAL INFORMATION</b>
          </div>
          <div className="ps-4">
            <p className="mb-3">
            Your personal information is used only in the confines of Charity NFT Marketplace (https://charitynft.org). We do not share any of your personal information with any third party for any reason, except in the case of a court order from a judge.
            </p>
          </div>
          <div className="mb-3">
            3. <b>SECURITY</b>
          </div>
          <div className="ps-4">
            <p className="mb-3">
            We take very precaution to ensure that your personal information is secured. We provide end-to-end security by leveraging AWS secured cloud infrastructure. 
            </p>
          </div>
        </div>
      </Container>
    </LandingLayout>
  );
}

export default PrivacyPolicy;
