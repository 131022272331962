// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import CONTRACT_DETAILS from "../contract/contractInfo";
const nftContractAddress = CONTRACT_DETAILS.nft.address;
const nftABI = CONTRACT_DETAILS.nft.abi;
const useMintNft = () => {
  //approve contract

  const {
    isError: isNftSentError,
    data: nftSentTx,
    isLoading: isNftTxInProgress,
    isSuccess: isNftTxSent,
    write: mint,
    error: nftSentError
  } = useContractWrite({
    address: nftContractAddress,
    abi: nftABI,
    functionName: "mint",
  });
  const {
    isLoading: isNftWaiting,
    isSuccess: isNftCompleted,
    isError: isNftError,
    error: nftTxError,
    data:nftMintResp
  } = useWaitForTransaction({
    hash: nftSentTx?.hash,
  });

  return {
    //nft operation
    isNftSentError,
    nftSentTx,
    isNftTxInProgress,
    isNftTxSent,
    mint, // Renamed the function
    isNftCompleted,
    isNftError,
    isNftWaiting,
    nftSentError,
    nftTxError,
    nftMintResp
  };
};

export default useMintNft;
