import axios from "axios";

const authenticationToken = localStorage.getItem("authenticationToken");

const isURL = (str) => {
  var urlPattern = /^(https?:\/\/)?([\w-]+(\.[\w-]+)+\/?)?([^\s]+)$/;
  return urlPattern.test(str);
};

export const register = async (data) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/user-signup`,
      {
        email: data?.email,
        role: data?.role,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const signin = async (data) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/signin`,
      {
        email: data?.email,
        password: data?.password,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const verifyAccount = async (data, token) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/reset-password/${token}`,
      {
        password: data?.password,
        confirmPassword: data?.confirmPassword,
        requestType: "accountVerification",
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const verifyEmailLink = async (token) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/email-verification/${token}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const requestForgetPassword = async (data) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/forget-password`,
      {
        email: data?.email,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const setupProfile = async (data, token) => {
  try {
    const formData = new FormData();
    formData.append("firstName", data?.firstName);
    formData.append("lastName", data?.lastName);
    formData.append("email", data?.email);
    formData.append("address", data?.address);
    if (data?.addressTwo) {
      formData.append("addressTwo", data?.addressTwo);
    }
    formData.append("city", data?.city);
    formData.append("zipCode", data?.zipCode);
    formData.append("country", data?.country);
    formData.append("role", data?.role);
    formData.append("username", data?.username);
    formData.append("walletAddress", data?.walletAddress);
    if (Array.isArray(data.categories)) {
      const categoriesString = JSON.stringify(data.categories);
      formData.append("categories", categoriesString);
    }
    // Append the file if it exists
    if (data.profilePicture && isURL(data.profilePicture)) {
      formData.append("profilePictureUrl", data.profilePicture);
    } else {
      formData.append("profileImage", data.profilePicture);
    }
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/complete-profile`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token
            ? `Bearer ${token}`
            : `Bearer ${authenticationToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const setupOrganizationProfile = async (data, token) => {
  try {
    const formData = new FormData();
    formData.append(
      "einNumber",
      data?.userData ? data?.userData?.einNumber : data?.einNumber
    );
    formData.append(
      "fiscalSponsor",
      data?.userData
        ? data?.userData?.fiscalSponsorNonProfitName
        : data?.fiscalSponsorNonProfitName
    );
    formData.append(
      "fiscalSponsorEmail",
      data?.userData
        ? data?.userData?.fiscalSponsorEmail
        : data?.fiscalSponsorEmail
    );
    formData.append(
      "nonProfitName",
      data?.userData ? data?.userData?.nonProfitName : data?.nonProfitName
    );
    formData.append(
      "firstName",
      data?.userData ? data?.userData?.firstName : data?.firstName
    );
    formData.append(
      "lastName",
      data?.userData ? data?.userData?.lastName : data?.lastName
    );
    formData.append(
      "email",
      data?.userData ? data?.userData?.email : data?.email
    );
    formData.append(
      "address",
      data?.userData ? data?.userData?.address : data?.address
    );
    formData.append(
      "organizationType",
      data?.userData ? data?.userData?.organizationType : data?.organizationType
    );
    formData.append("city", data?.userData ? data?.userData?.city : data?.city);
    formData.append(
      "zipCode",
      data?.userData ? data?.userData?.zipCode : data?.zipCode
    );
    formData.append(
      "state",
      data?.userData ? data?.userData?.state : data?.state
    );
    formData.append(
      "country",
      data?.userData ? data?.userData?.country : data?.country
    );
    formData.append(
      "missionStatement",
      data?.userData ? data?.userData?.missionStatement : data?.missionStatement
    );
    formData.append("role", data?.userData ? data?.userData?.role : data?.role);
    formData.append(
      "username",
      data?.userData ? data?.userData?.username : data?.username
    );
    formData.append(
      "walletAddress",
      data?.userData ? data?.userData?.walletAddress : data?.walletAddress
    );
    formData.append(
      "website",
      data?.userData ? data?.userData?.website : data?.website
    );
    formData.append(
      "facebook",
      data?.userData ? data?.userData?.facebook : data?.facebook
    );
    formData.append(
      "instagram",
      data?.userData ? data?.userData?.instagram : data?.instagram
    );
    formData.append(
      "linkedIn",
      data?.userData ? data?.userData?.linkedIn : data?.linkedIn
    );
    formData.append(
      "contactNumber",
      data?.userData ? data?.userData?.contactNumber : data?.contactNumber
    );
    if (data?.userData?.isoGuideStarEmail || data?.isoGuideStarEmail) {
      formData.append(
        "isoGuideStarEmail",
        data?.userData
          ? data?.userData?.isoGuideStarEmail
          : data?.isoGuideStarEmail
      );
    }
    if (data?.userData?.isIsoVerified || data?.isIsoVerified) {
      formData.append(
        "isIsoVerified",
        data?.userData ? data?.userData?.isIsoVerified : data?.isIsoVerified
      );
    }
    if (data?.userData?.addressTwo || data?.addressTwo) {
      formData.append("addressTwo", data?.userData?.addressTwo || data?.addressTwo);
    }
    if (data?.userData?.fiscalSponsorId || data?.fiscalSponsorId) {
      formData.append(
        "fiscalSponsorId",
        data?.fiscalSponsorId || data?.userData?.fiscalSponsorId
      );
    }
    if (Array.isArray(data?.userData?.fiscalProjects || data.fiscalProjects)) {
      const fiscalProjectString = JSON.stringify(
        data.fiscalProjects || data?.userData?.fiscalProjects
      );
      formData.append("fiscalProjects", fiscalProjectString);
    }
    if (Array.isArray(data?.userData?.categories || data.categories)) {
      const categoriesString = JSON.stringify(data.categories);
      formData.append("categories", categoriesString);
    }
    // Append the file if it exists
    if (data?.userData?.profilePicture || data?.profilePicture) {
      formData.append(
        "profileImage",
        data?.userData ? data?.userData?.profilePicture : data.profilePicture
      );
    }
    // Append the file if it exists
    if (data?.userData?.isoDocument || data?.isoDocument) {
      formData.append(
        "isoDocument",
        data?.userData ? data?.userData?.isoDocument : data.isoDocument
      );
    }
    const organization = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/complete-organization-profile`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: token
            ? `Bearer ${token}`
            : `Bearer ${authenticationToken}`,
        },
      }
    );
    return organization;
  } catch (error) {
    return error.response.data;
  }
};

export const deactivateFiscalProject = async (data) => {
  try {
    const verification = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/deactivate-fiscal-project`,
      {
        fiscalProjectId: data?.id,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: data?.token
            ? `Bearer ${data?.token}`
            : `Bearer ${authenticationToken}`,
        },
      }
    );
    return verification;
  } catch (error) {
    return error.response.data;
  }
};
export const updateFiscalProject = async (
  fiscalProjectId,
  authToken,
  options = {}
) => {
  try {
    const { defaultSponsorshipFee, sponsorshipFee } = options;
    let verification;

    if (sponsorshipFee) {
      verification = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/edit-fiscal-project`,
        {
          fiscalProjectId,
          sponsorshipFee,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken
              ? `Bearer ${authToken}`
              : `Bearer ${authenticationToken}`,
          },
        }
      );
    } else {
      verification = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/auth/edit-fiscal-project`,
        {
          fiscalProjectId,
          defaultSponsorshipFee,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken
              ? `Bearer ${authToken}`
              : `Bearer ${authenticationToken}`,
          },
        }
      );
    }

    return verification;
  } catch (error) {
    return error.response.data;
  }
};

export const acceptSponsorshipInvite = async (data) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/accept-sponsorship-invite/${data?.token}`,
      {
        email: data?.values?.email,
        password: data?.values?.password,
        confirmPassword: data?.values?.confirmPassword,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};
export const manageNotificationSettings = async (data) => {
  try {
    const notificationSetting = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/manage-notification-settings`,
      {
        notificationSettingIds: data?.notificationIds,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: data?.token
            ? `Bearer ${data?.token}`
            : `Bearer ${authenticationToken}`,
        },
      }
    );
    return notificationSetting;
  } catch (error) {
    return error.response.data;
  }
};

export const einVerification = async (data) => {
  try {
    const verification = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/ein-verification`,
      { ein: data?.ein, organizationType: data?.organizationType || "" },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: data?.token
            ? `Bearer ${data?.token}`
            : `Bearer ${authenticationToken}`,
        },
      }
    );
    return verification;
  } catch (error) {
    return error.response.data;
  }
};
export const findOrganizationByEin = async (data) => {
  try {
    const verification = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/auth/find-organization-by-ein/${data?.ein}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: data?.token
            ? `Bearer ${data?.token}`
            : `Bearer ${authenticationToken}`,
        },
      }
    );
    return verification;
  } catch (error) {
    return error.response.data;
  }
};

export const inviteFiscalSponsor = async (data) => {
  try {
    const verification = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/invite-fiscal-sponsor`,
      { email: data?.email },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: data?.token
            ? `Bearer ${data?.token}`
            : `Bearer ${authenticationToken}`,
        },
      }
    );
    return verification;
  } catch (error) {
    return error.response.data;
  }
};

export const updateProfile = async (data) => {
  try {
    const formData = new FormData();
    formData.append("firstName", data?.firstName);
    formData.append("nonProfitName", data?.nonProfitName);
    formData.append("lastName", data?.lastName);
    formData.append("username", data?.username);
    formData.append("email", data?.email);
    formData.append("missionStatement", data?.missionStatement);
    formData.append("bio", data?.bio);
    formData.append("zipCode", data?.zipCode);
    formData.append("address", data?.address);
    formData.append("addressTwo", data?.addressTwo);
    formData.append("city", data?.city);
    formData.append("country", data?.country);
    formData.append("defaultSponsorshipFee", data?.defaultSponsorshipFee);

    // Append the file if it exists
    if (data.profilepPictureFile) {
      formData.append("profileImage", data.profilepPictureFile);
    }

    if (data.bannerFile) {
      formData.append("bannerImage", data.bannerFile);
    }

    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/profile-setting`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${data?.authToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const setWallet = async (data) => {
  try {
    const formData = new FormData();
    formData.append("email", data?.email);
    formData.append("walletAddress", data?.walletAddress);

    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/set-walletAddress`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${data?.authToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const changePassword = async (data, authToken) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/change-password`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const addNftToFav = async (data) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/add-favourites`,
      { nftId: data?.nftId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data?.authToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const removeNftToFav = async (data) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/remove-favourites`,
      { nftId: data?.nftId },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${data?.authToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const findNonProfit = async (organizationName) => {
  try {
    const user = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/auth/organization/${organizationName}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const reportNonProfit = async (authToken, data) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/reports/organization`,
      {
        reportType: data?.reportType,
        reporterEmail: data.email,
        reason: data.reason,
        organizationId: data?.organizationId,
        reportedBy: data?.reportedBy,
        reportedTo: data?.reportedTo,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const reportNFT = async (authToken, data) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/reports/nft`,
      {
        reportType: data?.reportType,
        reporterEmail: data.email,
        reason: data.reason,
        nftId: data?.nftId,
        reportedBy: data?.reportedBy,
        reportedTo: data?.reportedTo,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const validateUsername = async (authToken, username) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/verify/username`,
      {
        username: username,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const leaderBoardList = async (authToken, userType, limit) => {
  try {
    const users = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/auth/leaderBoard/${userType}/${limit}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return users;
  } catch (error) {
    return error.response.data;
  }
};
// this getSponsorsList API is for getting direct supporters data for organization
export const getSponsorsList = async (authToken, id) => {
  try {
    const sponsors = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/auth/organization-sponsors/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return sponsors;
  } catch (error) {
    return error.response.data;
  }
};

export const getIndirectSupportersList = async (authToken, id) => {
  try {
    const sponsors = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/auth/organization-indirect-supporters/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return sponsors;
  } catch (error) {
    return error.response.data;
  }
};

export const getUserCharityList = async (authToken, id) => {
  try {
    const sponsors = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/auth/user-charities/${id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return sponsors;
  } catch (error) {
    return error.response.data;
  }
};

export const setSocialMediaData = async (data, authToken) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/set-social-media`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};

export const updateApproval = async (id, data) => {
  try {
    const authToken = localStorage.getItem("authenticationToken");
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/approval/${id}`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};
export const refetchUser = async () => {
  try {
    const authToken = localStorage.getItem("authenticationToken");
    const user = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/auth/user`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};
