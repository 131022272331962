import React, { useEffect, useState } from 'react';
import HeroSection from '../../HeroSection';
import { Col, Row, Select } from 'antd';
import { Container } from 'react-bootstrap';
import SearchIcon from '../../../assets/images/icons/light-search.svg';
import MainCard from '../../MainCard';
import { getNFTs } from '../../../store/_actions/nft_actions';
import { useSelector, useDispatch } from 'react-redux';
import LandingLayout from '../../layout/LandingLayout';
import moment from 'moment';
import { Pagination } from 'antd';

const NftListing = () => {
  const [limit,setLimit] = useState(10);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState({
    typeFilter: '',
    priceFilter: '',
    dateFilter: -1,
    search: '',
    causeFilter:'',
    page,
    limit
    
  });
  const dispatch = useDispatch();
  const  nfts = useSelector((state) => state.nft.nfts?.[0]);
  useEffect(() => {
    dispatch(getNFTs(params));
  }, []);

  useEffect(() => {
    dispatch(getNFTs(params));
  }, [params.typeFilter , params.priceFilter , params.dateFilter,params?.causeFilter]);
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      dispatch(getNFTs(params));
    }, 700);
    return () => clearTimeout(debounceTimeout);
  }, [params.search]);

  const handleTypeChange = (value) => {
    console.log('value :', value);
    setParams({ ...params, typeFilter: value });
  };
  const handlePriceChange = (value) => {
    console.log('value :', value);
    setParams({ ...params, priceFilter: value });
  };
  const handleDateChange = (value) => {
    console.log('value :', value);
    setParams({ ...params, dateFilter: value });
  };
  useEffect(() => {
    setParams((prevParams) => ({
        ...prevParams,
        page: page,
        limit: limit,
    }));
    dispatch(getNFTs({ ...params, page, limit }));
  }, [page, limit]);

  const handlePageClick = (page,pageSize) => {
    setPage(page); 
    setLimit(pageSize);
  };
  return (
    <>
      <LandingLayout>
        <HeroSection />
        <Container>
          <div className='pt-5'>
            <h1 className='mb-4 main_heading'>NFT Listings</h1>
            <div
              className={`d-flex align-items-center justify-content-between gap-4 pb-5`}
            >
              <div className={`searchOuter d-flex gap-2`}>
                <img src={SearchIcon} alt='icon' />
                <input
                  className='w-100'
                  type='text'
                  placeholder='Search'
                  onChange={(e) => {
                    setParams({ ...params, search: e.target.value });
                  }}
                />
              </div>
              <div className={`d-flex gap-4`}>
                <Select
                  placeholder='Type'
                  style={{
                    width: 140,
                  }}
                  onChange={handleTypeChange}
                  options={[
                    {
                      value: '',
                      label: 'All',
                    },
                    {
                      value: 'image',
                      label: 'Image',
                    },
                    {
                      value: 'video',
                      label: 'Video',
                    },
                    {
                      value: 'gif',
                      label: 'Gif',
                    },
                  ]}
                />
                <Select
                  placeholder='Price'
                  style={{
                    width: 140,
                  }}
                  onChange={handlePriceChange}
                  options={[
                    {
                      value: '',
                      label: 'All',
                    },
                    {
                      value: 'desc',
                      label: 'Low to High',
                    },
                    {
                      value: 'asc',
                      label: 'High to Low',
                    },
                  ]}
                />
                <Select
                  placeholder='Recently Added'
                  style={{
                    width: 180,
                  }}
                  onChange={handleDateChange}
                  options={[
                    {
                      value: moment()
                        .subtract(1, 'months')
                        .format('YYYY-MM-DD'),
                      label: 'One Month Ago',
                    },
                    {
                      value: moment()
                        .subtract(2, 'months')
                        .format('YYYY-MM-DD'),
                      label: 'Two Month Ago',
                    },
                    {
                      value: -1,
                      label: 'Recently Added',
                    },
                    {
                      value: 1,
                      label: 'Oldest',
                    },
                  ]}
                />
              </div>
            </div>
            <Row gutter={[20, 20]}>
              {nfts &&
                nfts?.data?.length > 0 &&
                nfts?.data?.map((item, i) => (
                  <Col lg={6} md={12} sm={24} xs={24} key={i}>
                    <MainCard item={item}  params={params} setParams={setParams} />
                  </Col>
                ))}
            </Row>
            <Pagination
        current={page} // Current page
        pageSize={limit} // Items per page
        total={nfts?.metadata[0]?.total} // Total items
        onChange={handlePageClick} // Handle page change
        showSizeChanger
        onShowSizeChange={handlePageClick}
        style={{ textAlign: 'center', margin: '20px 0' }} // Center the pagination
      />
          </div>
        </Container>
      </LandingLayout>
    </>
  );
};

export default NftListing;
