import React from "react";
import { Link } from "react-router-dom";
import Classes from "../Pages/scss/banned.module.scss";
import { Col, Row } from "antd";
import { Container } from "react-bootstrap";

function Banned() {
  return (
    <div className={Classes.banned}>
      <Container>
        <Row
          className={`${Classes.outer} d-flex justify-content-center align-items-center`}
        >
          <Col md={12} sm={24} xs={24}></Col>
          <Col md={12} sm={24} xs={24}>
            <div className="">
              <h1>Sorry, this profile is currently hidden.</h1>
              <p>
                Go Back to the <Link to="/">Home Page</Link>
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Banned;
