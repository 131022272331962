import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Classes from './charityNft.module.scss';
import Img from '../../assets/images/section/userProf.svg';
import Img2 from '../../assets/images/section/organizationProf.svg';
import checkImg from '../../assets/images/icons/check_circle.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import { alertMessage } from '../../constants/alertMessage';

const data = [
  {
    img: Img,
    title: 'Create profile as a user',
    role: 'user',
    description:
      'A user profile can mint the NFTs. A user profile can Create, Sell and Buy NFTs as well',
  },
  {
    img: Img2,
    title: 'Create profile as a nonprofit organization',
    role: 'organization',
    description: 'A nonprofit organization can sell and do auctions.',
  },
];

const CharityNftData = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isFilled, setIsFilled] = useState(false);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsFilled(true);
  };

  useEffect(() => {
    if (!auth?.email) {
      navigate('/login');
      alertMessage('error', 'Authentication required.');
    }
    if (auth?.username && auth?.email) {
      navigate('/profile');
    } else {
      navigate('/get-started');
    }
  }, [auth]);

  const isAccountTypeSelected = () => {
    if (!selectedOption) {
      alertMessage('error', 'Select account type first.');
      return false;
    }

    if (selectedOption === 'user') {
      navigate('/build-profile', {
        state: { role: selectedOption, address: location?.state?.address },
      });
    } else {
      navigate('/user-info', {
        state: { role: selectedOption, address: location?.state?.address },
      });
    }
  };
  return (
    <div
      className={`${Classes.mainOuter} d-flex justify-content-center align-items-center flex-column`}
    >
      <h1 className={Classes.title}>Charity NFT Marketplace</h1>
      <div className={Classes.content}>
        <div className='mb-4'>
          <h2 className={Classes.subTitle}>Welcome! Sign up for a free account</h2>
          {/* <p className={Classes.desc}>Let’s get started!</p> */}
        </div>
        <div className='d-flex gap-4 mb-4 flex-md-row flex-column'>
          {data?.map((d) => (
            <div
              className={`${Classes.box} ${
                selectedOption === d.role ? Classes.selected : ''
              }`}
              onClick={() => handleOptionClick(d.role)}
            >
              <img className={Classes.check} src={checkImg} alt='img' />
              <>
                <img className='mb-3' src={d.img} height={56} alt='img' />
                <h3 className={Classes.subHeading}>{d.title}</h3>
                {/* <p className={Classes.txt}>{d.description}</p> */}
              </>
            </div>
          ))}
        </div>
        <div className='d-flex justify-content-end'>
          <button
            onClick={() => isAccountTypeSelected()}
            className={Classes.blueBtn}
          >
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default CharityNftData;
