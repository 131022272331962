import React from 'react';
import LoginComponent from '../../Login';

const Login = () => {
  return (
    <>
      <LoginComponent />
    </>
  );
};

export default Login;
