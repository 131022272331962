import {
  SHOW_PLACE_BET_LOADER,
  HIDE_PLACE_BET_LOADER,
  PLACE_BET_STATUS,
  SET_BETS
} from "../constants";

const initialWalletState = {
  bids: null,
  isPlacingBid: false,
  status: "",
};

export const BidReducer = (state = initialWalletState, action) => {
  switch (action.type) {
    case SET_BETS:
      return {
        ...state,
        bids: action.payload,
      };
    case SHOW_PLACE_BET_LOADER:
      return {
        ...state,
        isPlacingBid: true,
        status: "pending",
      };
    case HIDE_PLACE_BET_LOADER:
      return {
        ...state,
        isPlacingBid: false,
        status: "",
      };
    case PLACE_BET_STATUS:
      return {
        ...state,
        status: action.payload,
      };
    default:
      return state;
  }
};
