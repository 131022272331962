import React, { useEffect, useState } from 'react';
import Classes from './sponsorship.module.scss';
import {Tag } from 'antd';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import Search from '../../assets/images/icons/searchdark.svg';
import { invitesList, resendInvitation } from '../../services/invites.service';
import SendInviteModal from './SendInviteModal';
import RespondInviteModal from './RespondInviteModal';
import { alertMessage } from '../../constants/alertMessage';

const SponsorshipRequests = () => {
  const [isRespondModalOpen, setIsRespondModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sendedInvites, setSendedInvites] = useState([]);
  const [statusType, setTtatusType] = useState('');
  const [respondRecord, setRespondRecord] = useState('');
  const [filterText, setFilterText] = useState('');
  const token = localStorage.getItem('authenticationToken');

  const getInvitesList = async () => {
    try {
      const data = await invitesList(token);
      if (data?.data?.success === true) {
        setSendedInvites(data?.data?.data?.sendedInvites);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInvitesList();
  }, []);

  const showRespondModal = () => {
    setIsRespondModalOpen(true);
  };

  const handleRespondCancel = () => {
    setIsRespondModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleResendInvite = async (data) => {
    try {
      const requestData = {
        email: data?.receiverEmail,
        token,
      };
      const response = await resendInvitation(requestData);
      if (response?.data?.success === true) {
        alertMessage('success', `${response?.data?.message}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      name: 'Email address',
      selector: (row) => row.receiverEmail,
    },
    {
      name: 'Status',
      cell: (row) => (
        <Tag
          color={
            row.status === 'accepted'
              ? 'green'
              : row.status === 'rejected'
              ? 'red'
              : 'orange'
          }
        >
          {row.status}
        </Tag>
      ),
    },
    {
      name: 'Sended date',
      selector: (row) => moment(row.createdAt).format('MMM DD, YYYY'),
    },
  ];

  const filteredData =
    sendedInvites &&
    sendedInvites?.filter((item) =>
      item.receiverEmail.toLowerCase().includes(filterText.toLowerCase())
    );

  const handleChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleEdit = (row) => {
    setRespondRecord(row);
  };

  const handleDelete = (row) => {
    setRespondRecord(row);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold', // Make the header text bold
        fontSize: 14,
        background: '#f1f1f1',
      },
    },
    table: {
      style: {
        border: '1px solid #ddd', // Add border around the whole table
      },
    },
  };
  return (
    <div className={`${Classes.invite}`}>
      <h1 className={Classes.title}>Fiscal Projects</h1>
      <div className='d-flex align-items-center justify-content-between flex-md-row flex-column my-3 gap-2'>
        <div className={Classes.inputOuter}>
          <img src={Search} alt='search' />
          <input placeholder='Search by email...' onChange={handleChange} />
        </div>
      </div>
      <DataTable
        columns={columns}
        customStyles={customStyles}
        data={filteredData}
        pagination
      />
      <SendInviteModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        getInvitesList={getInvitesList}
      />
      <RespondInviteModal
        isRespondModalOpen={isRespondModalOpen}
        handleRespondCancel={handleRespondCancel}
        message={message}
        respondRecord={respondRecord}
        statusType={statusType}
        getInvitesList={getInvitesList}
      />
    </div>
  );
};

export default SponsorshipRequests;
