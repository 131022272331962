import React,{useState} from 'react';
import Cause from '../../Cause';
import New from '../../New';
import PopularNft from '../../PopularNft';
import ComingSoon from '../../ComingSoon';
import LeaderBoard from '../../LeaderBoard';
import FriendSuggestion from '../../FriendSuggestion';

const SigninHome = () => {
  const [searchQuery, setSearchQuery] = useState("");

  return (
    <>
      <Cause />
      <New />
      <PopularNft />
      <ComingSoon />
      <LeaderBoard />
      <FriendSuggestion searchQuery={searchQuery}/>
    </>
  );
};

export default SigninHome;
