import React from "react";
import Classes from "./createNft.module.scss";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import checkImg from "../../assets/images/svg/confirm.svg";


const SuccessModal = ({ isModalOpen, handleCancel, nft,modalTitle,modalDescription }) => {
  const navigate = useNavigate();
  return (
    <Modal
      className={Classes.modal}
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      <div className="d-flex align-items-center justify-content-center flex-column">
        <img src={checkImg} alt="img" />
        <div className={Classes.modal}>
          <h3 className="mt-2">{modalTitle}</h3>
          <p className="pb-4">
            {modalDescription}
          </p>
          {/* <hr /> */}
          {/* <p className="mb-3 mt-4">Share your product</p>
          <div className="d-flex gap-3 justify-content-center align-items-center mb-4">
            <a href="#">
              <img src={img1} alt="icon" />
            </a>
            <a href="#">
              <img src={img2} alt="icon" />
            </a>
            <a href="#">
              <img src={img3} alt="icon" />
            </a>
          </div> */}
         {nft?._id && <button
            className={`${Classes.blueBtn} w-100`}
            onClick={() => navigate(`/nft/${nft?._id}`)}
          >
            View NFT
          </button>}
          {!nft?._id && <button
            className={`${Classes.blueBtn} w-100`}
            onClick={() => handleCancel()}
          >
            Close
          </button>}
        </div>
      </div>
    </Modal>
  );
};

export default SuccessModal;
