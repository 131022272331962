import React from "react";
import { Col, Modal, Row } from "antd";
import Classes from "./causesList.module.scss";
import { Link } from "react-router-dom";

const CausesList = ({
  isModalOpen,
  handleCancel,
  handleCauseChange,
  nft_categories,
}) => {
  // Sort nft_categories alphabetically by categoryName
  const sortedCategories = nft_categories?.length && nft_categories?.slice().sort((a, b) => {
    if (a.categoryName < b.categoryName) return -1;
    if (a.categoryName > b.categoryName) return 1;
    return 0;
  });

  return (
    <Modal
      className={Classes.causeModal}
      title="List of Causes"
      open={isModalOpen}
      footer={null}
      onCancel={handleCancel}
    >
      <div className={`${Classes.modalContent}`}>
        <Row gutter={[20, 30]}>
          {sortedCategories &&
            sortedCategories?.length > 0 &&
            sortedCategories?.map((category, index) => (
              <Col md={12} key={category?._id}>
                <div className={Classes.causesList}>
                  <div className={Classes.counter}>{index + 1}</div>
                  <Link
                    onClick={() => {
                      handleCauseChange(category?._id);
                      handleCancel();
                    }}
                  >
                    {category?.categoryName}
                  </Link>
                </div>
              </Col>
            ))}
        </Row>
        <button onClick={handleCancel} className={`${Classes.cencelbtn} mt-4`}>
          Close
        </button>
      </div>
    </Modal>
  );
};

export default CausesList;
