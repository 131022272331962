import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserHistory from '../../UserHistory';
import { alertMessage } from '../../../constants/alertMessage';
import LandingLayout from '../../layout/LandingLayout';
import { refetchUser } from "../../../services/auth.service";
import { thunkLogin } from "../../../store/_actions/user_action";
import { useDispatch } from "react-redux";
const PublicProfile = () => {
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const fetchUser = async () => {
      const authToken = localStorage.getItem("authenticationToken");
      if (authToken&&!auth?.categories[0]?.categoryName) {
        const resp = await refetchUser();
        if (resp) {
          dispatch(thunkLogin(resp?.data?.data, () => {}));
        }
      }
    };
    fetchUser();
  }, [!auth?.categories]);
  useEffect(() => {
    if (!auth?.email) {
      navigate('/login');
      alertMessage('error', 'Authentication required.');
    }
  }, []);
  useEffect(() => {
    const {username,id } = auth||{};
    if (!username &&!id) {
      navigate("/login");
    }else if(!username &&id){
      navigate("/get-started");
    }
  }, [auth]);

  return (
    <>
      <LandingLayout>
        <UserHistory />
      </LandingLayout>
    </>
  );
};

export default PublicProfile;
