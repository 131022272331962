import React, { useState } from "react";
import Classes from "./scss/walletConnect.module.scss";
import { Container } from "react-bootstrap";
import { Col, Row } from "antd";
import MetaMask from "../../../assets/images/icons/metamask.svg";
import Coinbase from "../../../assets/images/icons/coinbase.svg";
import { connectors, getName } from "./WalletConnection";
import { setConnectedWalletAddress } from "../../../store/_actions/wallet_action";
import { alertMessage } from "../../../constants/alertMessage.js";
import { useDispatch } from "react-redux";
import Web3 from "web3";
import { useNavigate } from "react-router-dom";
function WalletConnect() {
  const [activeConnector, setActiveConnector] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const connectWallet = (connector) => {
    console.log(connector, "connector==>");

    if (!window?.ethereum) {
      return;
    }
    console.log(getName(connector), "name connected");
    if (
      getName(connector) === "Coinbase Wallet" &&
      !window?.web3?.currentProvider?.isCoinbaseWallet
    ) {
      return alertMessage("success", "Wallet is not installed");
    }
    localStorage.setItem("activeWallet", getName(connector));
    if (connector === activeConnector) return; // Don't connect if already connected with the same connector
    setActiveConnector(connector);
    activateConnector(connector);
  };

  const activateConnector = async (connector) => {
    try {
      if (connector === null) return;

      await connector.activate(); // You may need to call the connector's activate method
      // dispatch(thunkWallet(connector?.provider));
      // console.log('conectng again')
      const web3 = new Web3(connector.provider);
      let accounts = await web3.eth.getAccounts();

      // await web3.eth.requestAccounts(

      //   {
      //   method: "wallet_requestPermissions",
      //   params: [
      //     {
      //       eth_accounts: {},
      //     },
      //   ],
      // }
      // );

      const account = accounts[0];
      localStorage.setItem("walletAddress", account);
      console.log(account, "account==>");
      dispatch(setConnectedWalletAddress(account));

      navigate("/get-started", { state: { address: account } });

      // setOpen(false);
    } catch (error) {
      console.error("Error connecting to the wallet:", error);
    }
  };
  return (
    <div className={Classes.wallet}>
      <Container>
        <h1 className={Classes.title}>Link your Wallet Address</h1>
        <p className={Classes.desc}>
          Link your wallet address to your account that cannot be changed later
        </p>
        <div className={Classes.inner}>
          <p className={Classes.info}>Available Wallets</p>
          <Row gutter={[20, 20]}>
            {connectors.map(([connector, hooks], i) => {
              return (
                <Col lg={12} md={12} sm={24} xs={24}>
                  <div
                    className={Classes.outer}
                    onClick={() => connectWallet(connector)}
                  >
                    <div className={Classes.iconOuter}>
                      <img
                        height={50}
                        src={
                          getName(connector) === "MetaMask"
                            ? MetaMask
                            : Coinbase
                        }
                        alt="icon"
                      />
                    </div>
                    <p className={Classes.name}>{getName(connector)}</p>
                  </div>
                </Col>
              );
            })}
            {/* 
            <Col lg={12} md={12} sm={24} xs={24}>
              <div className={Classes.outer}>
                <div className={Classes.iconOuter}>
                  <img height={50} src={Coinbase} alt="icon" />
                </div>
                <p className={Classes.name}>Coinbase</p>
              </div>
            </Col> */}
          </Row>
          {/* <div className={Classes.btnOuter}>
            <Link to="/" className={Classes.blueBtn}>
              Next
            </Link>
          </div> */}
        </div>
      </Container>
    </div>
  );
}

export default WalletConnect;
