import axios from 'axios';

export const categoriesListing = async () => {
  try {
    const notifications = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/categories`,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
    return notifications;
  } catch (error) {
    return error.response.data;
  }
};

export const categoryDetail = async (id) => {
  try {
    const notifications = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/categories/find/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authenticationToken')}`,
        },
      }
    );
    return notifications;
  } catch (error) {
    return error.response.data;
  }
};
export const updateUserCategory=async (id,data)=>{
  try {
    const notifications = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auth/update/category/${id}`,
      { categories: data },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authenticationToken')}`,
        },
      }
    );
    return notifications;
  } catch (error) {
    return error.response.data;
  }
}
