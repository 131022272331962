import React from "react";
import { useSelector } from "react-redux";
import { Tag } from "antd";
import Classes from "./tabs.module.scss";
import DataTable from "react-data-table-component";
import moment from "moment";

const IsoLetters = ({ isoDocuments }) => {
  const auth = useSelector((state) => state.auth);

  const customStyles = {
    headCells: {
      style: {
        fontWeight: "bold", // Make the header text bold
        fontSize: 14,
        background: "#f1f1f1",
      },
    },
    table: {
      style: {
        border: "1px solid #ddd", // Add border around the whole table
      },
    },
  };

  const columns = [
    {
      name: auth?.organizationType?.includes("IRS")
        ? "IRS Determination Letter"
        : "Fiscal Sponsorship Letter",
      selector: (row) => row.isoDocument,
      cell: (row) => (
        <a href={row.isoDocument} target="_blank">
          {auth?.organizationType?.includes("IRS")
            ? "IRS Determination Letter"
            : "Fiscal Sponsorship Letter"}
        </a>
      ),
    },
    {
      name: "Status",
      cell: (row) => (
        <Tag
          color={
            row.isVerified === "accepted"
              ? "green"
              : row.isVerified === "rejected"
                ? "red"
                : "orange"
          }
        >
          {row.isVerified}
        </Tag>
      ),
    },
    {
      name: "Date",
      selector: (row) => moment(row.createdAt).format("MMM DD, YYYY"),
    },
  ];

  const documents = isoDocuments;
  return (
    <div>
      <div className={`${Classes.modalInner}`}>
        <DataTable
          columns={columns}
          customStyles={customStyles}
          data={documents}
          pagination
        />
      </div>
    </div>
  );
};

export default IsoLetters;
