// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import CONTRACT_DETAILS from "../contract/contractInfo";
const nftABI = CONTRACT_DETAILS.nft.abi;
const nftContractAddress = CONTRACT_DETAILS.nft.address;

const useApprove = () => {
  //approve contract

  const {
    isError: isApproveSentError,
    data: approveSentTx,
    isLoading: isApproveTxInProgress,
    isSuccess: isApproveTxSent,
    write: approve,
    error: approveSentError
  } = useContractWrite({
    address: nftContractAddress,
    abi: nftABI,
    functionName: "approveAmount",
  });
  const {
    isLoading: isApproveWaiting,
    isSuccess: isApproveCompleted,
    isError: isApproveError,
    error: approveTxError,
    data: approveMintResp
  } = useWaitForTransaction({
    hash: approveSentTx?.hash,
  });

  return {
    //approve operation
    isApproveSentError,
    approveSentTx,
    isApproveTxInProgress,
    isApproveTxSent,
    approve, // Renamed the function
    isApproveCompleted,
    isApproveError,
    isApproveWaiting,
    approveSentError,
    approveTxError,
    approveMintResp
  };
};

export default useApprove;
