import React, { Fragment, useState, useEffect } from "react";
import Classes from "./socialMedia.module.scss";
import * as Yup from "yup";
import { Formik } from "formik";
import { useSelector, useDispatch } from "react-redux";
import { setSocialMediaData } from "../../services/auth.service";
import { ThreeDots } from "react-loader-spinner";
import { alertMessage } from "../../constants/alertMessage";
import { updateSocialMedia } from "../../store/_actions/user_action";

const SocialMedia = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [socialMedia, setSocialMedia] = useState({});
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleSaveChanges = async (data, resetForm) => {
    try {
      setIsLoading(true);
      const authToken = localStorage.getItem("authenticationToken");

      const response = await setSocialMediaData(data, authToken);
      if (response?.data?.success === true) {
        alertMessage("success", `${response?.data?.message}`);
        dispatch(updateSocialMedia(data));
        setSocialMedia(data);
        setIsLoading(false);
        resetForm({ values: "" });
      } else {
        alertMessage("error", `${response?.message}.`);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      alertMessage("error", error);
    }
  };

  useEffect(() => {
    setSocialMedia(auth?.socialMedia || {});
  }, [auth?.socialMedia]);

  return (
    <div className={Classes.profile}>
      <h1 className={Classes.title}>Social Media</h1>

      <Formik
        enableReinitialize
        initialValues={{
          facebook: socialMedia.facebook || "",
          instagram: socialMedia.instagram || "",
          twitter: socialMedia.twitter || "",
          linkedIn: socialMedia.linkedIn || "",
          youtube: socialMedia.youtube || "",
          tiktok: socialMedia.tiktok || "",
          discord: socialMedia.discord || "",
          website: socialMedia.website || "",
        }}
        validationSchema={Yup.object().shape({
          facebook: Yup.string().optional().url("Please enter a valid URL"),
          instagram: Yup.string().optional().url("Please enter a valid URL"),
          twitter: Yup.string().optional().url("Please enter a valid URL"),
          linkedIn: Yup.string().optional().url("Please enter a valid URL"),
          youtube: Yup.string().optional().url("Please enter a valid URL"),
          tiktok: Yup.string().optional().url("Please enter a valid URL"),
          discord: Yup.string().optional().url("Please enter a valid URL"),
          website: Yup.string().optional().url("Please enter a valid URL"),
        })}
        onSubmit={(values, { resetForm }) => {
          const data = {
            facebook: values.facebook.trim() === "" ? null : values.facebook,
            instagram: values.instagram.trim() === "" ? null : values.instagram,
            twitter: values.twitter.trim() === "" ? null : values.twitter,
            linkedIn: values.linkedIn.trim() === "" ? null : values.linkedIn,
            youtube: values.youtube.trim() === "" ? null : values.youtube,
            tiktok: values.tiktok.trim() === "" ? null : values.tiktok,
            discord: values.discord.trim() === "" ? null : values.discord,
            website: values.website.trim() === "" ? null : values.website,
          };
          handleSaveChanges(data, resetForm);
        }}
        textAlign="center"
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
          setFieldValue,
        }) => (
          <form
            onSubmit={handleSubmit}
            className="mt-8 space-y-6"
            noValidate
            autoComplete="off"
          >
            <div className={Classes.formOuter}>
              <div className="mb-3">
                <p className={Classes.label}>Discord</p>
                <div className={`${Classes.inputOuter}`}>
                  <input
                    className="pe-3"
                    type="text"
                    name="discord"
                    placeholder="Enter Discord URL"
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue("discord", e.target.value)}
                    value={values.discord}
                  />
                </div>
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.discord && touched.discord ? (
                    <Fragment>{errors.discord}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>Facebook</p>
                <div className={`${Classes.inputOuter}`}>
                  <input
                    className="pe-3"
                    type="text"
                    name="facebook"
                    placeholder="Enter Facebook URL"
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue("facebook", e.target.value)}
                    value={values.facebook}
                  />
                </div>
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.facebook && touched.facebook ? (
                    <Fragment>{errors.facebook}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>Instagram</p>
                <div className={`${Classes.inputOuter}`}>
                  <input
                    className="pe-3"
                    type="text"
                    name="instagram"
                    placeholder="Enter Instagram URL"
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue("instagram", e.target.value)}
                    value={values.instagram}
                  />
                </div>
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.instagram && touched.instagram ? (
                    <Fragment>{errors.instagram}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>LinkedIn</p>
                <div className={`${Classes.inputOuter}`}>
                  <input
                    className="pe-3"
                    type="text"
                    name="linkedIn"
                    placeholder="Enter LinkedIn URL"
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue("linkedIn", e.target.value)}
                    value={values.linkedIn}
                  />
                </div>
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.linkedIn && touched.linkedIn ? (
                    <Fragment>{errors.linkedIn}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>TikTok</p>
                <div className={`${Classes.inputOuter}`}>
                  <input
                    className="pe-3"
                    type="text"
                    name="tiktok"
                    placeholder="Enter TikTok URL"
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue("tiktok", e.target.value)}
                    value={values.tiktok}
                  />
                </div>
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.tiktok && touched.tiktok ? (
                    <Fragment>{errors.tiktok}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>X/Twitter</p>
                <div className={`${Classes.inputOuter}`}>
                  <input
                    className="pe-3"
                    type="text"
                    name="twitter"
                    placeholder="Enter X/Twitter URL"
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue("twitter", e.target.value)}
                    value={values.twitter}
                  />
                </div>
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.twitter && touched.twitter ? (
                    <Fragment>{errors.twitter}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>Website</p>
                <div className={`${Classes.inputOuter}`}>
                  <input
                    className="pe-3"
                    type="text"
                    name="website"
                    placeholder="Enter Website URL"
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue("website", e.target.value)}
                    value={values.website}
                  />
                </div>
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.website && touched.website ? (
                    <Fragment>{errors.website}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className="mb-3">
                <p className={Classes.label}>YouTube</p>
                <div className={`${Classes.inputOuter}`}>
                  <input
                    className="pe-3"
                    type="text"
                    name="youtube"
                    placeholder="Enter YouTube URL"
                    onBlur={handleBlur}
                    onChange={(e) => setFieldValue("youtube", e.target.value)}
                    value={values.youtube}
                  />
                </div>
                <span
                  className="mt-1 d-block"
                  size={"small"}
                  style={{ color: "red" }}
                >
                  {errors.youtube && touched.youtube ? (
                    <Fragment>{errors.youtube}</Fragment>
                  ) : null}
                </span>
              </div>

              <div className="mt-5">
                <button className={`${Classes.blueBtn} w-100`} type="submit">
                  {isLoading ? (
                    <div className="d-flex justify-content-center align-items-center">
                      <ThreeDots
                        height="30"
                        width="30"
                        radius="9"
                        color="white"
                        ariaLabel="three-dots-loading"
                        wrapperStyle={{}}
                        wrapperClassName=""
                        visible={true}
                      />
                    </div>
                  ) : (
                    "Save Changes"
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default SocialMedia;
