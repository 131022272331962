// import { useRef, useEffect } from 'react';

// export const VideoComponent = ({ source }) => {
//   const videoRef = useRef(null);

//   useEffect(() => {
//     const handlePlay = () => {
//       videoRef.current.muted = false;
//     };

//     const handlePause = () => {
//       videoRef.current.muted = true;
//     };

//     const videoElement = videoRef.current;

//     if (videoElement) {
//       videoElement.addEventListener('play', handlePlay);
//       videoElement.addEventListener('pause', handlePause);

//       // Mute the video initially
//       videoElement.muted = true;

//       return () => {
//         videoElement.removeEventListener('play', handlePlay);
//         videoElement.removeEventListener('pause', handlePause);
//       };
//     }
//   }, []);

//   return (
//     <video ref={videoRef} width='100%' height='300' controls>
//       <source src={source} type='video/mp4' />
//       Your browser does not support the video tag.
//     </video>
//   );
// };
import React, { useRef, useEffect } from "react";

const VideoComponent = ({ source }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    const handlePlay = () => {
      videoRef.current.muted = false;
    };

    const handlePause = () => {
      videoRef.current.muted = true;
    };

    const videoElement = videoRef.current;

    if (videoElement) {
      videoElement.addEventListener("play", handlePlay);
      videoElement.addEventListener("pause", handlePause);

      // Mute the video initially
      videoElement.muted = true;

      return () => {
        videoElement.removeEventListener("play", handlePlay);
        videoElement.removeEventListener("pause", handlePause);
      };
    }
  }, []);
  return (
    <div>
      <video ref={videoRef} width="100%" height="300" controls>
        <source src={source} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};

export default VideoComponent;
