import React from "react";
import Classes from "./moreItems.module.scss";
import { Col, Row } from "antd";
import NFTImg from "../../assets/images/section/nft1.svg";
import { useNavigate } from "react-router-dom";
import Polygon from "../../assets/images/icons/polygon.svg";
import { CHAIN_CURRENCY } from "../../helpers/common";
import { useSelector } from "react-redux";
import VideoComponent from "../../helpers/VideoPreview";

function MoreItems({ data }) {
  const navigate = useNavigate();
  const user = useSelector((state) => state.auth);
  const { connectedAddress } = useSelector((state) => state.wallet);
  return (
    <div className={Classes.moreItems}>
      <p className={Classes.title}>More like this</p>
      <Row gutter={[20, 20]}>
        {data && data.length > 0 ? (
          data?.map((item) => (
            <Col lg={6} md={12} sm={12} xs={24} key={item?._id}>
              <div
                className={Classes.nftCard}
                onClick={() => navigate(`/nft/${item?._id}`)}
              >
                {item?.primaryType === "image" ? (
                  <img
                    className={Classes.nftImg}
                    src={item?.file || item?.nftImage || NFTImg}
                    alt="img"
                  />
                ) : (
                  <VideoComponent source={item?.file} />
                )}

                <div className="p-2">
                  <div className="d-flex align-items-center justify-content-between my-2">
                    <div>
                      <h3 className={Classes.title2}>{item?.name}</h3>

                      {item?.userId?.firstName && (
                        <span
                          className={Classes.desc}
                          onClick={() =>
                            navigate(`/user/${item?.userId?.username}`)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Owned by:&nbsp;
                          <b className={Classes.owner}>
                            {item?.ownerId === user?.id &&
                            connectedAddress === item?.ownerAddress
                              ? "You"
                              : item?.userId?.firstName}
                          </b>
                        </span>
                      )}
                    </div>
                    <img
                      className="align-self-start pt-2"
                      src={Polygon}
                      alt="icon"
                    />
                  </div>
                  <div className="d-flex justify-content-between align-items-ceter">
                    <p className={Classes.txt}>Price</p>
                    <p className={Classes.price}>
                      {item?.currentPrice}{" "}
                      {CHAIN_CURRENCY[item?.blockchainType]}
                    </p>
                  </div>
                </div>
              </div>
            </Col>
          ))
        ) : (
          <span className="ps-2">
            No related NFTs are available at the moment.
          </span>
        )}
      </Row>
    </div>
  );
}

export default MoreItems;
