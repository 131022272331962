import { NOTIFICATIONS } from '../constants';
// eslint-disable-next-line

const initialUserState = {
  notifications: [],
};

export const NotificationReducer = (state = initialUserState, action) => {
  switch (action.type) {
    case NOTIFICATIONS:
      return {
        ...state,
        notifications: action.payload,
      };
    default:
      return state;
  }
};
