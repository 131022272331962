import React from "react";
import Classes from "./nftCard.module.scss";

const PublicNftCard = ({ org }) => {
  return (
    <div className={Classes.pubnftCard}>
      <img src={org?.categoryImage} className={`${Classes.img}`} alt="img" />
      <p className={Classes.txt}>{org?.categoryName}</p>
    </div>
  );
};

export default PublicNftCard;
