// @ts-ignore
import { NOTIFICATIONS } from '../constants';
const notifications = (data) => {
  return {
    type: NOTIFICATIONS,
    payload: data,
  };
};

export const userNotificationList = (data, callback) => {
  return async (dispatch) => {
    try {
      console.log('datadatadatadata :', data);
      await dispatch(notifications(data));
      // callback();
    } catch (error) {
      console.log('Error: ', error.message);
      console.log('Error: ', error);
    }
  };
};
