import React, { Fragment, useState } from "react";
import Classes from "../Invite/invite.module.scss";
import { Modal } from "antd";
import * as Yup from "yup";
import { Formik } from "formik";
import { ThreeDots } from "react-loader-spinner";
import {
  einVerification,
  inviteFiscalSponsor,
} from "../../services/auth.service";
import { alertMessage } from "../../constants/alertMessage";

const EinVerificationModal = ({
  isModalOpen,
  handleCancel,
  setIrsOrganizationData,
  modalTitle,
  accountType,
  setSelectedOption,
  einSubHeading,
  handleClickNext,
  consentState,
  setShowExistingEinError,
  showExistingEinError
}) => {
  const [loading, setLoading] = useState(false);
  const [nonProfitInfo, setNonProfitInfo] = useState("");
  const token = localStorage.getItem("authenticationToken");
  const handleSendInvite = async (data, resetForm) => {
    try {
      setLoading(true);
      const requestData = {
        ein: data?.einNumber,
        token,
        organizationType: accountType ? accountType : "",
      };
      const response = await einVerification(requestData);

      if (response?.data?.data && response?.data?.data?.name) {
        if (response?.data?.data?.isRegistered === false) {
          setIrsOrganizationData("");
          resetForm({ values: "" });
          setLoading(false);
        } else {
          setIrsOrganizationData(response?.data?.data);
        }
        setNonProfitInfo(response?.data?.data);
        alertMessage("success", `${response?.data?.message}`);
        resetForm({ values: "" });
        setLoading(false);
      } else {
        if(response?.message === 'Your nonprofit is already registered on our platform, please contact your nonprofit system administrator for login access.'){
          setLoading(false);
          setShowExistingEinError(true)
          return;
        }
        else{
          alertMessage("error", `${response?.message}`);
        }
        setLoading(false);
        resetForm({ values: "" });
      }
    } catch (error) {
      console.log(error);
      alertMessage("error", `${error}`);
      setLoading(false);
    }
  };

  const handleInviteFiscalSponsor = async (data, resetForm) => {
    try {
      setLoading(true);
      const requestData = {
        email: data?.email,
        token,
      };
      if (token) {
        const response = await inviteFiscalSponsor(requestData);
        if (response?.data?.success) {
          setIrsOrganizationData({ email: data?.email });
          sessionStorage.setItem('showInviteSentModal', true);
          sessionStorage.setItem('fiscalSponsorEmailAddress', data?.email);
          resetForm({ values: "" });
          handleEinAccept();
          setLoading(false);
        } else {
          alertMessage("error", `${response?.message}`);
          setLoading(false);
        }
      } else {
        alertMessage("error", "Authentication token is required.");
      }
    } catch (error) {
      console.log(error);
      alertMessage("error", `${error}`);
      setLoading(false);
    }
  };

  const handleEinAccept = async () => {
    setNonProfitInfo("");
    handleCancel();
    handleClickNext();
  };
  const handleEinReject = async () => {
    // setSelectedOption("");
    setNonProfitInfo("");
    setIrsOrganizationData("");
  };
  return (
    <Modal
      title="Nonprofit Organization"
      open={isModalOpen}
      onCancel={handleCancel}
      footer={null}
    >
      {nonProfitInfo && nonProfitInfo?.isRegistered === false ? (
        <>
          <Formik
            initialValues={{
              email: "",
            }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email("Email is invalid.")
                .trim()
                .max(50, "Email should not exceed 50 characters.")
                .required("Email is required."),
            })}
            onSubmit={(values, { resetForm }) => {
              const data = {
                email: values.email,
              };
              handleInviteFiscalSponsor(data, resetForm);
            }}
            textAlign="center"
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values,
            }) => (
              <form
                onSubmit={handleSubmit}
                className="mt-8 space-y-6"
                noValidate
                autoComplete="off"
              >
                <div className={`${Classes.addContent} py-2`}>
                  <div className="mb-3">
                    <p className={Classes.label}>
                      Please enter your fiscal sponsor's email address
                    </p>
                    <input
                      type="email"
                      name="email"
                      placeholder={
                        "Please enter your fiscal sponsor's email address"
                      }
                      onBlur={handleBlur}
                      onChange={handleChange}
                      value={values.email || ""}
                    />
                    <span
                      className="mt-1 d-block"
                      size={"small"}
                      style={{ color: "red" }}
                    >
                      {errors.email && touched.email ? (
                        <Fragment>{errors.email}</Fragment>
                      ) : null}
                    </span>
                  </div>
                  <div className="d-flex justify-content-center align-items-center gap-3">
                    <button
                      className={`${Classes.sendbtn} w-100`}
                      type="submit"
                    >
                      {loading ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <ThreeDots
                            height="30"
                            width="30"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Send Invite Request"
                      )}
                    </button>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </>
      ) : nonProfitInfo ? (
        <>
          <h3>
            <b> {einSubHeading}</b>
          </h3>
          <div className="justify-content-center align-items-center gap-3">
            <div className="mb-4">
              <div className="mb-1">
                <span>Nonprofit EIN Number:</span> <b>{nonProfitInfo?.ein}</b>
              </div>
              <div className="mb-1">
                <span>Nonprofit Name:</span> <b>{nonProfitInfo?.name}</b>
              </div>
            </div>
            <div
              className={`${Classes.addContent} d-flex justify-content-center align-items-center gap-3`}
            >
              <button
                className={`${Classes.rejectbtn} ${Classes.reject}`}
                onClick={() => handleEinReject()}
              >
                No
              </button>
              <button
                className={`${Classes.sendbtn}`}
                onClick={() => handleEinAccept()}
              >
                Yes
              </button>
            </div>
          </div>
        </>
      ) : (
        <Formik
          initialValues={{
            einNumber: "",
          }}
          validationSchema={Yup.object().shape({
            einNumber: Yup.string()
              .required("EIN number is required.")
              .matches(/^[0-9]+$/, "EIN number must contain only digits.")
              .min(9, "EIN number must be exactly 9 digits.")
              .max(9, "EIN number must not exceed 9 digits."),
          })}
          onSubmit={(values, { resetForm }) => {
            const data = {
              einNumber: values.einNumber,
            };
            handleSendInvite(data, resetForm);
          }}
          textAlign="center"
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form
              onSubmit={handleSubmit}
              className="mt-8 space-y-6"
              noValidate
              autoComplete="off"
            >
              <div className={`${Classes.addContent} py-2`}>
                <div className="mb-3">
                  <p className={Classes.label}>{modalTitle}</p>
                  <input
                    type="text"
                    name="einNumber"
                    placeholder={modalTitle}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.einNumber}
                  />
                  <span
                    className="mt-1 d-block"
                    size={"small"}
                    style={{ color: "red" }}
                  >
                    {errors.einNumber && touched.einNumber ? (
                      <Fragment>{errors.einNumber}</Fragment>
                    ) : null}
                  </span>
                </div>
                <div className="d-flex justify-content-center align-items-center gap-3">
                  <button className={`${Classes.sendbtn} w-100`} type="submit">
                    {loading ? (
                      <div className="d-flex justify-content-center align-items-center">
                        <ThreeDots
                          height="30"
                          width="30"
                          radius="9"
                          color="white"
                          ariaLabel="three-dots-loading"
                          wrapperStyle={{}}
                          wrapperClassName=""
                          visible={true}
                        />
                      </div>
                    ) : (
                      "Verify"
                    )}
                  </button>
                </div>
              </div>
              {showExistingEinError &&
              <>
              <br/>
              <p style={{color:'red'}}><strong>Your nonprofit is already registered on our platform.</strong></p>
              <br/>
              <p>Please contact your nonprofit’s authorized administrator for login access.</p>
              <br/>
              <p>If you are the nonprofit’s authorized administrator, please contact us immediately at <a href='#'>support@charitynft.org</a> with all of the following information:</p>
              <ol>
                <li>Your full name</li>
                <li>Nonprofit EIN</li>
                <li>Nonprofit website URL</li>
                <li>Nonprofit mailing address</li>
                <li>Nonprofit phone number</li>
                <li>A digital copy of your IRS determination letter</li>
                <li>Either your verified LinkedIn profile URL or a digital copy of your driver’s license</li>
            </ol>
            <p>We will consider your request if and only if you have provided all 7 pieces of information above in one email.</p></>}
            </form>
          )}
        </Formik>
      )}
    </Modal>
   
  );
};

export default EinVerificationModal;
