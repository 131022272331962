import React, { useState, useEffect } from "react";
import Classes from "./notification.module.scss";
import { Checkbox } from "antd";
import { listNotificationSettings } from "../../services/settings.service";
import { manageNotificationSettings } from "../../services/auth.service";
import { useSelector, useDispatch } from "react-redux";
import { alertMessage } from "../../constants/alertMessage";
import { updateNotificationSettings } from "../../store/_actions/user_action";

const Notification = () => {
  const token = localStorage.getItem("authenticationToken");
  const [notificationSettings, setNotificationSettings] = useState([]);
  const [notificationIds, setNotificationIds] = useState([]);
  const [settingType, setSettingType] = useState("");
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const handleNotificationSettings = async () => {
    try {
      if (token) {
        const response = await listNotificationSettings(token);
        setNotificationSettings(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onChange = (_id, e) => {
    setNotificationIds((prevIds) => {
      if (e.target.checked) {
        setSettingType("push");
        return [...prevIds, _id];
      } else {
        setSettingType("pop");
        return prevIds.filter((id) => id !== _id);
      }
    });
  };
  useEffect(() => {
    setNotificationIds(auth?.notificationIds || []);
  }, []);
  useEffect(() => {
    handleNotificationSettings();
  }, [notificationIds]);

  const handleUserNotificationSettings = async () => {
    try {
      if (token) {
        const data = { token, notificationIds };
        const response = await manageNotificationSettings(data);
        if (response?.data?.status === 200) {
          alertMessage("success", "Notification settings updated successfully");
          dispatch(updateNotificationSettings(notificationIds));
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className={Classes.notification}>
      <h1 className={Classes.title}>Notification Settings</h1>
      <div className={Classes.formOuter}>
        <div className={`${Classes.checkboxOuter} mb-5`}>
          {notificationSettings &&
            notificationSettings.map((notificationSetting) => {
              const showCheckbox = !(
                auth?.role === "user" &&
                notificationSetting?.name === "Donation to organization"
              );

              return (
                showCheckbox && (
                  <Checkbox
                    key={notificationSetting?._id}
                    checked={notificationIds?.includes(
                      notificationSetting?._id
                    )}
                    onChange={(e) => onChange(notificationSetting?._id, e)}
                  >
                    <div className="ps-3">
                      <h4 className={Classes.label}>
                        {notificationSetting?.description}
                      </h4>
                    </div>
                  </Checkbox>
                )
              );
            })}
        </div>
        <div className="d-flex justify-content-end">
          <button
            className={Classes.blueBtn}
            onClick={() => handleUserNotificationSettings()}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default Notification;
