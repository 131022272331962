// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import CONTRACT_DETAILS from "../contract/contractInfo";

const marketContractAddress = CONTRACT_DETAILS.market.address;
const marketplaceABI = CONTRACT_DETAILS.market.abi;

const useAuctionEnding = () => {
  //approve contract
  const {
    isError: isAuctionEndingSentError,
    data: auctionEndingSentTx,
    isLoading: isAuctionEndingTxInProgress,
    isSuccess: isAuctionEndingTxSent,
    write: auctionEnding,
    error: auctionEndingSentError
  } = useContractWrite({
    address: marketContractAddress,
    abi: marketplaceABI,
    functionName: "endAuction", // Changed the functionName to cancelListing
  });

  const {
    isLoading: isAuctionEndingWaiting,
    isSuccess: isAuctionEndingCompleted,
    isError: isAuctionEndingError,
    error: auctionEndingTxError,
    data: auctionEndingResp
  } = useWaitForTransaction({
    hash: auctionEndingSentTx?.hash,
  });

  return {
    isAuctionEndingSentError,
    auctionEndingSentTx,
    isAuctionEndingTxInProgress,
    isAuctionEndingTxSent,
    auctionEnding,
    auctionEndingSentError,
    isAuctionEndingWaiting,
    isAuctionEndingCompleted,
    isAuctionEndingError,
    auctionEndingTxError,
    auctionEndingResp
  };
};

export default useAuctionEnding;
