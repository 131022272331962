import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Classes from "./selection.module.scss";
import { useLocation, useNavigate } from "react-router-dom";
import { Col, Row } from "antd";
import { ThreeDots } from "react-loader-spinner";
import Check from "../../assets/images/icons/check_circle.svg";
import { Container } from "react-bootstrap";
import { alertMessage } from "../../constants/alertMessage";
import {
  setupProfile,
  setupOrganizationProfile,
} from "../../services/auth.service";
import { getCategories } from "../../store/_actions/categories_actions";
import { thunkLogin } from "../../store/_actions/user_action";
import NotificationAlert from "../Alert";


const Selection = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState([]);
  const [loading, setIsLoading] = useState(false);
  const [showCauseError, setShowCauseError] = useState(false);
  const auth = useSelector((state) => state.auth);
  const { categories } = useSelector((state) => state?.categories);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
  }, []);

  useEffect(() => {
    if (!auth?.email) {
      navigate("/login");
      alertMessage("error", "Authentication required.");
    }

    if (auth?.username && auth?.email) {
      navigate("/profile");
    }
  }, []);

  const handleOptionClick = (option) => {
    if (selectedOption.includes(option)) {
      setSelectedOption(
        selectedOption.filter(
          (item) => item?.categoryName !== option?.categoryName
        )
      );
    } else {
      setSelectedOption([...selectedOption, option]);
    }
  };

  const isAccountTypeSelected = () => {
    if (selectedOption?.length < 1) {
      setShowCauseError(true)
      setIsAlertVisible(true)
      return false;
    } else {
      setShowCauseError(false)
      completeProfile();
    }
  };

  const isOptionSelected = (option) => {
    return selectedOption.includes(option);
  };
  const completeProfile = async () => {
    try {
      const token = localStorage.getItem("authenticationToken");

      const categoryIds = [];
      setIsLoading(true);
      selectedOption?.map((opt) => {
        categoryIds.push(opt?._id);
      });

      let response;
      let data;

      if (location?.state?.userData?.role === "user") {
        data = {
          ...location?.state?.userData,
          categories: categoryIds,
        };
        response = await setupProfile(data, token);
      } else {
        data = {
          ...location?.state,
          categories: categoryIds,
        };
        response = await setupOrganizationProfile(data, token);
      }

      if (response && response.data.success === true) {
        dispatch(
          thunkLogin(response?.data?.data, () => {
            alertMessage("success", `${response?.data?.message}`);
            setIsLoading(false);
            setTimeout(() => {
              navigate("/profile");
            }, 1000);
          })
        );
      } else {
        alertMessage("error", `${response?.message}`);
        setIsLoading(false);
      }
    } catch (error) {
      alertMessage("error", "Something went wrong.");
      setIsLoading(false);
      console.log("error.response", error.response.message);
    }
  };

  return (
    <Container>
      <div
        className={`${Classes.mainOuter} d-flex justify-content-center align-items-center flex-column`}
      >
        <h1 className={Classes.title}>Charity NFT</h1>
        <div className={Classes.content}>
          <div className="mb-4">
            <div className="mb-4">
              <h2 className={Classes.subTitle}>Select your causes</h2>
              <p className={Classes.desc}>
                You can select a minimum of 1 cause.
              </p>
            </div>
            <Row gutter={[20, 20]} className="mb-4">
              {categories &&
                categories?.map((category, index) => (
                  <Col lg={6} md={8} sm={12} xs={24}>
                    <div
                      className={`${Classes.selection} ${
                        isOptionSelected(category) ? Classes.selected : ""
                      }`}
                      onClick={() => handleOptionClick(category)}
                      index={index}
                    >
                      <img
                        className={Classes.selectionImg}
                        src={category?.categoryImage}
                        alt="img"
                      />
                      <div
                        className={`${Classes.bottom} d-flex justify-content-center gap-1 align-items-center px-2 py-1 mt-1`}
                      >
                        <h3 className={Classes.subTxt}>
                          {category?.categoryName}
                        </h3>
                        <img className={Classes.check} src={Check} alt="img" />
                      </div>
                    </div>
                  </Col>
                ))}
            </Row>
          </div>
          <div className="d-flex justify-content-end">
            <button
              onClick={() => isAccountTypeSelected()}
              disabled={loading}
              className={Classes.blueBtn}
            >
              {loading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <ThreeDots
                    height="30"
                    width="30"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                "Finish"
              )}
            </button>
          </div>
        </div>
      {showCauseError && <NotificationAlert title="Select Cause" content='Please select one or more causes.' visible={isAlertVisible}
          onClose={() => setIsAlertVisible(false)}/>}

      </div>
    </Container>
  );
};

export default Selection;
