import React from "react";
import { Modal } from "antd";
import Classes from "../../FriendsDetailsComp/friendsDetails.module.scss";

const CommonModal = ({ isModalOpen, handleCancel, message, modalType }) => {
  return (
    <Modal open={isModalOpen} footer={null} onCancel={handleCancel}>
      <div
        className={`${Classes.modalContent} d-flex justify-content-center align-items-center gap-3 flex-column py-2`}
      >
        <h1>{modalType}</h1>
        <p className="mb-3">{message}</p>
        <div className="d-flex gap-3">
          <button
            className={`${Classes.btn} ${Classes.reject}`}
            onClick={handleCancel}
          >
            Close
          </button>
          {/* <button
            className={`${Classes.btn} ${Classes.accept}`}
            onClick={handleCancel}
          >
            No
          </button> */}
        </div>
      </div>
    </Modal>
  );
};

export default CommonModal;
