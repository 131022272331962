import React, { useState, Fragment } from "react";
import Classes from "./CreateCollectionComp.module.scss";
import UploadImg from "../../assets/images/svg/upload.svg";
import { Col, Row, Modal } from "antd";
import * as Yup from "yup";
import { Formik } from "formik";
import CollectionImg from "../../assets/images/section/collection.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { addNewCollection } from "../../services/collections.service";
import { ThreeDots } from "react-loader-spinner";
import backbtn from "../../assets/images/icons/back.svg";
import { Container } from "react-bootstrap";
import { alertMessage } from "../../constants/alertMessage";
import { FaTrash } from "react-icons/fa6";

const CreateCollectionComp = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [file, setFile] = useState("");
  const [logoErrorMessage, setLogoErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [collectionPreviewImage, setCollectionPreviewImage] = useState("");
  const [collectionPreviewName, setCollectionPreviewName] = useState("");

  const auth = useSelector((state) => state.auth);
  const authToken = localStorage.getItem("authenticationToken");
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const createCollection = async (data) => {
    try {
      if (authToken) {
        setIsLoading(true);
        if (!file) {
          setLogoErrorMessage("Collection image is required.");
          setIsLoading(false);

          return;
        }
        const collection = await addNewCollection(data, authToken, file);
        if (collection?.data?.data) {
          setMessage(collection?.data?.message);
          showModal();
          setIsLoading(false);
        } else {
          alertMessage("error", `${collection?.message}`);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log("error :", error);
      setIsLoading(false);
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const allowedTypes = [
        "image/jpeg",
        "image/png",
        "image/gif",
        "image/svg+xml",
      ];
      if (!allowedTypes.includes(file.type)) {
        alertMessage(
          "error",
          "Unsupported file type. Please select a JPG, PNG, GIF, or SVG file."
        );
        return;
      }
      // Check file size
      const maxSize = 50 * 1024 * 1024; // 50 MB
      if (file.size > maxSize) {
        alertMessage(
          "error",
          "File size exceeds the maximum limit (50 MB). Please choose a smaller file."
        );
        return;
      }

      setFile(file);
      setLogoErrorMessage("");
      const reader = new FileReader();
      reader.onload = (event) => {
        const uploadedImageUrl = event.target.result;
        setCollectionPreviewImage(uploadedImageUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  const removeImage = () => {
    setFile();
    setCollectionPreviewImage();
  };

  return (
    <div className={`${Classes.createCollection}`}>
      <div className="d-flex gap-3 align-items-center mb-3">
        <Link to="/create-nft">
          <img src={backbtn} alt="icon" />
        </Link>
        <h1 className={`${Classes.title}`}>Create Collection</h1>
      </div>
      {/* <p className={Classes.desc}>
        You’ll need to deploy an ERC-721 contract onto the blockchain before you
        can create a drop.
        <a className='ms-1' href='#'>
          What is a contract?
        </a>
      </p> */}
      <>
        <Formik
          initialValues={{
            collectionName: "",
            description: "",
          }}
          validationSchema={Yup.object().shape({
            collectionName: Yup.string()
              .trim()
              .max(
                30,
                ({ max }) => `Collection should not exceed ${max} characters.`
              )
              .required("Collection name is required."),
            description: Yup.string()
              .trim()
              .required("Description is required."),
          })}
          onSubmit={(values, { resetForm }) => {
            const data = {
              userId: auth?.id,
              description: values.description,
              collectionName: values.collectionName,
            };
            if (data) createCollection(data);
          }}
          textAlign="center"
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form
              onSubmit={handleSubmit}
              className="mt-8 space-y-6"
              noValidate
              autoComplete="off"
              style={{ width: "100%" }}
            >
              <Row gutter={[20, 20]} className="mb-5">
                <Col lg={7} md={24} sm={24} xs={24}>
                  <div className={Classes.label}>Logo Image*</div>
                  <div className={Classes.nftCard}>
                    {collectionPreviewImage ? (
                      <div className="position-relative">
                        <div
                          className={Classes.actionBtn}
                          onClick={() => removeImage()}
                        >
                          <FaTrash color="red" />
                        </div>
                        <img
                          className={Classes.imgUpload}
                          src={collectionPreviewImage}
                          alt="img"
                        />
                      </div>
                    ) : (
                      <div className={Classes.previewimg}></div>
                    )}

                    <div className={Classes.cardInfo}>
                      <div
                        className={`d-flex justify-content-between align-items-center mb-2`}
                      >
                        <p className={Classes.price}>
                          {collectionPreviewName ? (
                            collectionPreviewName
                          ) : (
                            <span className={Classes.previewTxt}></span>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={17} md={24} sm={24} xs={24} className="mb-4">
                  <h3 className={`${Classes.subtitle} mt-1`}>Upload file*</h3>
                  <p className={Classes.txt}>Choose your file to upload</p>
                  <div className="position-relative">
                    <div className="d-flex align-items-center gap-2">
                      <img src={UploadImg} alt="img" />
                      <p className={Classes.uploadTxt}>Upload your file</p>
                    </div>
                    <input
                      className={Classes.uploadInput}
                      type="file"
                      accept=".jpg, .jpeg, .png, .gif, .svg"
                      onChange={handleImageUpload}
                    />
                    <span
                      className="mt-1 d-block"
                      size={"small"}
                      style={{ color: "red" }}
                    >
                      {logoErrorMessage && (
                        <Fragment>{logoErrorMessage}</Fragment>
                      )}
                    </span>
                  </div>
                  <p className={`${Classes.txt} mt-2`}>
                    Maximum size 50 MB and file type (JPG, PNG, GIF & SVG)
                  </p>
                  <div className="my-4">
                    <div className={Classes.label}>Collection name*</div>
                    <input
                      className={Classes.input}
                      placeholder="e.g: Crypto Hunks"
                      type="text"
                      name="collectionName"
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleChange(e);
                        setCollectionPreviewName(e.target.value);
                      }}
                      value={values.collectionName}
                    />
                    <span
                      className="mt-1 d-block"
                      size={"small"}
                      style={{ color: "red" }}
                    >
                      {errors.collectionName && touched.collectionName ? (
                        <Fragment>{errors.collectionName}</Fragment>
                      ) : null}
                    </span>
                  </div>
                  <div className={Classes.label}>Description*</div>
                  <textarea
                    className={Classes.input}
                    type="text"
                    placeholder="e.g: This is a very limited item"
                    rows={4}
                    name="description"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.description}
                  />
                  <span
                    className="mt-1 d-block"
                    size={"small"}
                    style={{ color: "red" }}
                  >
                    {errors.description && touched.description ? (
                      <Fragment>{errors.description}</Fragment>
                    ) : null}
                  </span>
                </Col>
              </Row>

              <div className={Classes.footer}>
                <Container>
                  <div className={Classes.btnOuter}>
                    <button type="submit" className={`${Classes.blueBtn} ms-2`}>
                      {isLoading ? (
                        <div className="d-flex justify-content-center align-items-center">
                          <ThreeDots
                            height="30"
                            width="30"
                            radius="9"
                            color="white"
                            ariaLabel="three-dots-loading"
                            wrapperStyle={{}}
                            wrapperClassName=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        "Save"
                      )}
                    </button>
                  </div>
                </Container>
              </div>
            </form>
          )}
        </Formik>
        <div className="mb-4"></div>
      </>
      <Modal open={isModalOpen} footer={null} onCancel={handleCancel}>
        <div
          className={`${Classes.modalInner} d-flex justify-content-center align-items-center gap-3 flex-column py-4`}
        >
          <img src={collectionPreviewImage || CollectionImg} alt="img" />
          <p>{message}</p>
          <Link to="/create-nft" className={Classes.blueBtn}>
            Continue Creating NFT
          </Link>
        </div>
      </Modal>
    </div>
  );
};

export default CreateCollectionComp;
