// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import CONTRACT_DETAILS from "../contract/contractInfo";

const marketContractAddress = CONTRACT_DETAILS.market.address;
const marketplaceABI = CONTRACT_DETAILS.market.abi;

const useBuyNftForUser = () => {
  //approve contract
  const {
    isError: isBuyNftUserSentError,
    data: buyNftUserSentTx,
    isLoading: isBuyNftUserTxInProgress,
    isSuccess: isBuyNftUserTxSent,
    write: buyNftForUser,
    error: buyNftUserSentError
  } = useContractWrite({
    address: marketContractAddress,
    abi: marketplaceABI,
    functionName: "BuyFixedPriceItem", // Changed the functionName to buyNft
  });

  const {
    isLoading: isBuyNftUserWaiting,
    isSuccess: isBuyNftUserCompleted,
    isError: isBuyNftUserError,
    error: buyNftUserTxError,
    data: buyNftUserResp
  } = useWaitForTransaction({
    hash: buyNftUserSentTx?.hash,
  });

  return {
    //nft operation
    isBuyNftUserSentError,
    buyNftUserSentTx,
    isBuyNftUserTxInProgress,
    isBuyNftUserTxSent,
    buyNftForUser, // Renamed the function
    buyNftUserSentError,
    isBuyNftUserWaiting,
    isBuyNftUserCompleted,
    isBuyNftUserError,
    buyNftUserTxError,
    buyNftUserResp
  };
};

export default useBuyNftForUser;
