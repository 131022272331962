import React, { useState } from "react";
import Classes from "./featured.module.scss";
import { Container } from "react-bootstrap";
import Search from "../../assets/images/icons/searchbutton.svg";
import SearchMain from "../SearchMain";
import OrganizationCard from "../OrganizationCard";
import { Col, Row } from "antd";

function FeaturedOrgDetailComp() {
  const [show, setShow] = useState("all");
  const [showSearch, setShowSearch] = useState(false);
  const data = [1, 2, 3, 4, 5, 6];
  return (
    <div className={Classes.featuredNftDetailComp}>
      <Container>
        <h1 className={Classes.title}>Feature organizations</h1>
        <div className="d-flex gap-5 align-items-center justify-content-between">
          <div className="d-flex gap-5 align-items-center mb-4">
            <button
              onClick={() => setShow("all")}
              className={
                show === "all" ? `${Classes.activeTab}` : `${Classes.tab}`
              }
            >
              All
            </button>
            <button
              onClick={() => setShow("new")}
              className={
                show === "new" ? `${Classes.activeTab}` : `${Classes.tab}`
              }
            >
              New
            </button>
          </div>
          <div
            className="cursor_pointer"
            onClick={() => setShowSearch(!showSearch)}
          >
            <img src={Search} alt="icon" />
          </div>
        </div>
        {showSearch && (
          <div className="mb-4">
            <SearchMain />
          </div>
        )}
        {show === "all" && (
          <Row>
            {data?.map((i) => (
              <Col md={8} key={i}>
                <OrganizationCard />
              </Col>
            ))}
          </Row>
        )}
        {show === "new" && (
          <Row>
            <Col md={8}>
              <OrganizationCard />
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
}

export default FeaturedOrgDetailComp;
