import React, { useEffect, useState } from "react";
import Classes from "./uploadProfile.module.scss";
import img1 from "../../../assets/images/section/user.svg";
import img2 from "../../../assets/images/section/greenuser.svg";
import img3 from "../../../assets/images/section/pinkuser.svg";
import img4 from "../../../assets/images/section/darkBlueuser.svg";
import img5 from "../../../assets/images/section/purpleuser.svg";
import img6 from "../../../assets/images/section/yellowuser.svg";
import img7 from "../../../assets/images/section/reduser.svg";
import img8 from "../../../assets/images/section/peachuser.svg";
import img9 from "../../../assets/images/section/darkPurpleuser.svg";
import img10 from "../../../assets/images/section/orangeuser.svg";
import img11 from "../../../assets/images/section/skyblueuser.svg";
import img12 from "../../../assets/images/section/parrotuser.svg";

const UploadProfile = ({ setProfilePicture, setFormFieldsData }) => {
  const [tabs, setTabs] = useState("avatar");
  const [profileImg, setProfileImg] = useState(img4);
  const imageUrls = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
  ];
  useEffect(() => {
    setProfileImg(img4);
    setProfilePicture(`${process.env.REACT_APP_BASE_URL}${img11}`);
    setFormFieldsData((prevState) => ({
      ...prevState,
      profilePicture: `${process.env.REACT_APP_BASE_URL}${img11}`,
    }));
  }, []);

  const handleImageClick = (imageUrl) => {
    setProfileImg(imageUrl);
    setProfilePicture(`${process.env.REACT_APP_BASE_URL}${imageUrl}`);
    setFormFieldsData((prevState) => ({
      ...prevState,
      profilePicture: `${process.env.REACT_APP_BASE_URL}${imageUrl}`,
    }));
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(file);
      setFormFieldsData((prevState) => ({
        ...prevState,
        profilePicture: file,
      }));
      const reader = new FileReader();
      reader.onload = (event) => {
        const uploadedImageUrl = event.target.result;
        setProfileImg(uploadedImageUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={Classes.uploadProfile}>
      <div>
        <img className={Classes.imgUpload} src={profileImg} alt="img" />
        <div className="position-relative">
          <div className={`${Classes.uploadTxt} mt-3`}>
            Upload your own avatar
          </div>
          <input
            className={Classes.inputFile}
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
          />
        </div>
      </div>
      <div className={Classes.imgesOuter}>
        <div className="d-flex gap-3 align-items-center mb-3">
          <div className={Classes.images}>
            {imageUrls.map((imageUrl, i) => (
              <div
                key={i}
                className="grid-item"
                onClick={() => handleImageClick(imageUrl)}
              >
                <img
                  src={imageUrl}
                  alt={`Img ${i}`}
                  className={Classes.shortImg}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadProfile;