// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import CONTRACT_DETAILS from "../contract/contractInfo";
const marketContractAddress = CONTRACT_DETAILS.market.address;
const marketplaceABI = CONTRACT_DETAILS.market.abi;
const useSetDefaultFiscalSponsor = () => {

  const {
    isError: isFiscalSponsorSentError,
    data: fiscalSponsorSentTx,
    isLoading: isFiscalSponsorTxInProgress,
    isSuccess: isFiscalSponsorTxSent,
    write: setFiscalSponsor,
    error: fiscalSponsorSentError
  } = useContractWrite({
    address: marketContractAddress,
    abi: marketplaceABI,
    functionName: "setFiscalSponsorPercentage",
  });
  const {
    isLoading: isFiscalSponsorWaiting,
    isSuccess: isFiscalSponsorCompleted,
    isError: isFiscalSponsorError,
    error: fiscalSponsorTxError,
    data: fiscalSponsorMintResp
  } = useWaitForTransaction({
    hash: fiscalSponsorSentTx?.hash,
  });

  return {
    //nft operation
    isFiscalSponsorSentError,
    fiscalSponsorSentTx,
    isFiscalSponsorTxInProgress,
    isFiscalSponsorTxSent,
    setFiscalSponsor, // Renamed the function
    fiscalSponsorSentError,
    isFiscalSponsorWaiting,
    isFiscalSponsorCompleted,
    isFiscalSponsorError,
    fiscalSponsorTxError,
    fiscalSponsorMintResp
  };
};

export default useSetDefaultFiscalSponsor;
