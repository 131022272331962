import React from "react";
import NotificationComp from "../../NotificationComp";
import LandingLayout from "../../layout/LandingLayout";

const Settings = () => {
  return (
    <>
      <LandingLayout>
        <NotificationComp />
      </LandingLayout>
    </>
  );
};

export default Settings;
