import React from 'react';
import DocumentComp from '../../Documents';

const UploadDocument = () => {
  return (
    <>
      <DocumentComp />
    </>
  );
};

export default UploadDocument;
