import React from 'react';
import Classes from './featuredcard.module.scss';
import { Col, Row } from 'antd';
import NFTImg from '../../assets/images/section/nftImg.svg';

import Varified from '../../assets/images/icons/varified.svg';
import GreyStar from '../../assets/images/icons/greyStar.svg';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

const FeaturedUserCard = ({ data }) => {
  const navigate = useNavigate();
  const { username, firstName, lastName, profilePicture, _id } = data;
  const location = useLocation();
  const isExploreRoute = location.pathname === '/explore';

  console.log('datadata :', data);
  return (
    // <div>
    <Col lg={8} md={8} sm={24} xs={24}>
      <div className={`${Classes.featuredCard} ${isExploreRoute ? Classes.explore : ''}`}>
        <Row
          className='d-flex justify-content-between mb-3'
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`/user/${username}`, { state: data })}
        >
          <Col md={24}>
            <img
              className={Classes.nftImg}
              src={profilePicture ? profilePicture : NFTImg}
              alt='img'
            />
          </Col>
        </Row>
        <div className='d-flex align-items-center justify-content-between'>
          <div className='d-flex align-items-center gap-3'>
            <div className='position-relative'>
              {/* <img
                className={Classes.userImg}
                src={userId?.profilePicture ? userId?.profilePicture : UserImg}
                alt='img'
              /> */}

              <img className={Classes.varified} src={Varified} alt='img' />
            </div>
            <div>
              <h3 className={Classes.title}>{`${firstName} ${lastName}`}</h3>
            </div>
          </div>
          {/* <div className={Classes.rating}>
            <img src={GreyStar} alt='img' />
            <span>82</span>
          </div> */}
        </div>
      </div>
    </Col>
    // </div>
  );
};

export default FeaturedUserCard;
