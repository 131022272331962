import { Modal } from 'antd';
import Classes from '../ApprovedPurchase/modal.module.scss';
import SuccessModal from '../SuccessModal';
import React, { Fragment, useState } from 'react';
import { Formik } from 'formik';
import { ThreeDots } from 'react-loader-spinner';
import { alertMessage } from '../../constants/alertMessage';
import { reportNFT } from '../../services/auth.service';

import { Radio, Space } from 'antd';
import { useSelector } from 'react-redux';

const radioOptions = [
  {
    id: 1,
    title: 'Spam',
    value: 'Spam',
  },
  {
    id: 2,
    title: 'Fraud',
    value: 'Fraud',
  },
  {
    id: 3,
    title: 'Child sexual exploitation or abuse',
    value: 'Child sexual exploitation or abuse',
  },
  {
    id: 4,
    title: 'Terrorism or violent extremism',
    value: 'Terrorism or violent extremism',
  },
  {
    id: 5,
    title: 'Hate speach',
    value: 'Hate speach',
  },
  {
    id: 6,
    title: 'Imminent harm to person or property',
    value: 'Imminent harm to person or property',
  },
  {
    id: 7,
    title: 'Non-consensual intimate imagery',
    value: 'Non-consensual intimate imagery',
  },
  {
    id: 8,
    title: 'Self-harm or suicide',
    value: 'Self-harm or suicide',
  },
  {
    id: 9,
    title: 'Harrasment or bullying',
    value: 'Harrasment or bullying',
  },
  {
    id: 10,
    title: 'Defamation, impersonations, false information',
    value: 'Defamation, impersonations, false information',
  },
  {
    id: 11,
    title: 'Sexually inappropriate',
    value: 'Sexually inappropriate',
  },
  {
    id: 12,
    title: 'Content infringement',
    value: 'Content infringement',
  },
  {
    id: 13,
    title: 'Malware or viruses',
    value: 'Malware or viruses',
  },
  {
    id: 14,
    title: 'Others',
    value: 'Others',
  },
];

const ReportModal = ({
  isModalOpen,
  handleCancel,
  message,
  modalType,
  nftDetail,
}) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState();
  const [error, setError] = useState('');
  const [reportReason, setReportReason] = useState('');
  const [selectedOption, setSelectedOption] = useState({
    id: '',
    title: '',
    value: '',
  });
  const auth = useSelector((state) => state?.auth);

  const authToken = localStorage.getItem('authenticationToken');

  const onChange = (e) => {
    const selectedRadioOption = radioOptions.find(
      (option) => option.id === e.target.value
    );

    setValue(e.target.value);
    setSelectedOption(selectedRadioOption);
  };

  const handleReportNonProfit = async (data, resetForm) => {
    try {
      console.log(data,'data=>')
      setLoading(true);
      const response = await reportNFT(authToken, data);
      if (response?.status === 200) {
        resetForm({ values: '' });
        handleCancel();
        alertMessage('success', `${response?.data?.message}`);
        setLoading(false);
      } else {
        alertMessage('error', `${response?.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      alertMessage('error', `${error}`);
      setLoading(false);
    }
  };

  const [open, setOpen] = useState(false);
  const handleChange = (value) => {
    setSelectedOption(value);
  };
  const show = () => {
    setOpen(true);
    handleCancel();
  };
  const cancel = () => {
    setOpen(false);
  };

  const handleReasonState = (e) => {
    setReportReason(e?.target?.value);
  };

  return (
    <>
      <Modal
        className={Classes.actionModal}
        title='Report this item'
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <Formik
          initialValues={{
            reason: '',
          }}
          onSubmit={(values, { resetForm }) => {
            const data = {
              email: auth.email,
              reportedBy:auth?.id,
              reportedTo:nftDetail?.userId,
              reportType: 'nft',
              reason:
                selectedOption?.id === 14
                  ? reportReason
                  : selectedOption?.value, // selectedOption?.value,
              nftId: nftDetail && nftDetail?._id,
            };

            console.log('data :', data);
            if (data?.reason) {
              setError('');
              handleReportNonProfit(data, resetForm);
            } else {
              setError('Report reason is required.');
            }
          }}
        >
          {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            touched,
            values,
          }) => (
            <form
              onSubmit={handleSubmit}
              noValidate
              autoComplete='off'
              className={`${Classes.modalContent} mt-8 space-y-6`}
            >
              <div className={`${Classes.addContent} py-2`}>
                <div className={`py-2`}>
                  <p className={Classes.labelTxt}>
                   <b> Nonprofit organization Name</b>
                  </p>
                  <input
                    className={Classes.metaInfo}
                    disabled
                    type='text'
                    name='nftName'
                    value={nftDetail?.name}
                  />
                </div>
                <div className='mb-3'>
                  <p className={Classes.labelTxt}><b>Report Reason</b></p>

                  <Radio.Group onChange={onChange} value={value}>
                    <Space direction='vertical'>
                      {radioOptions.map((radioOption) => (
                        <Radio key={radioOption.id} value={radioOption.id}>
                          {radioOption.title}
                        </Radio>
                      ))}
                    </Space>
                  </Radio.Group>

                  {value === 14 ? (
                    <div className='mb-3 mt-4'>
                      <textarea
                        name='reason'
                        placeholder='Enter report reason'
                        onChange={(e) => handleReasonState(e)}
                        className={Classes.metaInfo}
                        rows={3}
                      ></textarea>
                    </div>
                  ) : null}

                  {error ? (
                    <span
                      className='mt-1 d-block'
                      size={'small'}
                      style={{ color: 'red' }}
                    >
                      <Fragment>{error}</Fragment>
                    </span>
                  ) : (
                    ''
                  )}
                </div>

                <div className='d-flex justify-content-center align-items-center gap-3 mt-4'>
                  <button className={`${Classes.bluebtn} w-100`} type='submit'>
                    {loading ? (
                      <div className='d-flex justify-content-center align-items-center'>
                        <ThreeDots
                          height='30'
                          width='30'
                          radius='9'
                          color='white'
                          ariaLabel='three-dots-loading'
                          wrapperStyle={{}}
                          wrapperClassName=''
                          visible={true}
                        />
                      </div>
                    ) : (
                      'Report NFT'
                    )}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </Modal>
      <SuccessModal showModal={show} isModalOpen={open} handleCancel={cancel} />
    </>
  );
};

export default ReportModal;
