import React from "react";
import Classes from "./listingtable.module.scss";
import { MdShoppingCart } from "react-icons/md";

import { BiTransferAlt } from "react-icons/bi";
import TimeAgo from "react-timeago";
import { BsTagFill } from "react-icons/bs";
function OffersTable({ activity }) {

  const handleAcitivityIcon = (type) => {
    if (type === "Mint") {
      return <MdShoppingCart />;
    } else if (type === "List") {
      return <BsTagFill />;
    } else {
      return <BiTransferAlt />;
    }
  };
  return (
    <div className={Classes.listingTable}>
      <div className={Classes.header}>Item Activity</div>
      <div className={Classes.tableOuter}>
        <table>
          <tr className="">
            <th>Event</th>
            <th>Price (POL)</th>
            <th>From</th>
            <th>To</th>
            <th>Date & Time</th>
          </tr>
            {activity &&
              activity?.length > 0 &&
              activity.map((item, key) => (
                <tr key={item?._id}>
                  <td>
                    <div className="d-flex gap-1 align-items-center">
                      {handleAcitivityIcon(item.activityType)}
                      <span className={Classes.boldtxt}>
                        {item.activityType}
                      </span>
                    </div>
                  </td>
                  <td>
                    <span className={Classes.txt}>{item?.price || ""}</span>
                  </td>
                  <td>
                    <span className={Classes.txt}>
                      {item?.fromAddress?.slice(0, 6)?.toUpperCase() || "Null Address"}
                    </span>
                  </td>
                  <td>
                    <span className={`${Classes.boldtxt} ${Classes.blueTxt}`}>
                      {item?.toAddress?.slice(0,6)?.toUpperCase()||""}
                    </span>
                  </td>
                  <td>
                    <span className={Classes.boldtxt}>
                      <TimeAgo date={item.createdAt}/>
                      {/* <button className={Classes.blueBtn}>Buy</button> */}
                    </span>
                  </td>
                </tr>
              ))}
        </table>
      </div>
    </div>
  );
}

export default OffersTable;
