import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Classes from "./tabs.module.scss";
import SearchIcon from "../../assets/images/icons/light-search.svg";
import { Col, DatePicker, Row, Select } from "antd";
import { nftListing } from "../../services/nft.service";
import { getUserFiscalProjects } from "../../services/fiscalProjects.service";
import { Link } from "react-router-dom";
import FeaturedCard from "../FeaturedCard";
import LeaderBoardData from "../LeaderBoardData";
import { useDispatch, useSelector } from "react-redux";
import { friendRequestsList } from "../../services/friends.service";
import projIImg from "../../assets/images/section/projImg.svg";
import checkcircle from "../../assets/images/icons/varified.svg";
import { collectionsListing } from "../../services/collections.service";
import Favourites from "../Favourites";
import { BsPencil, BsCheck2Circle, BsXCircle,BsListTask,BsDatabaseAdd } from "react-icons/bs";
import { OrganizationTabs, UsersTabs } from "./TabHeaders";
import DeactivateFiscalProjectModal from "./DeactivateFiscalProjectModal";
import FiscalProjectFeeModal from "./FiscalProjectFeeModal";
import IsoDeterminationLetterModal from "./IsoDeterminationLetterModal";
import EditFiscalProjectModal from "./EditFiscalProjectModal";
import NFTCard from "../NFTCard";
import IsoLetters from "./IsoLetters";
import {
  getSponsorsList,
  updateApproval,
  getIndirectSupportersList,
  getUserCharityList,
} from "../../services/auth.service";
import { alertMessage } from "../../constants/alertMessage";
import { userIsoLettersList } from "../../services/isoDocuments.service";
import { thunkLogin } from "../../store/_actions/user_action";
import {
  AiOutlineBarChart,
  AiOutlineEye,
  AiOutlinePieChart,
} from "react-icons/ai";
import {
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  AreaChart,
} from "recharts";
import StatisticsModal from "./StatisticsModal";
import { getFiscalProjectStats } from "../../services/fiscalProjects.service";
import moment from "moment";
import CommonModal from "../views/Pages/CommonModal";
import { useLocation } from "react-router-dom";
import { FiAlertCircle } from "react-icons/fi";
import { Pagination } from 'antd'; // Import Ant Design Pagination


const onChange = (date, dateString) => {};
const { RangePicker } = DatePicker;
const UserTabs = () => {
  const location = useLocation();
  const [show, setShow] = useState("nftdrop");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const [isStatOpen, setIsStatOpen] = useState(false);
  const [isSponsorshipModalOpen, setIsSponsorshipModalOpen] = useState(false);
  const [isoDeterminationLetterModalOpen, setIsoDeterminationLetterModalOpen] =
    useState(false);
  const [showSale, setShowSale] = useState("fiscalproj");
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [friendsTabClicked, setFriendsTabClicked] = useState(false);
  const [friendRequests, setFriendRequests] = useState([]);
  const [filteredFriendRequests, setFilteredFriendRequests] = useState([]);
  const [filterFiscalProjects, setFilterFiscalProjects] = useState("");
  const [isoDocuments, setIsoDocuments] = useState([]);
  const [filterInterests, setFilterInterests] = useState("");
  const [filterSupporters, setFilterSupporters] = useState("");
  const [filterIndirectSupporters, setFilterIndirectSupporters] = useState("");
  const [filterUserCharities, setFilterUserCharities] = useState("");
  const [filterDropNfts, setFilterDropNfts] = useState("");
  const [filterCollection, setFilterCollection] = useState("");
  const [nftsList, setNftsList] = useState();
  const [fiscalProjectsList, setFiscalProjectsList] = useState();
  const [fiscalProjectCount, setFiscalProjectCount] = useState();
  const [fiscalProjectCountWithStatus, setFiscalProjectCountWithStatus] = useState();
  const [sponsorsList, setSponsorsList] = useState();
  const [indirectSupporterList, setIndirectSupporterList] = useState();
  const [userCharities, setUserCharities] = useState();
  const [fiscalProjectId, setFiscalProjectId] = useState("");
  const [fiscalProject, setFiscalProject] = useState("");
  const [selectedOption, setSelectedOption] = useState("interest");
  const [selectedOption2, setSelectedOption2] = useState("collection");
  const [collections, setCollections] = useState();
  const auth = useSelector((state) => state.auth);
  const token = localStorage.getItem("authenticationToken");
  const [dateFilter, setDateFilter] = useState("all");
  const [buttonStates, setButtonStates] = useState({});
  const [page, setPage] = useState(1);
  const [limit,setLimit] = useState(10);
  const [activeFiscalTab, setActiveFiscalTab] = useState('all');

  const [orignalData, setOrignalData] = useState(null);
  const [data, setData] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [fiscalsDateRange, setFiscalsDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const { connectedAddress } = useSelector((state) => state.wallet);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const showModal = () => {
    setIsModalOpen(true);
  };
  const showSponsorshipModal = () => {
    setIsSponsorshipModalOpen(true);
  };
  const showIsoDeterminationModal = () => {
    setIsoDeterminationLetterModalOpen(true);
  };
  const showEditModal = () => {
    setEditModalOpen(true);
  };
  const showStatModal = () => {
    setIsStatOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    setEditModalOpen(false);
    setIsSponsorshipModalOpen(false);
    setIsoDeterminationLetterModalOpen(false);
    setIsStatOpen(false);
  };

  const showWalletModal = () => {
    setIsWalletModalOpen(true);
  };
  const handleWalletModalCancel = () => {
    setIsWalletModalOpen(false);
  };
  const getCollections = async () => {
    try {
      const response = await collectionsListing(token);
      if (response?.data?.data) {
        setCollections(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getNftListing = async () => {
    try {
      const nfts = await nftListing(auth?.walletAddress, auth?.id);
      if (nfts && nfts?.data?.data?.length) {
        setNftsList(nfts?.data?.data);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const [allFiscalProjectsList, setAllFiscalProjectsList] = useState([]);
  const getOrganizationFiscalProjects = async (status = "all", searchTerm = "") => {
    try {
      const startDate = fiscalsDateRange?.startDate;
      const endDate = fiscalsDateRange?.endDate;
      const verificationStatus = status;
      const fiscalProjects = await getUserFiscalProjects(
        token,
        auth?.id,
        startDate,
        endDate,
        verificationStatus,
        page,
        limit,
        searchTerm
      );
      if (fiscalProjects && fiscalProjects?.data?.data) {
        setFiscalProjectsList(fiscalProjects?.data?.data?.fiscalProjects);
        setFiscalProjectCount(fiscalProjects?.data?.data?.totalCount);
        setFiscalProjectCountWithStatus(fiscalProjects?.data?.data?.projectCounts);
      }
      if (!fiscalProjects?.data.success) {
        setFiscalProjectsList(null);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const getSponsors = async () => {
    const response = await getSponsorsList(token, auth?.id);
    if (response?.data?.success) {
      setSponsorsList(response?.data?.data?.sponsorsList);
    }
  };
  const getIndirectSupporters = async () => {
    const response = await getIndirectSupportersList(token, auth?.id);
    if (response?.data?.success) {
      setIndirectSupporterList(response?.data?.data?.indirectSupporterList);
    }
  };
  const getUserCharities = async () => {
    const response = await getUserCharityList(token, auth?.id);
    if (response?.data?.success) {
      setUserCharities(response?.data?.data?.charitiesList);
    }
  };
  const filteredData =
    auth?.categories?.length > 0 &&
    auth?.categories?.filter((item) =>
      item?.categoryName?.toLowerCase().includes(filterInterests?.toLowerCase())
    );

  const filteredSponsorsListData =
    sponsorsList?.length > 0 &&
    sponsorsList?.filter(
      (item) =>
        item?.user?.username
          ?.toLowerCase()
          .includes(filterSupporters?.toLowerCase()) ||
        item?.user?.firstName
          ?.toLowerCase()
          .includes(filterSupporters?.toLowerCase()) ||
        item?.user?.lastName
          ?.toLowerCase()
          .includes(filterSupporters?.toLowerCase())
    );
  const filteredIndirectSupportersData =
    indirectSupporterList?.length > 0 &&
    indirectSupporterList?.filter(
      (item) =>
        item?.user?.username
          ?.toLowerCase()
          .includes(filterIndirectSupporters?.toLowerCase()) ||
        item?.user?.firstName
          ?.toLowerCase()
          .includes(filterIndirectSupporters?.toLowerCase()) ||
        item?.user?.lastName
          ?.toLowerCase()
          .includes(filterIndirectSupporters?.toLowerCase())
    );

  const filteredUserCharitiesData =
    userCharities?.length > 0 &&
    userCharities?.filter(
      (item) =>
        item?.user?.username
          ?.toLowerCase()
          .includes(filterUserCharities?.toLowerCase()) ||
        item?.user?.firstName
          ?.toLowerCase()
          .includes(filterUserCharities?.toLowerCase()) ||
        item?.user?.lastName
          ?.toLowerCase()
          .includes(filterUserCharities?.toLowerCase())
    );

  const filteredCollectionData =
    collections?.length > 0 &&
    collections?.filter((item) =>
      item?.collectionName
        ?.toLowerCase()
        .includes(filterCollection?.toLowerCase())
    );

  const filteredDropNFtData =
    nftsList?.length > 0 &&
    nftsList?.filter((item) =>
      item?.name?.toLowerCase().includes(filterDropNfts?.toLowerCase())
    );
  const getFriendsListing = async () => {
    try {
      const requests = await friendRequestsList(token);
      let friends = [];
      if (requests?.data?.data) {
        const receivedFriendRequests =
          requests.data.data.receivedFriendRequests || [];
        const sentFriendRequests = requests.data.data.sentFriendRequests || [];

        friends = [
          ...receivedFriendRequests.filter(
            (request) => request?.requestStatus === "approved"
          ),
          ...sentFriendRequests.filter(
            (request) => request?.requestStatus === "approved"
          ),
        ];
        setFriendRequests(friends);
        setFilteredFriendRequests(friends);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const searchFriendRequests = async (searchValue) => {
    try {
      const matchingObjects = friendRequests.filter((item) => {
        const { firstName, lastName, email } = item;
        return (
          firstName.toLowerCase().includes(searchValue?.toLowerCase()) ||
          lastName.toLowerCase().includes(searchValue?.toLowerCase()) ||
          email.toLowerCase().includes(searchValue?.toLowerCase())
        );
      });
      setFilteredFriendRequests(matchingObjects);

      if (matchingObjects?.length === 0) {
        getFriendsListing();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleChange = (value) => {
    setDateFilter(value);
  };

  useEffect(() => {
    if (friendsTabClicked) {
      setFriendsTabClicked(false);
    }
  }, [friendsTabClicked]);

  useEffect(() => {
    if (location?.state?.tab) {
      setShow(location.state.tab);
    }
  }, [location.state?.tab]);

  const handleFriendsTabClick = () => {
    setShow("friends");
    setFriendsTabClicked(true);
    getFriendsListing();
  };

  const handleFilterChange = (e) => {
    setFilterInterests(e.target.value);
  };

  const handleSupportersFilterChange = (e) => {
    setFilterSupporters(e.target.value);
  };
  const handleIndirectSupportersFilterChange = (e) => {
    setFilterIndirectSupporters(e.target.value);
  };
  const handleUserCharitiesFilterChange = (e) => {
    setFilterUserCharities(e.target.value);
  };
  const handleDropNFTFilterChange = (e) => {
    setFilterDropNfts(e.target.value);
  };

  const handleUserIsoLetters = async () => {
    const response = await userIsoLettersList(token);
    if (response?.data?.data) {
      setIsoDocuments(response?.data?.data);
    }
  };
  const handleUpdateApproval = async (id, status) => {
    const response = await updateApproval(id, { status });
    if (response.data.success) {
      setButtonStates((prevState) => ({
        ...prevState,
        [id]: status,
      }));
      dispatch(
        thunkLogin(response?.data?.data, () => {
          alertMessage("success", `${response?.data?.message}`);
        })
      );
      setShowSale("fiscalproj")
      getOrganizationFiscalProjects(activeFiscalTab);
      return;
    }
    alertMessage("error", response.data.message);
  };
  const options = [
    { label: "All", value: "all" },
    { label: "Today", value: "today" },
    { label: "Last 24 hours", value: "last24Hours" },
    { label: "Last Week", value: "lastWeek" },
    { label: "Last Month", value: "lastMonth" },
    { label: "Last Year", value: "lastYear" },
  ];

  useEffect(() => {
    getNftListing();
    getCollections();
    getSponsors();
    getIndirectSupporters();
    getUserCharities();
    handleUserIsoLetters();
    getOrganizationFiscalProjects();
  }, []);
  const getStats = async () => {
    const stats = await getFiscalProjectStats({
      dateFilter,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
    setOrignalData(stats.data.data);
    formatData(stats.data.data);
  };
  const formatData = (data) => {
    let count = 0;
    const res = data.map((item) => {
      const createdAt = moment(item.createdAt);
      count = count + item?.price;
      return {
        name: createdAt.format("D MMMM HH:mm"),
        Donation: count,
        pv: count,
      };
    });
    setData(res);
  };
  useEffect(() => {
    getStats();
  }, [dateFilter, dateRange]);
  useEffect(() => {
    getOrganizationFiscalProjects();
  }, [fiscalsDateRange]);

  function disabledDate(current) {
    // Can not select days after today
    return current && current > moment().endOf("day");
  }
  const handleRangePicker = (dates) => {
    setDateFilter("all");
    setDateRange({
      startDate: dates ? dates[0]?.toISOString() : null,
      endDate: dates ? dates[1]?.toISOString() : null,
    });
  };
  const handleFiscalsRangePicker = (dates) => {
    setFiscalsDateRange({
      startDate: dates ? dates[0]?.toISOString() : null,
      endDate: dates ? dates[1]?.toISOString() : null,
    });
  };

  const filteredFiscalProjectsData =
  fiscalProjectsList?.length > 0 &&
  fiscalProjectsList.filter((project) =>
    project?.nonProfitName?.toLowerCase().includes(filterFiscalProjects.toLowerCase()) ||
  project?.firstName?.toLowerCase().includes(filterFiscalProjects.toLowerCase()) ||
  project?.lastName?.toLowerCase().includes(filterFiscalProjects.toLowerCase()) ||
  project?.email?.toLowerCase().includes(filterFiscalProjects.toLowerCase())||
  project?.username?.toLowerCase().includes(filterFiscalProjects.toLowerCase())
);

    useEffect(() => {
     getOrganizationFiscalProjects(activeFiscalTab)
    }, [page,limit]); // Fetch when currentPage changes
  
    // Handle page change
    const handlePageClick = (page,pageSize) => {
      setPage(page); 
      setLimit(pageSize);
    };
   

  const handleFiscalSearchChange = (e) => {
    const searchValue = e.target.value;
    setFilterFiscalProjects(searchValue);
    getOrganizationFiscalProjects(activeFiscalTab, searchValue);
  };

  return (
    <div className={`${Classes.userTabs} pt-5`}>
      {auth?.role === "organization" ? (
        <OrganizationTabs
          show={show}
          setShow={setShow}
          hideFiscal={
            auth.organizationType ===
              "Nonprofit with 501(c)(3) fiscal sponsor" ||
            auth.organizationType ===
              "Nonprofit seeking a fiscal sponsor or 501(c)(3) status"
          }
        />
      ) : (
        <UsersTabs
          show={show}
          setShow={setShow}
          handleFriendsTabClick={handleFriendsTabClick}
        />
      )}
      <hr className="mb-3" />
      {show === "collections" && (
        <>
          <div className="d-flex align-items-center justify-content-between gap-4 pb-5 pt-3 flex-sm-row flex-column">
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input
                className="w-100"
                type="text"
                placeholder="Search"
                onChange={(e) => setFilterCollection(e.target.value)}
              />
            </div>
            <div className={`${Classes.rightSection} d-flex gap-4`}>
              <button
                className={`${Classes.outlineBtn}`}
                onClick={() => {
                  if (connectedAddress) {
                    navigate("/create-nft");
                  } else {
                    showWalletModal();
                    setMessage(
                      "Please go to your profile and click on “Connect wallet” in order to enable NFT creation."
                    );
                    setModalType("Create NFT");
                  }
                }}
              >
                Create NFT
              </button>
            </div>
          </div>
          {selectedOption2 === "collection" && (
            <div className={Classes.outer}>
              <h1 className={Classes.title}>My Collection</h1>
              <Row gutter={[20, 20]}>
                {filteredCollectionData &&
                  filteredCollectionData?.length > 0 &&
                  filteredCollectionData.map((item) => {
                    return <FeaturedCard data={item} />;
                  })}
              </Row>
            </div>
          )}
        </>
      )}
      {show === "usercauses" && (
        <>
          <div className="d-flex align-items-center justify-content-between gap-4 pb-5 pt-3 flex-sm-row flex-column">
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input
                className="w-100"
                type="text"
                placeholder="Search"
                onChange={handleFilterChange}
              />
            </div>
            <div className={Classes.interestBtn}>
              <Link to="/edit-cause" className={Classes.outlineBtn}>
                Edit Causes
              </Link>
            </div>
          </div>
          {selectedOption === "interest" && (
            <div className={Classes.outer}>
              <h1 className={Classes.title}>Supporting Causes</h1>
              <Row gutter={[20, 20]}>
                {filteredData &&
                  filteredData?.map((category, index) => (
                    <Col lg={6} md={8} sm={12} xs={24}>
                      <div className={Classes.causeOuter} key={index}>
                        <img
                          className={Classes.img}
                          src={category?.categoryImage}
                          alt="img"
                        />
                        <h2 className={Classes.txt}>
                          {category?.categoryName}
                        </h2>
                      </div>
                    </Col>
                  ))}
              </Row>
            </div>
          )}
        </>
      )}
      {show === "favourites" && (
        <>
          <div className="d-flex align-items-center justify-content-between gap-4 pb-5 pt-3 flex-sm-row flex-column">
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input
                className="w-100"
                type="text"
                placeholder="Search"
                onChange={handleFilterChange}
              />
            </div>
          </div>
          <div className={Classes.outer}>
            <h1 className={Classes.title}>Favorites</h1>
            <Favourites />
          </div>
        </>
      )}
      {show === "charities" && (
        <>
          <div className="d-flex align-items-center justify-content-between gap-4 pb-5 pt-3 flex-lg-row flex-column">
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input
                className="w-100"
                type="text"
                placeholder="Search"
                onChange={handleUserCharitiesFilterChange}
              />
            </div>
          </div>
          <Row gutter={[20, 20]}>
            {filteredUserCharitiesData &&
            filteredUserCharitiesData.length > 0 ? (
              filteredUserCharitiesData?.map((friend, index) => (
                <Col lg={8} md={24} sm={24} xs={24}>
                  <LeaderBoardData friend={friend} />
                </Col>
              ))
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100">
                <p>You don't have made any charities yet</p>
              </div>
            )}
          </Row>
        </>
      )}
      {show === "causes" && (
        <>
          <div
            className={`${Classes.itemsOuter} d-flex align-items-center justify-content-between gap-4 pb-5 pt-3`}
          >
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input
                className="w-100"
                type="text"
                placeholder="Search"
                onChange={handleFilterChange}
              />
            </div>
            <div className={Classes.interestBtn}>
              <Link to="/edit-cause" className={Classes.outlineBtn}>
                Edit Causes
              </Link>
            </div>
          </div>
          <Row gutter={[20, 20]}>
            {filteredData &&
              filteredData?.map((category, index) => (
                <Col lg={6} md={8} sm={12} xs={24}>
                  <div className={Classes.causeOuter} key={index}>
                    <img
                      className={Classes.img}
                      src={category?.categoryImage}
                      alt="img"
                    />
                    <h2 className={Classes.txt}>{category?.categoryName}</h2>
                  </div>
                </Col>
              ))}
          </Row>
        </>
      )}

      {/* NFT DROPs */}
      {show === "nftdrop" && (
        <>
          <div
            className={`${Classes.itemsOuter} d-flex align-items-center justify-content-between gap-4 pb-5 pt-3`}
          >
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input
                className="w-100"
                type="text"
                placeholder="Search"
                onChange={handleDropNFTFilterChange}
              />
            </div>
            <div className={`${Classes.rightSection} d-flex gap-4`}>
              <Select
                placeholder="Price"
                style={{
                  width: 140,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "low",
                    label: "Low to High",
                  },
                  {
                    value: "high",
                    label: "High to Low",
                  },
                ]}
              />
              <Select
                placeholder="Recently Added"
                style={{
                  width: 180,
                }}
                onChange={handleChange}
                options={[
                  {
                    value: "one",
                    label: "One Month Ago",
                  },
                  {
                    value: "two",
                    label: "Two Month Ago",
                  },
                ]}
              />
              <button
                onClick={() => {
                  if (connectedAddress) {
                    navigate("/create-nft");
                  } else {
                    showWalletModal();
                    setMessage(
                      "Please go to your profile and click on “Connect wallet” in order to enable NFT creation."
                    );
                    setModalType("Create NFT");
                  }
                }}
                className={Classes.outlineBtn}
              >
                Drop NFT
              </button>
            </div>
          </div>
          <Row gutter={[20, 20]}>
            {filteredDropNFtData &&
              filteredDropNFtData?.map((nft, index) => (
                <Col lg={6} md={12} sm={24} xs={24} index={index}>
                  <NFTCard nftList={nft} />
                </Col>
              ))}
          </Row>
          <div className={Classes.outer}>{/* <FeaturedCard /> */}</div>
        </>
      )}
      {show === "verification" && (
        <>
          <Row className="mt-4" gutter={[20, 20]}>
            <Col lg={24} md={24} sm={24} xs={24}>
              <div className="align-items-center justify-content-between gap-4   flex-sm-row flex-column">
                <div className={`d-flex gap-2`}></div>
                <div className={Classes.interestBtn}>
                  <button
                    className={Classes.outlineBtn}
                    onClick={() => showIsoDeterminationModal()}
                  >
                    {auth?.organizationType?.includes("IRS")
                      ? "Verify IRS Determination Letter"
                      : "Verify Fiscal Sponsorship Letter"}
                  </button>
                </div>
              </div>
            </Col>
            {/* <Col lg={4} md={24} sm={24} xs={24}></Col> */}
            <Col lg={24} md={24} sm={24} xs={24}>
              {/* <img src={auth?.isoDocument} alt={auth?.firstName} /> */}
              <IsoLetters isoDocuments={isoDocuments} />
            </Col>
          </Row>
          <IsoDeterminationLetterModal
            isoDeterminationLetterModalOpen={isoDeterminationLetterModalOpen}
            setIsoDocuments={setIsoDocuments}
            handleCancel={handleCancel}
          />
        </>
      )}
      {show === "fiscal" && (
        <>
          <div
            className={`${Classes.itemsOuter} d-flex align-items-center justify-content-between gap-4 pb-5 pt-3`}
          >
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input
                className="w-100"
                type="text"
                placeholder="Search"
                onChange={handleFiscalSearchChange}
              />
            </div>
          </div>
          {auth?.role === "organization" &&
            auth?.organizationType !==
              "Nonprofit with 501(c)(3) fiscal sponsor" && (
              <div className={Classes.metaInfoInRed}>
                <FiAlertCircle />
                <span>
                Before you approve a new fiscal project, please make sure that you have connected your wallet and have set the default fiscal sponsorship fee.
                </span>
              </div>
            )}
          <div
            className={`${Classes.itemsOuter} d-flex align-items-center justify-content-between  pb-4 `}
          >
            <div
              className={`${Classes.rightSection} d-flex align-items-center justify-content-between w-100`}
            >
              <div className="d-flex gap-2">
                <button
                  onClick={() => {
                    setShowSale("fiscalproj");
                    setPage(1);
                    getOrganizationFiscalProjects("all");
                    setActiveFiscalTab("all");
                  }}
                  className={`${Classes.blueBtn} ${Classes.topsale} ${activeFiscalTab === "all" ? Classes.activeButton : ""}`}
                >
                  <BsListTask />
                  All ({fiscalProjectCountWithStatus?.all})
                </button>
                <button
                  onClick={() => {
                    setShowSale("fiscalproj");
                    setPage(1);
                    getOrganizationFiscalProjects("pending");
                    setActiveFiscalTab("pending");
                  }}
                  className={`${Classes.pendingBtn} ${Classes.topsale} ${activeFiscalTab === "pending" ? Classes.activeButton : ""}`}
                >
                  <BsDatabaseAdd />
                  New ({fiscalProjectCountWithStatus?.pending})
                </button>
                <button
                  onClick={() => {
                    setShowSale("fiscalproj");
                    setPage(1);
                    getOrganizationFiscalProjects("approved");
                    setActiveFiscalTab("approved");
                  }}
                  className={`${Classes.approvedBtn} ${Classes.topsale} ${activeFiscalTab === "approved" ? Classes.activeButton : ""}`}
                >
                  <BsCheck2Circle />
                  Approved ({fiscalProjectCountWithStatus?.approved})
                </button>
                <button
                  onClick={() => {
                    setShowSale("fiscalproj");
                    setPage(1);
                    getOrganizationFiscalProjects("disapproved");
                    setActiveFiscalTab("disapproved");
                  }}
                  className={`${Classes.disapprovedBtn} ${Classes.topsale} ${activeFiscalTab === "disapproved" ? Classes.activeButton : ""}`}
                >
                  <BsXCircle />
                  Rejected ({fiscalProjectCountWithStatus?.disapproved})
                </button>
                {/* <button
                  onClick={() => setShowSale("grandSale")}
                  className={`${Classes.blueBtn}  ${Classes.topsale}`}
                >
                  <AiOutlineBarChart />
                  Total Revenue
                </button> */}
              </div>
              <button
                className={`${Classes.outlineBtn} ${Classes.topbtns}`}
                onClick={() => showSponsorshipModal()}
              >
                Set Default Sponsorship Fee for New Project
              </button>
            </div>
          </div>
          <div className="">
            {showSale === "fiscalproj" && (
              <>
                <div className="mb-3">
                  <p className={`${Classes.datelabel} mb-1`}>Select Range</p>
                  <RangePicker
                    disabledDate={disabledDate}
                    onChange={handleFiscalsRangePicker}
                  />
                </div>
                <Row gutter={[10, 10]}>
                  {(filteredFiscalProjectsData &&
                    filteredFiscalProjectsData.map((userFiscalProject, index) => {
                      const userId = userFiscalProject?._id;
                      const approvalStatus =
                        buttonStates[userId] ||
                        userFiscalProject?.verificationStatus;

                      return (
                        <Col lg={24} md={24} sm={24} xm={24} key={index}>
                          <div className={Classes.mainDiv}>
                            <div
                              className={`${Classes.projOuter}  d-flex justify-content-between align-items-start gap-3`}
                            >
                              <div className="d-flex gap-3">
                                <div className="position-relative">
                                  <img
                                    className={Classes.img}
                                    src={
                                      userFiscalProject?.profilePicture ||
                                      projIImg
                                    }
                                    alt="img"
                                  />
                                  <img
                                    className={`${Classes.check} position-absolute`}
                                    src={checkcircle}
                                    alt="icon"
                                  />
                                </div>
                                <div>
                                  <div className="d-flex gap-3">
                                    <p className={Classes.name}>
                                      {`${userFiscalProject?.nonProfitName || ""} (${userFiscalProject?.firstName || ""} ${userFiscalProject?.lastName || ""})`}
                                      <a
                                        href={`${process.env.REACT_APP_BASE_URL}/user/${userFiscalProject?.username}`}
                                        target="_blank"
                                      >
                                        {` ${process.env.REACT_APP_BASE_URL}/user/${userFiscalProject?.username}`}
                                      </a>
                                    </p>
                                  </div>
                                  <div>
                                    <span className={`${Classes.label} me-2`}>
                                      Email:
                                    </span>
                                    <span className={Classes.value}>
                                      {userFiscalProject?.email}
                                    </span>
                                  </div>
                                  <div>
                                    <span className={`${Classes.label} me-2`}>
                                      Fiscal fee %:
                                    </span>
                                    <span className={Classes.value}>
                                      {userFiscalProject?.sponsorshipFee ||
                                        auth?.defaultSponsorshipFee ||
                                        10}
                                    </span>
                                  </div>
                                  <div>
                                    <span className={`${Classes.label} me-2`}>
                                      Revenue:
                                    </span>
                                    <span className={Classes.value}>
                                    {(userFiscalProject?.totalRevenue.toFixed(8) || 0)}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center gap-2">
                                <button
                                  onClick={() => {
                                    showStatModal();
                                    setFiscalProjectId(userFiscalProject?._id);
                                  }}
                                  className={`${Classes.actionIcons} ${Classes.view}`}
                                >
                                  <AiOutlineEye color="#fff" />
                                  View
                                </button>

                                <button
                                  className={`${Classes.actionIcons} ${Classes.edit} ${userFiscalProject?.nftCount === 0 ? Classes.disabled : ""}`}
                                  onClick={() => {
                                    if (userFiscalProject?.nftCount !== 0) {
                                      showEditModal();
                                      setFiscalProject(userFiscalProject);
                                    }
                                  }}
                                >
                                  <BsPencil color="#fff" />
                                  Edit
                                </button>
                                <button
                                  className={`${Classes.actionIcons} ${Classes.approve} ${approvalStatus === "approved" ? Classes.disabled : ""}`}
                                  onClick={() => {
                                    if (approvalStatus !== "approved")
                                      handleUpdateApproval(
                                        userFiscalProject?._id,
                                        "approved"
                                      );
                                  }}
                                >
                                  <BsCheck2Circle color="#fff" />
                                  Approve
                                </button>
                                <button
                                  className={`${Classes.actionIcons} ${Classes.reject} ${approvalStatus === "disapproved" ? Classes.disabled : ""}`}
                                  onClick={() => {
                                    if (approvalStatus !== "disapproved")
                                      handleUpdateApproval(
                                        userFiscalProject?._id,
                                        "disapproved"
                                      );
                                  }}
                                >
                                  <BsXCircle color="#fff" />
                                  Reject
                                </button>
                              </div>
                            </div>
                          </div>
                        </Col>
                      );
                    })) || (
                    <p className="text-center w-100">No data available</p>
                  )}
                </Row>
              </>
            )}
            {showSale === "grandSale" && (
              <div className={Classes.grandTotal}>
                <div className="d-flex align-items-center justify-content-between mb-3">
                  <h4>Grand Total Revenue for all Fiscal Projects</h4>
                  <div className="d-flex align-items-center gap-2">
                    <Select
                      placeholder="All"
                      style={{
                        width: 160,
                      }}
                      onChange={handleChange}
                      options={options}
                      disabled={dateRange?.startDate}
                      value={dateFilter}
                    />

                    <RangePicker
                      disabledDate={disabledDate}
                      onChange={handleRangePicker}
                    />
                  </div>
                </div>

                <ResponsiveContainer width="100%" height={350}>
                  <AreaChart
                    width="100%"
                    height="100%"
                    data={data}
                    syncId="anyId"
                    margin={{
                      top: 10,
                      right: 30,
                      left: 0,
                      bottom: 0,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Area
                      type="monotone"
                      dataKey="pv"
                      stroke="#666"
                      fill="#0EA5E9"
                    />
                  </AreaChart>
                </ResponsiveContainer>

                {/* <div className={Classes.noGraphData}>
                  <p>No data available</p>
                </div> */}
              </div>
            )}
          </div>
          <FiscalProjectFeeModal
            isSponsorshipModalOpen={isSponsorshipModalOpen}
            handleCancel={handleCancel}
          />
          <DeactivateFiscalProjectModal
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            fiscalProjectId={fiscalProjectId}
            // handleUserFiscalProjects={handleUserFiscalProjects}
          />
          <EditFiscalProjectModal
            isEditModalOpen={isEditModalOpen}
            handleCancel={handleCancel}
            fiscalProject={fiscalProject}
            getOrganizationFiscalProjects={getOrganizationFiscalProjects}
            // handleUserFiscalProjects={handleUserFiscalProjects}
          />
          <StatisticsModal
            isStatOpen={isStatOpen}
            handleCancel={handleCancel}
            fiscalProjectId={fiscalProjectId}
          />
        </>
      )}
      {show === "supporters" && (
        <>
          <div
            className={`${Classes.itemsOuter} d-flex align-items-center justify-content-between gap-4 pb-5 pt-3`}
          >
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input
                className="w-100"
                type="text"
                placeholder="Search"
                onChange={handleSupportersFilterChange}
              />
            </div>
          </div>
          <Row gutter={[20, 20]}>
            {filteredSponsorsListData && filteredSponsorsListData.length > 0 ? (
              filteredSponsorsListData?.map((friend, index) => (
                <Col lg={8} md={24} sm={24} xs={24} key={index}>
                  <LeaderBoardData friend={friend} />
                </Col>
              ))
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100">
                <p>You don't have any supporters yet</p>
              </div>
            )}
          </Row>
        </>
      )}
      {show === "inDirectsupporters" && (
        <>
          <div
            className={`${Classes.itemsOuter} d-flex align-items-center justify-content-between gap-4 pb-5 pt-3`}
          >
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input
                className="w-100"
                type="text"
                placeholder="Search"
                onChange={handleIndirectSupportersFilterChange}
              />
            </div>
          </div>
          <Row gutter={[20, 20]}>
            {filteredIndirectSupportersData &&
            filteredIndirectSupportersData.length > 0 ? (
              filteredIndirectSupportersData?.map((friend, index) => (
                <Col lg={8} md={24} sm={24} xs={24}>
                  <LeaderBoardData friend={friend} />
                </Col>
              ))
            ) : (
              <div className="d-flex justify-content-center align-items-center w-100">
                <p>You don't have any indirect supporters yet</p>
              </div>
            )}
          </Row>
        </>
      )}
      {show === "friends" && (
        <>
          <div
            className={`${Classes.rightSection} d-flex align-items-center justify-content-between gap-4 pb-5 pt-3`}
          >
            <div className={`${Classes.searchOuter} d-flex gap-2`}>
              <img src={SearchIcon} alt="icon" />
              <input
                className="w-100"
                type="text"
                placeholder="Search"
                onChange={(e) => searchFriendRequests(e.target.value)}
              />
            </div>
            <div className={Classes.collectionBtn}>
              <Link
                to="/friends-detail"
                className={`${Classes.outlineBtn} ${Classes.w_more}`}
              >
                Edit Friends
              </Link>
            </div>
          </div>
          <Row gutter={[20, 20]}>
            {filteredFriendRequests &&
              filteredFriendRequests.map((friend, index) => (
                <Col lg={8} md={24} sm={24} xs={24} key={index}>
                  <LeaderBoardData friend={friend} />
                </Col>
              ))}
          </Row>
        </>
      )}
      <CommonModal
        showModal={showWalletModal}
        isModalOpen={isWalletModalOpen}
        handleCancel={handleWalletModalCancel}
        message={message}
        modalType={modalType}
      />
      {show === "fiscal" && filteredFiscalProjectsData?.length > 0 && (
        <Pagination
        current={page} // Current page
        pageSize={limit} // Items per page
        total={fiscalProjectCount} // Total items
        onChange={handlePageClick} // Handle page change
        showSizeChanger
        onShowSizeChange={handlePageClick}
        style={{ textAlign: 'center', margin: '20px 0' }} // Center the pagination
      />
       )}
    </div>
  );
};

export default UserTabs;
