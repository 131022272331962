import React, { useEffect, useState } from "react";
import {
  ComposedChart,
  Line,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

import Classes from "./priceHistory.module.scss";
import angle from "../../assets/images/icons/angle.svg";
import moment from "moment";

function PriceHistory({ data }) {
  const [history, setHistory] = useState(null);
  useEffect(() => {
    if (data && data?.length > 0) {
      let formatedData = data.map((item) => {
        return {
          name: moment(item.createdAt).format("MMM DD"),
          pv:
            item.sellingMethod === 2
              ? item?.sellingConfig?.startPrice?.amount
              : item?.sellingConfig?.price?.amount,
          uv:
            item.sellingMethod === 2
              ? item?.sellingConfig?.startPrice?.amount
              : item?.sellingConfig?.price?.amount,
        };
      });
      setHistory(formatedData);
    }
    return () => {
      setHistory(null);
    };
  }, [data]);
  console.log(history, "history==>");
  return (
    <div className={`${Classes.priceHistory}`}>
      <div className={`${Classes.card} mb-4`}>
        <div
          className={`${Classes.border_bottom} d-flex align-items-center gap-2 pb-3 mb-3`}
        >
          <img src={angle} alt="icon" />
          <p>Price History</p>
        </div>
        {history && history?.length > 0 ? (
          <ResponsiveContainer width="100%" height={150} style={{padding: '5px'}}>
            <ComposedChart data={history}>
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" scale="band" />
              <YAxis />
              <Bar dataKey="uv" barSize={20} fill="#eee" />
              <Line type="monotone" dataKey="uv" stroke="#2BCDE4" />
            </ComposedChart>
          </ResponsiveContainer>
        ) : (
          <span className={`${Classes.noData}`}>No data yet</span>
        )}
      </div>
    </div>
  );
}

export default PriceHistory;
