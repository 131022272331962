import React from "react";
import CollectionDetailHistory from "../../CollectionDetailHistory";
import LandingLayout from "../../layout/LandingLayout";

const CollectionDetail = () => {
  return (
    <>
      <LandingLayout>
        <CollectionDetailHistory />
      </LandingLayout>
    </>
  );
};

export default CollectionDetail;
