import axios from 'axios';

export const notificationsListing = async (authenticationToken) => {
  try {
    const notifications = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/notifications`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authenticationToken}`,
        },
      }
    );
    return notifications;
  } catch (error) {
    return error.response.data;
  }
};

export const checkNotifications = async (
  authenticationToken,
  notificationId
) => {
  try {
    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/notifications/view`,
      {
        notificationId: notificationId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authenticationToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};
