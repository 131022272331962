import React, { useEffect, useState } from 'react';
import Classes from './notify.module.scss';
import { useDispatch } from 'react-redux';
import { Card } from 'antd';
import DataTable from 'react-data-table-component';
import moment from 'moment';
import { ThreeDots } from 'react-loader-spinner';
import {
  checkNotifications,
  notificationsListing,
} from '../../services/notification.service';
import { userNotificationList } from '../../store/_actions/notification_action';
import { useNavigate } from "react-router-dom";


let isSeen;
const customStyles = {
  rows: {
    style: (row) => {
      row?.children[2]?.map((child) => {
        if (child?.props?.row && child?.props?.row?.isSeen === true) {
          isSeen = true;
        } else {
          isSeen = false;
        }
      });

      return {
        padding: 15,
        borderBottom: '2px solid #f1f1f1',
        cursor: 'pointer',
        background: isSeen ? 'white' : '#e3f7fa',
      };
    },
  },
  headCells: {
    style: {
      fontWeight: 'bold',
      fontSize: 14,
    },
  },

  table: {
    style: {
      border: '1px solid #ddd',
    },
  },
};

const Notifications = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [notificationsList, setNotificationList] = useState([]);
  const authToken = localStorage.getItem('authenticationToken');
  const dispatch = useDispatch();
  const navigate = useNavigate();


  const getNotificationsList = async () => {
    try {
      setIsLoading(true);
      if (authToken) {
        const notificatios = await notificationsListing(authToken);

        if (notificatios?.data) {
          setNotificationList(notificatios?.data?.data);
          setIsLoading(false);
        }
      }
    } catch (error) {
      console.log('error');
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getNotificationsList();
  }, []);

  const handleViewNotification = async (notification) => {
    const notificationArray = [];
    try {
      setIsLoading(true);
      if (authToken) {
        const notificatios = await checkNotifications(
          authToken,
          notification?._id
          );
        if (notificatios?.data) {
          setIsLoading(false);
          if(notification?.nftId){
            navigate(`/nft/${notification?.nftId}`)
          }else{
            if(notification?.notificationType==='Friend Request Received'){
            navigate(`/friends-detail`)
            }else if(notification?.notificationType==='Invitation Request'){
            navigate(`/setting`)
            }else{
            navigate(`/profile`)
            }
            
          }
          getNotificationsList();
          const unSeenNotifications = notificatios?.data?.data?.filter(
            (noti) => noti?.isSeen === false
          );
          notificationArray.push(unSeenNotifications);
          dispatch(userNotificationList(unSeenNotifications));
        }
      }
    } catch (error) {
      console.log('error');
      setIsLoading(false);
    }
  };

  const columns = [
    {
      name: 'All Notification',
      selector: (row) => row.description,
      cell: (row) => (
        <div onClick={() => handleViewNotification(row)}>
          {row?.description}
        </div>
      ),
    },
    {
      name: '',
      selector: (row) => moment(row.createdAt).format('MMM DD, YYYY'),
      cell: (row) => (
        <div onClick={() => handleViewNotification(row)}>
          {moment(row.createdAt).format('MMM DD, YYYY')}
        </div>
      ),
    },
  ];
  const filteredData = notificationsList || [];
  return (
    <div className={Classes.profile}>
      <h1 className={Classes.title}>Notifications</h1>
      <div className='mb-4'>
        <Card>
          {isLoading ? (
            <div className='d-flex justify-content-center align-items-center'>
              <ThreeDots
                height='30'
                width='30'
                radius='9'
                color='#1890ff'
                ariaLabel='three-dots-loading'
                wrapperStyle={{}}
                wrapperClassName=''
                visible={true}
              />
            </div>
          ) : (
            <DataTable
              columns={columns}
              customStyles={customStyles}
              data={filteredData}
              pagination
              noHeader={true}
            />
          )}
        </Card>
      </div>
    </div>
  );
};

export default Notifications;
