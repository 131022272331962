// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import CONTRACT_DETAILS from "../contract/contractInfo";

const marketContractAddress = CONTRACT_DETAILS.market.address;
const marketplaceABI = CONTRACT_DETAILS.market.abi;

const useCancelBidForUser = () => {
  //approve contract
  const {
    isError: isCancelBidUserSentError,
    data: cancelBidUserSentTx,
    isLoading: isCancelBidUserTxInProgress,
    isSuccess: isCancelBidUserTxSent,
    write: cancelBidForUser,
    error: cancelBidUserSentError
  } = useContractWrite({
    address: marketContractAddress,
    abi: marketplaceABI,
    functionName: "cancelBid", // Changed the functionName to cancelBid
  });

  const {
    isLoading: isCancelBidUserWaiting,
    isSuccess: isCancelBidUserCompleted,
    isError: isCancelBidUserError,
    error: cancelBidUserTxError,
    data: cancelBidUserResp
  } = useWaitForTransaction({
    hash: cancelBidUserSentTx?.hash,
  });

  return {
    //nft operation
    isCancelBidUserSentError,
    cancelBidUserSentTx,
    isCancelBidUserTxInProgress,
    isCancelBidUserTxSent,
    cancelBidForUser, // Renamed the function
    cancelBidUserSentError,
    isCancelBidUserWaiting,
    isCancelBidUserCompleted,
    isCancelBidUserError,
    cancelBidUserTxError,
    cancelBidUserResp
  };
};

export default useCancelBidForUser;
