import axios from 'axios';

export const invitesList = async (token) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/invites`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const sendInvitation = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/invites/send-invite`,
      {
        email: data?.email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data?.token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const resendInvitation = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/invites/resend-invite`,
      {
        email: data?.email,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data?.token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const acceptInvitation = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/invites/accept-invite`,
      {
        email: data?.email,
        status: data?.status,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data?.token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};

export const rejectInvitation = async (data) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/invites/reject-invite`,
      {
        email: data?.email,
        status: data?.status,
      },
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${data?.token}`,
        },
      }
    );
    return response;
  } catch (error) {
    return error.response.data;
  }
};
