import React, { useState } from "react";
import Classes from "./featuredNftDetailComp.module.scss";
import { Container } from "react-bootstrap";
import Search from "../../assets/images/icons/searchbutton.svg";
import SearchMain from "../SearchMain";
import FeaturedCard from "../FeaturedCard";

function FeaturedNftDetailComp() {
  const [show, setShow] = useState("all");
  const [showSearch, setShowSearch] = useState(false);
  return (
    <div className={Classes.featuredNftDetailComp}>
      <Container>
        <h1 className={Classes.title}>Featured NFTs</h1>
        <div className="d-flex gap-5 align-items-center justify-content-between">
          <div className="d-flex gap-5 align-items-center mb-4">
            <button
              onClick={() => setShow("all")}
              className={
                show === "all" ? `${Classes.activeTab}` : `${Classes.tab}`
              }
            >
              All
            </button>
            <button
              onClick={() => setShow("new")}
              className={
                show === "new" ? `${Classes.activeTab}` : `${Classes.tab}`
              }
            >
              New
            </button>
          </div>
          <div
            className="cursor_pointer"
            onClick={() => setShowSearch(!showSearch)}
          >
            <img src={Search} alt="icon" />
          </div>
        </div>
        {showSearch && (
          <div className="mb-4">
            <SearchMain />
          </div>
        )}
        {show === "all" && <FeaturedCard />}
        {show === "new" && <FeaturedCard />}
      </Container>
    </div>
  );
}

export default FeaturedNftDetailComp;
