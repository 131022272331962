import {
  WALLET_PROVIDER,
  SET_WALLET_ADDRESS,
  SET_WALLET_EMPTY,
  SET_WALLET_ERROR
} from "../constants";

const initialWalletState = {
  provider: "",
  connectedAddress: null,
  walletAddressError:null
};

export const WalletReducer = (state = initialWalletState, action) => {
  switch (action.type) {
    case WALLET_PROVIDER:
      return {
        ...state,
        provider: action.payload,
      };
    case SET_WALLET_ADDRESS:
      return {
        ...state,
        connectedAddress: action.payload,
      };
    case SET_WALLET_EMPTY:
      return {
        ...state,
        connectedAddress: null,
      };
      case SET_WALLET_ERROR:
        return {
          ...state,
          walletAddressError: action.payload,
        };
    default:
      return state;
  }
};
