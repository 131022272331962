import { SET_BUY_NOW_LOADER } from "../constants";

const initialWalletState = {
  isBuying: false,
  status: "",
};

export const AuctionReducer = (state = initialWalletState, action) => {
  switch (action.type) {
    case SET_BUY_NOW_LOADER:
      return {
        ...state,
        isBuying: action.payload.loader,
        status:action.payload.status
      };

    default:
      return state;
  }
};
