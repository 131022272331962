import axios from 'axios';

const authenticationToken = localStorage.getItem('authenticationToken');

export const collectionsListing = async (token) => {
  try {
    const notifications = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/collections/user/list`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return notifications;
  } catch (error) {
    return error.response.data;
  }
};
export const allCollectionsListing = async (params) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/collections/list`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authenticationToken}`,
        },
        params,
      }
    );
    return res;
  } catch (error) {
    return error.response.data;
  }
};

export const collectionDetail = async (id, authToken, params) => {
  try {
    const notifications = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/collections/find/${id}`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        params,
      }
    );
    return notifications;
  } catch (error) {
    return error.response.data;
  }
};

export const addNewCollection = async (data, authToken, file) => {
  try {
    const formData = new FormData();
    formData.append('userId', data?.userId);
    formData.append('collectionName', data?.collectionName);
    formData.append('description', data?.description);

    if (file) {
      formData.append('collectionImage', file);
    }

    const collection = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/collections/create`,
      formData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    return collection;
  } catch (error) {
    return error.response.data;
  }
};
