import React from 'react';
import { Container } from 'react-bootstrap';
import CreateCollectionComp from '../../CreateCollectionComp';
import Header from '../../layout/LandingLayout/Header';

const CreateCollection = () => {
  return (
    <div className='bgLight'>
      <Header />
      <Container className='pt-5'>
        <CreateCollectionComp />
      </Container>
    </div>
  );
};

export default CreateCollection;
