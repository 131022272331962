import React, { useEffect, useState } from 'react';
import Classes from './causeDetailComp.module.scss';
import { Container } from 'react-bootstrap';
import { Col, Row, Select } from 'antd';
import Search from '../../assets/images/icons/searchbutton.svg';
import SearchMain from '../SearchMain';
import { useDispatch, useSelector } from 'react-redux';
import { getCategories } from '../../store/_actions/categories_actions';

const CauseDetailComp = ({title}) => {
  const [show, setshow] = useState(false);
  const [filterCategories, setFilterCategories] = useState('');
  const { categories } = useSelector((state) => state?.categories);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
  }, []);
  const handleChange = (e) => {
    setFilterCategories(e.target.value);
  };

  const filteredData =
    categories &&
    categories?.filter((item) =>
      item.categoryName.toLowerCase().includes(filterCategories.toLowerCase())
    );
  return (
    <div className={Classes.causeDetailComp}>
      <Container>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <h1 className={Classes.title}>{title||'-'}</h1>
          <div className={Classes.searchIcon} onClick={() => setshow(!show)}>
            <img src={Search} alt='img' />
          </div>
        </div>
        {show && (
          <div className='mb-4'>
            <SearchMain onChange={handleChange} />
          </div>
        )}
        <Row gutter={[20, 20]}>
          {filteredData &&
            filteredData?.map((category, index) => (
              <Col lg={6} md={8} sm={12} xs={24}>
                <div className={Classes.causeOuter} key={index}>
                  <img
                    className={Classes.img}
                    src={category?.categoryImage}
                    alt='img'
                  />
                  <h2 className={Classes.txt}>{category?.categoryName}</h2>
                </div>
              </Col>
            ))}
        </Row>
      </Container>
    </div>
  );
};

export default CauseDetailComp;
