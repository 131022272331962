import React, { useEffect, useState } from "react";
import Classes from "./friendsDetails.module.scss";
import { Container } from "react-bootstrap";
import SearchBtn from "../../assets/images/icons/searchbutton.svg";
import LeaderBoardData from "../LeaderBoardData";
import { Col, Row, Select } from "antd";
import Search from "../../assets/images/icons/searchdark.svg";
import { useSelector } from "react-redux";
import CaretDown from "../../assets/images/icons/caretDown.svg";
import FriendRequests from "./FriendRequests";
import { friendRequestsList } from "../../services/friends.service";
import FriendSuggestion from "../FriendSuggestion";
import { useNavigate } from "react-router-dom";

const FriendsDetailsComp = () => {
  const [show, setShow] = useState("all");
  const [approvedFriendRequests, setApprovedFriendRequests] = useState([]);
  const [pendingFriendRequests, setPendingFriendRequests] = useState([]);
  const [showSearch, setShowSearch] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("friends");
  const auth = useSelector((state) => state.auth);
  const navigate=useNavigate()
  const token = localStorage.getItem("authenticationToken");

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const handleChange = (value) => {
    setSelectedOption(value);
  };

  const getFriendsListing = async () => {
    try {
      const requests = await friendRequestsList(token);
      let approvedFriends = [];
      let pendingFriends = [];
      if (requests?.data?.data) {
        const receivedFriendRequests =
          requests.data.data.receivedFriendRequests || [];
        const sentFriendRequests = requests.data.data.sentFriendRequests || [];

        approvedFriends = [
          ...receivedFriendRequests.filter(
            (request) => request?.requestStatus === "approved"
          ),
          ...sentFriendRequests.filter(
            (request) => request?.requestStatus === "approved"
          ),
        ];

        pendingFriends = [
          ...receivedFriendRequests.filter(
            (request) => request?.requestStatus === "pending"
          ),
         
        ];
        setApprovedFriendRequests(approvedFriends);
        setPendingFriendRequests(pendingFriends);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getFriendsListing();
  }, [show]);

  useEffect(() => {
    const {username,id } = auth||{};
    if (!username &&!id) {
      navigate("/login");
    }else if(!username &&id){
      navigate("/get-started");
    }
  }, [auth]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filterFriends = (friends) => {
    return friends.filter((friend) =>
      friend?.user?.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
      friend?.user?.lastName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  };
  return (
    <div className={Classes.friendsDetailsComp}>
    <Container className="p-0">
      <h1 className={Classes.title}>Friends</h1>
      <div
        className={`${Classes.friendsTabs} d-flex gap-md-5 gap-2 align-items-center justify-content-between mb-4`}
      >
        <div className="d-flex gap-md-5 gap-3 align-items-center">
          <button
            onClick={() => setShow("all")}
            className={
              show === "all" ? `${Classes.activeTab}` : `${Classes.tab}`
            }
          >
            Your Friends
          </button>

          <button
            onClick={() => setShow("friendRequests")}
            className={
              show === "friendRequests"
                ? `${Classes.activeTab}`
                : `${Classes.tab}`
            }
          >
            Friend Requests
          </button>
          <button
            onClick={() => setShow("suggestions")}
            className={
              show === "suggestions"
                ? `${Classes.activeTab}`
                : `${Classes.tab}`
            }
          >
            Suggestions
          </button>
        </div>
        <div className="d-flex align-items-center gap-1">
          <div
            className={Classes.searchOuter}
            onClick={() => setShowSearch(!showSearch)}
          >
            <img src={SearchBtn} alt="img" />
          </div>
        </div>
      </div>
      {showSearch && (
        <div className={Classes.search}>
          <div className="d-flex ms-3 w-100">
            <img src={Search} alt="icon" />
            <input
              className="px-2"
              placeholder="Search...."
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>
          <button className={Classes.blueBtn} type="button">
            Search
          </button>
        </div>
      )}
      <>
        {show === "all" && (
          <Row gutter={[20, 20]}>
            {approvedFriendRequests && approvedFriendRequests.length > 0 ? (
              filterFriends(approvedFriendRequests)?.map((friend, index) => (
                <Col lg={8} md={24} sm={24} xs={24} key={index}>
                  <LeaderBoardData friend={friend} />
                </Col>
              ))
            ) : (
              <p style={{ marginLeft: "40%" }}>
                You don't have any friends yet
              </p>
            )}
          </Row>
        )}
        {show === "friendRequests" && (
          <FriendRequests
            showModal={showModal}
            isModalOpen={isModalOpen}
            handleCancel={handleCancel}
            pendingFriendRequests={filterFriends(pendingFriendRequests)}
            getFriendsListing={getFriendsListing}
          />
        )}
        {show === "suggestions" && <FriendSuggestion searchQuery={searchQuery} />}
      </>
    </Container>
  </div>
);
};

export default FriendsDetailsComp;
