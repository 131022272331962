import React from "react";
import { Link } from "react-router-dom";
import Classes from "../Pages/scss/notFound.module.scss";

function NftNotFound() {
  return (
    <div className={Classes.notFound}>
      <div className="d-flex justify-content-center align-items-center flex-column  py-5">
        <h1>Ooopss!! NFT not found.</h1>
        <p>
          Go Back to the <Link to='/'>Home Page</Link>
        </p>
      </div>
    </div>
  );
}

export default NftNotFound;
