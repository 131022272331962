import { getRequest } from '../../helpers/request';
import { SET_CATEGORIES_LISTING } from '../constants';
import { ENV } from '../../config';

export const getCategories = (params) => (dispatch) => {
  let url = ENV.apiBaseUrl + '/categories/';
  getRequest(url,params)
    .then((res) => {
      dispatch({ type: SET_CATEGORIES_LISTING, payload: res?.data });
    })
    .catch((err) => {
      console.log(err, 'err=>');
    });
};
