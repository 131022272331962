import React, { useEffect, useState } from 'react';
import NFTDetail from '../../NFTDetail';
import { Container } from 'react-bootstrap';
import Offers from '../../Offers';
import { Col, Row } from 'antd';
import NftInformation from '../../NftInformation';
import MoreItems from '../../MoreItems';
import { getNFT } from '../../../store/_actions/nft_actions';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import OffersTable from '../../OffersTable';
import ListingTable from '../../ListingTable';
import { useLocation } from 'react-router-dom';
import LandingLayout from '../../layout/LandingLayout';
const ViewNft = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { nft } = useSelector((state) => state?.nft);
  const { bids } = useSelector((state) => state?.bid);
  const [activeAuction,setActiveAuction]=useState(null)
  const [price,setPrice]=useState()
  const location = useLocation();

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = () => {
    dispatch(getNFT(id));
  };
  // const { totalListings } = nft && nft?.nft;  destructuring
  const { totalListings } = nft?.nft || {};

 
useEffect(()=>{
  const foundListedAuction =
  location?.state?.auctionId && totalListings?.length > 0
    ? totalListings?.find((item) => item._id === location?.state?.auctionId)
    : null;

const auction = foundListedAuction
  ? foundListedAuction
  : totalListings?.length > 0
  ? totalListings[0]
  : null;

const auctionPrice =
  auction?.sellingMethod === 1
    ? auction?.sellingConfig?.price.amount
    : auction?.sellingMethod === 2
    ? auction?.sellingConfig?.startPrice?.amount
    : 0;
    setActiveAuction(auction)
    setPrice(auctionPrice)
},[totalListings,location?.state?.auctionId])
  return (
    <>
      <LandingLayout>
        <Container className='pt-5'>
          {nft?.nft && (
            <NFTDetail
              data={nft?.nft}
              offers={bids}
              priceData={nft?.nft?.priceHistory}
              fetchData={fetchData}
              activeAuction={activeAuction}
              price={price}
            />
          )}
          <Row gutter={[25, 25]}>
            <Col lg={10} md={24} sm={24} xs={24}>
              {nft?.nft && <NftInformation data={nft?.nft} />}
            </Col>
            <Col lg={14} md={24} sm={24} xs={24}>
              <ListingTable listings={nft?.nft.totalListings} />
              <OffersTable activity={nft?.activity} />
              {bids && activeAuction?.sellingMethod === 2 && (
                <Offers offers={bids} data={nft?.nft} />
              )}
            </Col>
          </Row>
          {nft?.moreLikeThis && <MoreItems data={nft?.moreLikeThis} />}
        </Container>
      </LandingLayout>
    </>
  );
};

export default ViewNft;
