import { SET_CREATE_TRANSTIOIN_LOADER } from "../constants";

const initialWalletState = {
  isCreatingTransaction: false,
  status: "",
};

export const Transaction = (state = initialWalletState, action) => {
  switch (action.type) {
    case SET_CREATE_TRANSTIOIN_LOADER:
      return {
        ...state,
        isCreatingTransaction: action.payload.loader,
        status: action.payload.status,
      };
    default:
      return state;
  }
};
