import { Modal, Select, DatePicker } from "antd";
import React, { useEffect, useState } from "react";
import Classes from "./tabs.module.scss";
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { getFiscalProjectStats } from "../../services/fiscalProjects.service";
import moment from "moment";
const { RangePicker } = DatePicker;

function StatisticsModal({ isStatOpen, handleCancel, fiscalProjectId }) {
  const [selectedOption, setSelectedOption] = useState("");
  const [dateFilter, setDateFilter] = useState("all");
  const [data, setData] = useState(null);
  const [orignalData, setOrignalData] = useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const handleChange = (value) => {
    setDateFilter(value);
  };
  const options = [
    { label: "All", value: "all" },
    { label: "Today", value: "today" },
    { label: "Last 24 hours", value: "last24Hours" },
    { label: "Last Week", value: "lastWeek" },
    { label: "Last Month", value: "lastMonth" },
    { label: "Last Year", value: "lastYear" },
  ];
  const getStats = async () => {
    const stats = await getFiscalProjectStats({
      dateFilter,
      id: fiscalProjectId,
      startDate: dateRange.startDate,
      endDate: dateRange.endDate,
    });
    setOrignalData(stats.data.data);
    formatData(stats.data.data);
  };
  const formatData = (data) => {
    let count = 0;
    const res = data.map((item) => {
      const createdAt = moment(item.createdAt);
      count = count + item?.price;
      return {
        name: createdAt.format("D MMMM HH:mm"),
        Donation: count,
        // uv: count,
      };
    });
    setData(res);
  };
  useEffect(() => {
    getStats();
  }, [dateFilter, fiscalProjectId,dateRange]);

  function disabledDate(current) {
    // Can not select days after today
    return current && current > moment().endOf("day");
  }
  const handleRangePicker = (dates) => {
    setDateFilter("all");
    setDateRange({
      startDate: dates ? dates[0]?.toISOString() : null,
      endDate: dates ? dates[1]?.toISOString() : null,
    });
  };
  return (
    <div>
      <Modal
        title="Statistics"
        open={isStatOpen}
        footer={null}
        onCancel={handleCancel}
        className={Classes.statsModal}
      >
        <div className={`${Classes.modalInner}`}>
          <div className="d-flex align-items-center gap-2 mb-2">
            <Select
              // placeholder="All"
              defaultValue={["All"]}
              style={{
                width: 160,
              }}
              onChange={handleChange}
              options={options}
              disabled={dateRange?.startDate}
              value={dateFilter}
            />
            <RangePicker
              disabledDate={disabledDate}
              onChange={handleRangePicker}
            />
          </div>
          <ResponsiveContainer height={250} width="100%">
            <ComposedChart
              width="100%"
              height={250}
              data={data}
              margin={{
                top: 20,
                right: 20,
                bottom: 20,
                left: 20,
              }}
            >
              <CartesianGrid stroke="#f5f5f5" />
              <XAxis dataKey="name" scale="band" />
              <YAxis />
              <Tooltip />
              <Bar dataKey="Donation" barSize={20} fill="#0EA5E9" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </Modal>
    </div>
  );
}

export default StatisticsModal;
