import React, { useState } from "react";
import Classes from "./formField.module.scss";
import ButtonClasses from "../buildProfile.module.scss";
import { Col, Row, Checkbox } from "antd";
import { Formik, Field, ErrorMessage, Form } from "formik";
import { useSelector } from "react-redux";
import {  useNavigate } from "react-router-dom";
import { validationSchema } from "./FormValidations";
import { alertMessage } from "../../../constants/alertMessage";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";
import { validateUsername } from "../../../services/auth.service";
import Terms from "../../Terms";

const FormFields = ({ setFormFieldsData, formFieldsData, restData }) => {
  const [isUsernameUnique, setIsUsernameUnique] = useState("");
  const [isCheckTerms, setIsCheckTerms] = useState(false);
  const auth = useSelector((state) => state.auth);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleButtonClick = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const navigate = useNavigate();

  const handleUsernameExist = async (username) => {
    try {
      const trimmedUsername = username.trim();
      if(trimmedUsername.length>3){
        const authToken = localStorage.getItem("authenticationToken");
        const response = await validateUsername(authToken, trimmedUsername);
  
        if (response?.status === 200) {
          setIsUsernameUnique("available");
        } else {
          setIsUsernameUnique("taken");
        }
      }else{
        setIsUsernameUnique("");
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  return (
    <>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          username: "",
          email: auth?.email,
          address: "",
          addressTwo: "",
          city: "",
          zipCode: "",
          country: "",
          role: restData?.role || "",
          walletAddress: restData?.walletAddress || "",
        }}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setFormFieldsData((prevState) => ({
            ...prevState,
            firstName: values?.firstName,
            lastName: values?.lastName,
            username: values?.username,
            email: values?.email,
            address: values?.address,
            addressTwo: values?.addressTwo,
            city: values?.city,
            zipCode: values?.zipCode,
            country: values?.country,
            role: values?.role,
            walletAddress: values.walletAddress,
          }));

          if (formFieldsData?.profilePicture) {
            navigate("/selection", {
              state: {
                userData: {
                  firstName: values?.firstName,
                  lastName: values?.lastName,
                  username: values?.username,
                  email: values?.email,
                  address: values?.address,
                  addressTwo: values?.addressTwo,
                  city: values?.city,
                  zipCode: values?.zipCode,
                  country: values?.country,
                  role: values?.role,
                  profilePicture: formFieldsData?.profilePicture,
                  walletAddress: values.walletAddress,
                },
              },
            });
          } else {
            alertMessage("error", "Profile picture is required.");
          }
          setSubmitting(false);
        }}
      >
        {({ handleChange }) => (
          <Form>
            <Row gutter={[16, 38]} className={`${Classes.formFields} w-100`}>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <div className={Classes.label}>First Name* </div>
                  <Field
                    type="text"
                    className={Classes.input}
                    name="firstName"
                  />
                </div>
                <ErrorMessage
                  name="firstName"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>

              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <div className={Classes.label}>Last Name* </div>
                  <Field
                    type="text"
                    className={Classes.input}
                    name="lastName"
                  />
                </div>
                <ErrorMessage
                  name="lastName"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className={Classes.label}>Username*</div>
                    <p
                      className={`${Classes.txt} d-flex align-items-center gap-1`}
                    >
                      {isUsernameUnique === "available" ? (
                        <>
                          <AiFillCheckCircle fontSize="20px" color="green" />
                          Username Available
                        </>
                      ) : isUsernameUnique === "taken" ? (
                        <>
                          <AiFillCloseCircle fontSize="20px" color="red" />
                          Username Already Taken
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <Field
                    type="text"
                    className={Classes.input}
                    name="username"
                    onChange={(e) => {
                      handleChange(e);
                      handleUsernameExist(e.target.value);
                    }}
                  />
                </div>
                <ErrorMessage
                  name="username"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <div className={Classes.label}>Email*</div>
                  <Field
                    type="email"
                    className={Classes.input}
                    name="email"
                    // diabled='true'
                    disabled={true}
                    value={auth?.email}
                  />
                </div>
                <ErrorMessage
                  name="email"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <div className={Classes.label}>Address*</div>
                  <Field type="text" className={Classes.input} name="address" />
                </div>
                <ErrorMessage
                  name="address"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <div className={Classes.label}>Address Line 2</div>
                  <Field
                    type="text"
                    className={Classes.input}
                    name="addressTwo"
                  />
                </div>
                <ErrorMessage
                  name="addressTwo"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <Row gutter={[16, 16]}>
                  <Col md={12} sm={24} xs={24}>
                    <div>
                      <div className={Classes.label}>City*</div>
                      <Field
                        type="text"
                        className={Classes.input}
                        name="city"
                      />
                    </div>
                    <ErrorMessage
                      name="city"
                      render={(msg) => (
                        <span className="mt-1 d-block" style={{ color: "red" }}>
                          {msg}
                        </span>
                      )}
                    />
                  </Col>
                  <Col md={12} sm={24} xs={24}>
                    <div>
                      <div className={Classes.label}>Zip Code*</div>
                      <Field
                        type="text"
                        className={Classes.input}
                        name="zipCode"
                      />
                    </div>
                    <ErrorMessage
                      name="zipCode"
                      render={(msg) => (
                        <span className="mt-1 d-block" style={{ color: "red" }}>
                          {msg}
                        </span>
                      )}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <div className={Classes.label}>Country*</div>
                  <Field type="text" className={Classes.input} name="country" />
                </div>
                <ErrorMessage
                  name="country"
                  render={(msg) => (
                    <span className="mt-1 d-block" style={{ color: "red" }}>
                      {msg}
                    </span>
                  )}
                />
              </Col>
              <div className='d-flex align-items-center gap-2'>
              <Checkbox onClick={() => setIsCheckTerms(!isCheckTerms)}></Checkbox>
                <p className={`${Classes.subTxt}`}>
                    I have read and agreed to the&nbsp;
                    <span onClick={handleButtonClick}>Terms & Conditions*</span>
                  </p>
              </div>
              <div className="d-flex justify-content-between w-100">
                <button
                  onClick={() => navigate("/get-started")}
                  type="button"
                  className={`${ButtonClasses.blueBtn} ${ButtonClasses.back}`}
                >
                  Back
                </button>
                <button
                  className={`${ButtonClasses.blueBtn} ${
                    !isCheckTerms && ButtonClasses.back
                  }`}
                  type="submit"
                  disabled={!isCheckTerms}
                >
                  Next
                </button>
              </div>
            </Row>
          </Form>
        )}
      </Formik>
      <Terms
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default FormFields;
