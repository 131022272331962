import React, { useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import LandingLayout from "../../layout/LandingLayout";
import HeroSection from "../../HeroSection";
import SigninHome from "./SigninHome";
import GuestHome from "./GuestHome";
import { thunkLogin } from "../../../store/_actions/user_action";
import { useDispatch, useSelector } from "react-redux";
import { alertMessage } from "../../../constants/alertMessage";

const Home = () => {
  const authenticationToken = localStorage.getItem("authenticationToken");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);

  const getUser = async () => {
    try {
      const url = `${process.env.REACT_APP_API_BASE_URL}/authorization/login/success`;
      const { data } = await axios.get(url, { withCredentials: true });

      if (data?.user) {
        localStorage.setItem("authenticationToken", data?.user?.token);
        localStorage.setItem("userInfo", JSON.stringify(data?.user));
        dispatch(
          thunkLogin(data.user, () => {
            alertMessage("success", `Logged in successfully`);
            setTimeout(() => {
              navigate("/get-started");
            }, 1000);
          })
        );
      }
      // TODO: save data to redux and then need to complete the login regitration flow, but before need to check if user is already registered
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (!auth?.email) {
      getUser();
    }
  }, []);

  return (
    <>
      <LandingLayout>
        <HeroSection />
        {authenticationToken ? <SigninHome /> : <GuestHome />}
      </LandingLayout>
    </>
  );
};

export default Home;
