import React, { useEffect, useState } from "react";
import Classes from "./leaderboard.module.scss";
import { Container } from "react-bootstrap";
import { Tabs, Row, Col } from "antd";
import LeaderBoardPublic from "../LeaderBoardPublic";
import { leaderBoardList } from "../../services/auth.service";
import { Link } from "react-router-dom";

const LeaderBoard = () => {
  const authToken = localStorage.getItem("authenticationToken");     
  const [selectedOption, setSelectedOption] = useState("organization");      
  const [leaderBoardData, setLeaderBoardData] = useState(null);               
  const currentUrl = window.location.href;
  let limit = 9;
  if (currentUrl.includes("leaderboards")) {       
    limit = 100;          
  }
  const handleChange = (value) => {
    setSelectedOption(value);         
  };
  const getLeaderBoardData = async () => {    
    const response = await leaderBoardList(authToken, selectedOption, limit);        
    if (response?.data?.success) {         
      setLeaderBoardData(response?.data?.data);                     
    }      
  };
  useEffect(() => {
    getLeaderBoardData();
  }, [selectedOption]);

  return (
    <div className={Classes.leaderBoard}>           
      <Container className="position-relative">                      
        <div className="d-flex align-items-center gap-3 mb-4">                                    
          <h1 className={Classes.title}>Leaderboards</h1>                                            
          {!currentUrl.includes("leaderboards") && (                                                       
            <Link to="/leaderboards" className={Classes.seeAllBtn}>                                      
              See More        
            </Link>   
          )}
        </div>
        <div className="d-flex align-items-center justify-content-between gap-2">                     
          <Tabs defaultActiveKey="organization" onChange={handleChange}>                       
            <Tabs.TabPane tab="Organizations" key="organization">                    
              <div className="mt-4">   
                <Row gutter={[20, 20]}>                       
                  {leaderBoardData &&                    
                    leaderBoardData.map((index, user) => (                          
                      <Col lg={8} md={12} sm={24} xs={24} key={index}>                                
                        <Link to={`/user/${index?.username}`}>            
                          <LeaderBoardPublic index={index} user={user} />            
                        </Link>
                      </Col>
                    ))}
                </Row>
              </div>
            </Tabs.TabPane>
            <Tabs.TabPane tab="Users" key="user">
              <div className="mt-4">
                <Row gutter={[20, 20]}>
                  {leaderBoardData &&
                    leaderBoardData.map((index, user) => (     
                      <Col lg={8} md={12} sm={24} xs={24} key={index}>       
                        <Link to={`/user/${index?.username}`}>         
                          <LeaderBoardPublic index={index} user={user} />                      
                        </Link>          
                      </Col>
                    ))}
                </Row>           
              </div>
            </Tabs.TabPane>
          </Tabs>     
          <div
            className={`${Classes.actionBtn} d-flex align-items-center gap-3`}
          >
          </div>
        </div>
      </Container>
    </div>
  );
};

export default LeaderBoard;
