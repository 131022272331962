import axios from 'axios';

export const userIsoLettersList = async (token) => {
  try {
    const isoDocs = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/iso/user/iso-letters`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return isoDocs;
  } catch (error) {
    return error.response.data;
  }
};
export const isoDeterminationLetterVerification = async (data) => {
  try {
    const formData = new FormData();
    if (data?.isoDocument) {
      formData.append('isoDocument', data.isoDocument);
    }
    const isoVerification = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/iso/submit/iso-determination-letter`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${data?.authToken}`,
        },
      }
    );
    return isoVerification;
  } catch (error) {
    return error.response.data;
  }
};
