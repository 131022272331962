import { getRequest, postRequest } from "../../helpers/request";
import {
  SHOW_PLACE_BET_LOADER,
  HIDE_PLACE_BET_LOADER,
  PLACE_BET_STATUS,
  SET_BETS,
} from "../constants";
import { ENV } from "../../config";
export const placeBet = (data) => (dispatch) => {
  //   let url = ENV.serverUrl + "/client/activity/live";
  let url = ENV.apiBaseUrl + "/bid/create";
  console.log("action triggered");
  dispatch({ type: SHOW_PLACE_BET_LOADER });
  postRequest(url, data)
    .then((res) => {
      console.log(res, "res of place bet");
      // dispatch({ type: HIDE_PLACE_BET_LOADER });
      // dispatch({ type: PLACE_BET_STATUS, payload:{status: "success",loader:false} });
    })
    .catch((err) => {
      console.log(err, "err=>");
      dispatch({ type: HIDE_PLACE_BET_LOADER });
      dispatch({ type: PLACE_BET_STATUS, payload: "failed" });
    });
};
export const getBids = (id) => (dispatch) => {
  //   let url = ENV.serverUrl + "/client/activity/live";
  let url = ENV.apiBaseUrl + `/bid/auction/list/${id}`;
  console.log("action triggered");

  getRequest(url)
    .then((res) => {
      console.log(res?.data, "res of bets list");

      dispatch({ type: SET_BETS, payload: res?.data });
    })
    .catch((err) => {
      console.log(err, "err=>");
      // dispatch({ type: PLACE_BET_STATUS, payload: "failed" });
    });
};
