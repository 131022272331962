import React from 'react';
import { useNavigate } from 'react-router-dom';
import Classes from './nftCard.module.scss';
import polygon from '../../assets/images/icons/polygon.svg';
import  VideoComponent  from '../../helpers/VideoPreview';

const NFTCard = ({ nftList }) => {
  const navigate = useNavigate();
  return (
    <div
      className={Classes.nftCard}
      onClick={() => navigate(`/nft/${nftList?._id}`)}
    >
      {nftList?.primaryType === 'image' ? (
        <img
          src={nftList?.file}
          className={`${Classes.nftImg} mb-3`}
          alt='img'
        />
      ) : (
        <VideoComponent source={nftList?.file} />
      )}
      <div className='d-flex justify-content-between align-items-ceter mb-1'>
        <p className={Classes.name}>{nftList?.name}</p>
        <img height={25} src={polygon} alt='icon' />
      </div>
      {/* <p className={`${Classes.desc} mb-1`}>Last sale: 0.78 WETH</p> */}
      {/* <div className='d-flex justify-content-between align-items-ceter'>
        <p className={Classes.txt}>Price</p>
        <p className={Classes.price}>0.792 ETH</p>
      </div> */}
    </div>
  );
};

export default NFTCard;
