import React, { useEffect, useState } from 'react';
import Classes from '../Activity/activity.module.scss';
import { Container } from 'react-bootstrap';
import MainCard from '../MainCard';
import Slider from 'react-slick';
import { Link, useLocation } from 'react-router-dom';
import { getNFTs } from '../../store/_actions/nft_actions';
import { useSelector, useDispatch } from 'react-redux';

const NftsFilters = ({ title, data }) => {
  const location = useLocation();
  const isExploreRoute = location.pathname === '/explore';
  const [params, setParams] = useState({
    typeFilter: '',
    priceFilter: '',
    dateFilter: 1,
    search: '',
  });
  const dispatch = useDispatch();
  const { nfts } = useSelector((state) => state.nft);
  const settings = {
    dots: false,
    infinite: nfts?.length === 1 ? true : false,
    speed: 500,
    slidesToShow: nfts?.length >= 4 ? 4 : nfts?.length,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    lazyLoad: 'ondemand',
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  useEffect(() => {
    dispatch(getNFTs(params));
  }, []);
  return (
    <div className={`${Classes.activity} ${isExploreRoute ? Classes.explore : ''}`}>
      <Container>
        <div className='d-flex align-items-center justify-content-between mb-4'>
          <h1 className={Classes.heading}>{title || 'New Additions'}</h1>
          <Link to='/nft-listing' className={Classes.seeAllBtn}>
            See More
          </Link>
        </div>
        <Slider {...settings}>
          {data &&
            data?.length > 0 &&
            data.map((item, index) => <MainCard item={item} key={index} />)}
        </Slider>
      </Container>
    </div>
  );
};

export default NftsFilters;
