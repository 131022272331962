import React from "react";
import Header from "../../layout/LandingLayout/Header";
import FeaturedOrgDetailComp from "../../FeaturedOrgDetailComp";

function FeaturedOrgDetail() {
  return (
    <>
      <Header />
      <FeaturedOrgDetailComp />
    </>
  );
}

export default FeaturedOrgDetail;
