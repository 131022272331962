import React, { useEffect, useState } from "react";
import Classes from "./history.module.scss";
import { Tooltip } from "antd";
import check from "../../assets/images/icons/verified.svg";
import { Container } from "react-bootstrap";
import icon2 from "../../assets/images/icons/socialIcon2.svg";
import icon3 from "../../assets/images/icons/socialIcon3.svg";
import icon4 from "../../assets/images/icons/socialIcon4.svg";
import icon6 from "../../assets/images/icons/socialIcon6.svg";
import icon7 from "../../assets/images/icons/socialIcon7.svg";
import icon8 from "../../assets/images/icons/socialIcon8.svg";
import icon9 from "../../assets/images/icons/socialIcon9.svg";
import icon5 from "../../assets/images/icons/twitter-new.svg";
import share from "../../assets/images/icons/share.svg";
import OrgImg from "../../assets/images/section/orgImg.svg";
import UserTabs from "../UserTabs";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Banner from "../../assets/images/section/defaultBG.png";
import { ReactComponent as WalletIcon } from "../../assets/images/icons/wallet.svg";
import { ReactComponent as WalletIconInBlue } from "../../assets/images/icons/walletBlue.svg";
import { ReactComponent as Setting } from "../../assets/images/icons/setting.svg";
import { SET_PLATFORM_SETTINGS_LISTING } from "../../store/constants";
import { listPlatformSettings } from "../../services/settings.service";

import { useWeb3Modal } from "@web3modal/wagmi/react";
import { setWallet } from "../../services/auth.service";
import { thunkLogin } from "../../store/_actions/user_action";
import { alertMessage } from "../../constants/alertMessage.js";
import { FaLink } from "react-icons/fa";
import NotificationAlert from "../../components/Alert";
import { FiAlertCircle } from "react-icons/fi";
import { getMintedNftCount } from  "../../services/nft.service";


const UserHistory = () => {
  const auth = useSelector((state) => state.auth);
  const { connectedAddress } = useSelector((state) => state.wallet);
  const [openWallet, setOpenWallet] = useState(false);
  const [mintedNftCount, setMintedNftCount] = useState(null);
  const dispatch = useDispatch();
  const { open } = useWeb3Modal();
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const copyToClipboard = () => {
    const baseUrl = new URL(window.location.href).origin;
    const profileUrl = baseUrl + `/user/${auth?.username}`;

    navigator.clipboard.writeText(profileUrl).then(() => {
      setTooltipVisible(true);
      setTimeout(() => {
        setTooltipVisible(false);
      }, 2000);
    });
  };
  const getPlatformSettings = async () => {
    const response = await listPlatformSettings();
    if (response?.data?.success) {
      dispatch({
        type: SET_PLATFORM_SETTINGS_LISTING,
        payload: response?.data?.data,
      });
    }
  };
  const getMintedNftCountData = async () => {
    const response = await getMintedNftCount(auth?.walletAddress);
    if (response?.data?.success) {
      setMintedNftCount(response?.data?.data)
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (!auth.email) {
      navigate("/login");
    }
    getPlatformSettings();
    getMintedNftCountData();
  }, [auth]);

  const handleSplitNonprofitName = (nonProfitName) => {
    const displayText =
      nonProfitName && nonProfitName.length > 50
        ? nonProfitName.slice(0, 50) + "..."
        : nonProfitName;

    return displayText;
  };
  useEffect(() => {
    if (
      connectedAddress &&
      (!auth?.walletAddress || auth?.walletAddress === "undefined") &&
      auth?.username
    ) {
      updateUserWalletAddress();
    }
  }, [connectedAddress]);

  useEffect(() => {
    if (auth?.walletAddress && connectedAddress !== auth?.walletAddress) {
      setIsAlertVisible(true);  
    } else {
      setIsAlertVisible(false); 
    }
  }, [auth?.walletAddress, connectedAddress]);

  const updateUserWalletAddress = async () => {
    const authToken = localStorage.getItem("authenticationToken");
    let requestData = {
      email: auth?.email,
      walletAddress: connectedAddress,
      authToken,
    };
    const response = await setWallet(requestData);
    if (response?.data?.success === true) {
      dispatch(
        thunkLogin(response?.data?.data, () => {
          alertMessage("success", `${response?.data?.message}`);
        })
      );
    } else {
      alertMessage("error", `${response?.data?.message}`);
    }
  };
  const formatWalletAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 8)}...${address.slice(-8)}`;
  };

  return (
    <div className={`${Classes.history}`}>
      <div
        className={Classes.myBannerImg}
        style={{ backgroundImage: `url(${auth?.bannerImage || Banner})` }}
      ></div>

      <div className={`${Classes.userDetails}`}>
        <Container>
          <>
            <div className={`${Classes.userInfo}`}>
              {auth?.role === "user" ? (
                <div className="d-flex align-items-md-center gap-md-3 gap-1 flex-sm-row flex-column">
                  <div className={Classes.userImg}>
                    {auth?.profilePicture ? (
                      <img src={auth?.profilePicture} alt="img" />
                    ) : (
                      <span>{auth?.firstName[0]}</span>
                    )}
                  </div>
                  <div className="pt-sm-5 pt-0 w-100">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <h3 className={Classes.userName}>
                        {auth?.username}
                      </h3>
                    </div>
                    <div className="d-flex align-items-lg-center align-items-start justify-content-between gap-3 w-100 flex-lg-row flex-column pt-md-0 pt-3">
                      <div
                        className={`${Classes.socialIcons} d-flex align-items-center flex-sm-row flex-column`}
                      >
                        <div
                          className={`${Classes.outer} d-flex align-items-center justify-content-sm-start justify-content-between gap-4 py-2 pe-sm-4 pe-0 me-sm-4 me-0`}
                        >
                          {auth?.socialMedia?.facebook && (
                            <Tooltip title="Facebook">
                              <a
                                href={auth?.socialMedia?.facebook}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon7} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.youtube && (
                            <Tooltip title="YouTube">
                              <a
                                href={auth?.socialMedia?.youtube}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon8} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.linkedIn && (
                            <Tooltip title="LinkedIn">
                              <a
                                href={auth?.socialMedia?.linkedIn}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon9} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.discord && (
                            <Tooltip title="Discord">
                              <a
                                href={auth?.socialMedia?.discord}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon3} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.tiktok && (
                            <Tooltip title="TikTok">
                              <a
                                href={auth?.socialMedia?.tiktok}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon6} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.website && (
                            <Tooltip title="Website">
                              <a
                                href={auth?.socialMedia?.website}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon2} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.instagram && (
                            <Tooltip title="Instagram">
                              <a
                                href={auth?.socialMedia?.instagram}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon4} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.twitter && (
                            <Tooltip title="Twitter">
                              <a
                                href={auth?.socialMedia?.twitter}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon5} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                        </div>
                        <button
                          className={Classes.linkBtn}
                          onClick={copyToClipboard}
                        >
                          <div className={`d-flex align-items-center gap-2`}>
                            <FaLink />
                            <p>
                              {tooltipVisible ? "Copied!" : "Copy profile link"}
                            </p>
                          </div>
                        </button>
                      </div>
                      <div className={`${Classes.profileButtons} mt-2`}>
                        <button
                          onClick={() => {
                            if (!connectedAddress) {
                              open({ view: "Networks" });
                            }
                          }}
                          className={`${
                            connectedAddress && auth?.walletAddress
                              ? Classes.whiteButton
                              : Classes.blueButton
                          }  d-flex align-items-center gap-1`}
                        >
                          {connectedAddress && auth?.walletAddress ? (
                            <WalletIconInBlue />
                          ) : (
                            <WalletIcon />
                          )}
                          <span className={Classes.connectBtn}>
                            {connectedAddress && auth?.walletAddress ? (
                              connectedAddress.slice(0, 3) +
                              "..." +
                              connectedAddress.slice(-3)
                            ) : (
                              <>
                                <span className={Classes.authBtn}>
                                  Connect wallet
                                </span>{" "}
                              </>
                            )}
                          </span>
                          {/* <WalletConnectionModal
                      open={openWallet}
                      setOpen={setOpenWallet}
                      connectedAddress={connectedAddress}
                    /> */}
                        </button>
                        <Link
                          to="/setting"
                          className={`${Classes.blueButton} ${Classes.setting} d-flex align-items-center gap-2`}
                        >
                          <Setting className={Classes.settingIcon} />
                          Setting
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="d-flex align-items-md-center gap-md-3 gap-1 flex-sm-row flex-column">
                  <div className={Classes.orgImg}>
                    <img src={auth?.profilePicture || OrgImg} alt="imgs" />
                  </div>
                  <div className="pt-sm-5 pt-0 w-100">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <h3 className={Classes.userName}>
                        {auth?.role === "organization" ? (
                          <>
                            {auth?.nonProfitName?.length > 50 ? (
                              <>
                                <Tooltip title={auth?.nonProfitName}>
                                  {handleSplitNonprofitName(
                                    auth?.nonProfitName
                                  )}
                                </Tooltip>
                              </>
                            ) : (
                              <>{auth?.nonProfitName}</>
                            )}
                          </>
                        ) : (
                          <>{`${auth?.firstName} ${auth?.lastName}`}</>
                        )}
                      </h3>
                      {auth?.verificationStatus === "approved" && (
                        <img
                          className={Classes.varified}
                          src={check}
                          alt="icon"
                        />
                      )}
                    </div>
                    {/* <div className="d-flex align-items-center justify-content-between"> */}
                    {/* <div className="d-flex align-items-center gap-4"> */}
                    {auth?.organizationType &&
                    auth?.organizationType.includes("fiscal sponsor") &&
                    (!auth?.verificationStatus ||
                      auth?.verificationStatus === "disapproved" ||auth?.verificationStatus === "pending") ? (
                      <div className={Classes.metaInfo}>
                        Nonprofit pending approval by fiscal sponsor
                      </div>
                    ) : auth?.organizationType ? (
                      <div className={Classes.metaInfo}>
                        {auth?.organizationType ===
                        "Nonprofit seeking a fiscal sponsor or 501(c)(3) status"
                          ? "Nonprofit with 501(c)(3) fiscal sponsor"
                          : auth?.organizationType}
                      </div>
                    ) : (
                      ""
                    )}

                    <div className="d-flex align-items-lg-center align-items-start justify-content-between gap-3 w-100 flex-lg-row flex-column pt-md-0 pt-3">
                      <div
                        className={`${Classes.socialIcons} d-flex align-items-center flex-sm-row flex-column mt-2`}
                      >
                        <div
                          className={`${Classes.outer} d-flex align-items-center justify-content-sm-start justify-content-between gap-4 py-2 pe-sm-4 pe-0 me-sm-4 me-0`}
                        >
                          {auth?.socialMedia?.facebook && (
                            <Tooltip title="Facebook">
                              <a
                                href={auth?.socialMedia?.facebook}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon7} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.youtube && (
                            <Tooltip title="YouTube">
                              <a
                                href={auth?.socialMedia?.youtube}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon8} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.linkedIn && (
                            <Tooltip title="LinkedIn">
                              <a
                                href={auth?.socialMedia?.linkedIn}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon9} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.discord && (
                            <Tooltip title="Discord">
                              <a
                                href={auth?.socialMedia?.discord}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon3} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.tiktok && (
                            <Tooltip title="TikTok">
                              <a
                                href={auth?.socialMedia?.tiktok}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon6} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.website && (
                            <Tooltip title="Website">
                              <a
                                href={auth?.socialMedia?.website}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon2} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.instagram && (
                            <Tooltip title="Instagram">
                              <a
                                href={auth?.socialMedia?.instagram}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon4} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                          {auth?.socialMedia?.twitter && (
                            <Tooltip title="Twitter">
                              <a
                                href={auth?.socialMedia?.twitter}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img src={icon5} alt="icon" />
                              </a>
                            </Tooltip>
                          )}
                        </div>
                        <button
                          className={Classes.linkBtn}
                          onClick={copyToClipboard}
                        >
                          <div className={`d-flex align-items-center gap-2`}>
                            <FaLink />
                            <p>
                              {tooltipVisible ? "Copied!" : "Copy profile link"}
                            </p>
                          </div>
                        </button>
                      </div>
                      <div className={`${Classes.profileButtons} mt-2`}>
                        <button
                          onClick={() => {
                            if (!connectedAddress) {
                              open({ view: "Networks" });
                            }
                          }}
                          className={`${
                            connectedAddress && auth?.walletAddress
                              ? Classes.whiteButton
                              : Classes.blueButton
                          }  d-flex align-items-center gap-1`}
                        >
                          {connectedAddress && auth?.walletAddress ? (
                            <WalletIconInBlue />
                          ) : (
                            <WalletIcon />
                          )}
                          <span className={Classes.connectBtn}>
                            {connectedAddress && auth?.walletAddress ? (
                              connectedAddress.slice(0, 3) +
                              "..." +
                              connectedAddress.slice(-3)
                            ) : (
                              <>
                                <span className={Classes.authBtn}>
                                  Connect wallet
                                </span>{" "}
                              </>
                            )}
                          </span>
                          {/* <WalletConnectionModal
                      open={openWallet}
                      setOpen={setOpenWallet}
                      connectedAddress={connectedAddress}
                    /> */}
                        </button>
                        <Link
                          to="/setting"
                          className={`${Classes.blueButton} ${Classes.setting} d-flex align-items-center gap-2`}
                        >
                          <Setting className={Classes.settingIcon} />
                          Setting
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {auth?.organizationType ===
              "Nonprofit with 501(c)(3) fiscal sponsor" && mintedNftCount === 0 && (
              <div className={Classes.metaInfoInRed}>
                <FiAlertCircle />
                <span>
                To automatically add your organization to the master list of nonprofits qualified to accept donations, please create and mint one NFT.
                </span>
              </div>
            )}

            {/* <div>
              <ul
                className={`${Classes.items} d-flex align-items-md-center align-items-start gap-lg-5 gap-4 p-0 flex-md-row flex-column`}
              >
                <li>
                  <span className={Classes.item}>Items</span>
                  <b className={Classes.value}>10.2K</b>
                </li>
                <li>
                  <span className={Classes.item}>Created</span>
                  <b className={Classes.value}>Oct 2023</b>
                </li>
                <li>
                  <span className={Classes.item}>Creator earnings </span>
                  <b className={Classes.value}>3.33%</b>
                </li>
                <li>
                  <span className={Classes.item}>Chain </span>
                  <b className={Classes.value}>Polygon</b>
                </li>
                <li>
                  <span className={Classes.item}>Category </span>
                  <b className={Classes.value}>PFPs</b>
                </li>
              </ul>
              <p className={Classes.desc}>{auth?.bio}</p>
            </div> */}
          </>
          <UserTabs />
        </Container>
      </div>
      {auth?.walletAddress && connectedAddress !== auth?.walletAddress && (
        <NotificationAlert
          title="Wallet Needs Reconnection"
          content={`Please go to your profile and re-connect your wallet to ${formatWalletAddress(auth?.walletAddress)}.`}
          visible={isAlertVisible}
          onClose={() => setIsAlertVisible(false)}
        />
      )}
    </div>
  );
};

export default UserHistory;
