// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import CONTRACT_DETAILS from "../contract/contractInfo";
const marketContractAddress = CONTRACT_DETAILS.market.address;
const marketplaceABI = CONTRACT_DETAILS.market.abi;
const useListNftForUser = () => {
  //approve contract

  const {
    isError: isListNftUserSentError,
    data: listNftUserSentTx,
    isLoading: isListNftUserTxInProgress,
    isSuccess: isListNftUserTxSent,
    write: listNftForUser,
    error: listNftUserSentError
  } = useContractWrite({
    address: marketContractAddress,
    abi: marketplaceABI,
    functionName: "listForUsers",
  });
  const {
    isLoading: isListNftUserWaiting,
    isSuccess: isListNftUserCompleted,
    isError: isListNftUserError,
    error: listNftUserTxError,
    data: listNftUserResp
  } = useWaitForTransaction({
    hash: listNftUserSentTx?.hash,
  });

  return {
    //nft operation
    isListNftUserSentError,
    listNftUserSentTx,
    isListNftUserTxInProgress,
    isListNftUserTxSent,
    listNftForUser, // Renamed the function
    listNftUserSentError,
    isListNftUserWaiting,
    isListNftUserCompleted,
    isListNftUserError,
    listNftUserTxError,
    listNftUserResp
  };
};

export default useListNftForUser;
