import React, { useEffect, useState } from "react";
import Classes from "./cause.module.scss";
import { Container } from "react-bootstrap";
import SearchBtn from "../../assets/images/icons/searchBtn.svg";
import Slider from "react-slick";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../store/_actions/categories_actions";
import { Link, useNavigate } from "react-router-dom";

const Cause = () => {
  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const [filterCategories, setFilterCategories] = useState("");
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    arrows: true,
    autoplay: false,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1399,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const { categories } = useSelector((state) => state?.categories);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getCategories());
  }, []);

  const handleChange = (e) => {
    setFilterCategories(e.target.value);
  };

  const filteredData =
    categories &&
    categories?.filter((item) =>
      item.categoryName.toLowerCase().includes(filterCategories.toLowerCase())
    );

  return (
    <div className={Classes.cause}>
      <Container>
        <div
          className={`${Classes.top} d-flex gap-3 mb-4`}
        >
          <h1 className={Classes.heading}>Find A Cause</h1>
          <div className={`${Classes.bottom} d-flex align-items-center gap-3`}>
            {/* <div className={Classes.searchBtn}>
              <img src={SearchBtn} alt='icon' />
              <input
                type='text'
                placeholder='Search...'
                onChange={handleChange}
              />
            </div> */}
            <Link className={Classes.seeAllBn} to={"/edit-cause"}>
              See More
            </Link>
          </div>
        </div>
        <div className={Classes.slider}>
          <Slider {...settings}>
            {filteredData &&
              filteredData?.map((category, index) => (
                <div className={Classes.causeSlider} index={index}>
                  <img
                    className={Classes.img}
                    src={category?.categoryImage}
                    alt="img"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      auth?.email ? navigate("/edit-cause") : navigate("/login")
                    }
                  />
                  <h2 className={Classes.txt}>{category?.categoryName}</h2>
                </div>
              ))}
          </Slider>
        </div>
      </Container>
    </div>
  );
};

export default Cause;
