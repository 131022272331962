import React, { useState, useEffect } from "react";
import Classes from "./userDetail.module.scss";
import { Container } from "react-bootstrap";
import { Col, Row } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Check from "../../assets/images/icons/check_circle.svg";
import { alertMessage } from "../../constants/alertMessage";
import EinVerificationModal from "./EinVerificationModal";
import FiscalSponsorConsentModal from "./FiscalSponsorConsentModal";

const accountTypes = [
  {
    id: 1,
    key: "Registered 501(c)(3) nonprofit with IRS",
    name: "I represent a registered 501(c)(3) nonprofit with the IRS in the United States.",
  },
  {
    id: 2,
    key: "Nonprofit with 501(c)(3) fiscal sponsor",
    name: "I represent a nonprofit with a 501(c)(3) fiscal sponsor.",
  },
  {
    id: 3,
    key: "Nonprofit seeking a fiscal sponsor or 501(c)(3) status",
    name: "I represent a nonprofit seeking a fiscal sponsor or 501(c)(3) status.",
  },
];
const UserDetail = () => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [einSubHeading, setEinSubHeading] = useState("");
  const [organizationType, setOrganizationType] = useState("");
  const [accountType, setAccountType] = useState("");
  const [irsOrganizationData, setIrsOrganizationData] = useState("");
  const [isModalConsent, setIsModalConsent] = useState(false);
  const [isModalAgreement, setIsModalAgreement] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAccountId, setIsAccountId] = useState(0);
  const [modalTitle, setModalTitle] = useState("");
  const [isModalDefaultSponsor,setIsModalDefaultSponsor]=useState(false)
  const [showExistingEinError, setShowExistingEinError] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const handleOptionClick = (option) => {
    setSelectedOption(option);
  };

  useEffect(() => {
    if (!auth?.email) {
      navigate("/login");
      alertMessage("error", "Authentication required.");
    }
    if (auth?.username && auth?.email) {
      navigate("/profile");
    }
  }, []);

  const data = {
    organizationType: organizationType,
    accountType: accountType,
    role: location?.state?.role,
    irsOrganizationData,
    address: location.state?.address,
  };
  const handleClickNext = () => {
    if (!selectedOption) {
      alertMessage("error", "Select organization type fist.");
      return false;
    }
    navigate("/build-organization-profile", {
      state: {
        organizationType: organizationType,
        accountType: accountType,
        role: location?.state?.role,
        irsOrganizationData,
        address: location.state?.address,
      },
    });
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setModalTitle("");
    setIsModalOpen(false);
    setIsAccountId(0);
    setShowExistingEinError(false)
  };

  const showConsentModal = () => {
    setIsModalConsent(true);
  };
  
  const handleConsentCancel = () => {
    setIsModalConsent(false);
    setIsAccountId(0);
  };

  const showAgreementModal = () => {
    setIsModalAgreement(true);
  };

  const handleAgreementCancel = () => {
    setIsModalAgreement(false);
    setIsAccountId(0);
  };

  useEffect(() => {
    if (isAccountId === 1) {
      setAccountType("");
      setModalTitle("Enter EIN number");
      setEinSubHeading("Is this your nonprofit organization?");
      showModal();
    }

    if (isAccountId === 2) {
      sessionStorage.removeItem('fiscalSponsorEmailAddress');
      setAccountType("fiscalSponsor");
      setOrganizationType(accountTypes[1].key);
      setModalTitle("Please enter your fiscal sponsor's EIN");
      setEinSubHeading("Is your fiscal sponsor the following 501(c)(3) nonprofit?");
      showModal();
    }

    if (isAccountId === 3) {
      sessionStorage.removeItem('fiscalSponsorEmailAddress');
      setAccountType("defaultFiscalSponsor");
      setOrganizationType(accountTypes[2].key);
      // setModalTitle("Please enter your fiscal sponsor's EIN");
      showConsentModal();
      // showAgreementModal();
    }
  }, [isAccountId]);

  return (
    <div className={Classes.userDetail}>
      <Container>
        <div
          className={`${Classes.mainOuter} d-flex justify-content-center align-items-center flex-column`}
        >
          <h1 className={Classes.title}>Charity NFT</h1>
          <div className={Classes.content}>
            <div className="mb-4">
              <h2 className={Classes.subTitle}>Choose one of the following that describes you and your nonprofit:</h2>
              <p className={Classes.desc}>Let’s get started!</p>
            </div>
            <Row gutter={[20, 20]} className="mb-4">
              <Col md={16}>
                {accountTypes &&
                  accountTypes?.map((accountType) => (
                    <div
                      className={`${Classes.infoOuter} ${
                        selectedOption === accountType?.id
                          ? Classes.selected
                          : ""
                      }`}
                      onClick={() => {
                        handleOptionClick(accountType?.id);
                        setOrganizationType(accountType?.key);
                        setIsAccountId(accountType?.id);
                      }}
                    >
                      <p className={Classes.txt}>{accountType?.name}</p>
                      <img className={Classes.check} src={Check} alt="img" />
                    </div>
                  ))}
              </Col>
              <Col lg={8} md={24} sm={24} xs={24}>
                <div className={Classes.rightSection}>
                  <div className="mb-3">
                    <h2 className={`${Classes.heading} mb-1`}>
                      501(c)(3) Exempt Purposes
                    </h2>
                    <p className={Classes.description}>
                      Charitable, religious, educational, scientific, literary,
                      testing for public safety, fostering national or
                      international amateur sports competition, and preventing
                      cruelty to children or animals.
                    </p>
                  </div>
                  <div>
                    <h2 className={`${Classes.heading} mb-1`}>
                      Fiscal Sponsors
                    </h2>
                    <p className={Classes.description}>
                      Fiscal sponsors support nonprofit projects that are not
                      registered as 501(c)(3) entities with the IRS. Donors make
                      tax-deductible contributions to the nonprofits through the
                      fiscal sponsors.
                      {/* Social Welfare, Labor and Agriculture, Business Leagues
                      and Chambers of Commerce. */}
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <div className='d-flex justify-content-end'>
              <button
                onClick={() => handleClickNext()}
                className={Classes.blueBtn}
              >
                Next
              </button>
            </div> */}
          </div>
        </div>
      </Container>
      <EinVerificationModal
        setSelectedOption={setSelectedOption}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        setIrsOrganizationData={setIrsOrganizationData}
        setModalTitle={setModalTitle}
        modalTitle={modalTitle}
        accountType={accountType}
        einSubHeading={einSubHeading}
        handleClickNext={handleClickNext}
        consentState={data}
        setShowExistingEinError={setShowExistingEinError}
        showExistingEinError={showExistingEinError}
      />
      <FiscalSponsorConsentModal
        isModalConsent={isModalConsent}
        handleConsentCancel={handleConsentCancel}
        showAgreementModal={showAgreementModal}
        consentState={data}
      />
    </div>
  );
};

export default UserDetail;
