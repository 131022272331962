import axios from "axios";

export const friendsList = async (interests,limit) => {
  try {
    const friendsList = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user/friends-list`,
      {
        interestedCategories: interests,
        limit
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authenticationToken")}`,
        },
      }
    );
    return friendsList;
  } catch (error) {
    return error.response.data;
  }
};

export const addNewFriend = async (data) => {
  try {
    const addFriend = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user/send-friend-request`,
      {
        friendId: data?.friendId,
        userId: data?.userId,
        requestStatus: data?.requestStatus,
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authenticationToken")}`,
        },
      }
    );
    return addFriend;
  } catch (error) {
    return error.response.data;
  }
};

export const friendRequestsList = async (token) => {
  try {
    const friendRequests = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/user/friend-reuqests`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return friendRequests;
  } catch (error) {
    return error.response.data;
  }
};

export const respondFriendRequest = async (data) => {
  try {
    const friendRequests = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/user/respond-friend-request`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("authenticationToken")}`,
        },
      }
    );
    return friendRequests;
  } catch (error) {
    return error.response.data;
  }
};
