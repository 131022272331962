import React from 'react';
import Header from '../../layout/LandingLayout/Header';
import SettingComp from '../../SettingComp';

const Settings = () => {
  return (
    <>
      <Header />
      <SettingComp />
    </>
  );
};

export default Settings;
