import axios from "axios";

const authenticationToken = localStorage.getItem("authenticationToken");

export const nftListings = async (token) => {
  try {
    const pinataResponse = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/nfts/favorites`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticationToken}`,
        },
      }
    );
    return pinataResponse;
  } catch (error) {
    return error.response.data;
  }
};

export const nftListing = async (walletAddress, userId) => {
  try {
    const authenticationToken = localStorage.getItem("authenticationToken");
    const pinataResponse = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/nfts/user/${walletAddress}/${userId}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticationToken}`,
        },
      }
    );
    return pinataResponse;
  } catch (error) {
    console.log(error,'error=>')
    return error.response.data;
  }
};

export const processNftToPinata = async (data) => {
  try {
    const authenticationToken = localStorage.getItem("authenticationToken");

    const formData = new FormData();
    formData.append("name", data?.name);
    formData.append("external_link", data?.external_link);
    formData.append("description", data?.description);
    formData.append("royaltyFee",data?.royaltyFee);
    formData.append("supply",data?.supply)
    formData.append("creatorAddress",data?.creatorAddress)

    // Append the file if it exists
    if (data?.file) {
      formData.append("file", data.file);
    }
    const pinataResponse = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/nfts/pinata-upoload-nft`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${authenticationToken}`,
        },
      }
    );
    return pinataResponse;
  } catch (error) {
    return error.response.data;
  }
};

export const saveNFT = async (data) => {
  try {
    const authenticationToken = localStorage.getItem("authenticationToken");
    const nft = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/nfts/mint-nft`,
      data,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authenticationToken}`,
        },
      }
    );
    return nft;
  } catch (error) {
    return error.response.data;
  }
};
export const getMintedNftCount = async(address) =>
{
  const response = await axios.get(
    `${process.env.REACT_APP_API_BASE_URL}/nfts/minted-nft-count/${address}`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authenticationToken}`,
      },
    }
  );
  return response
};