import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import PublicHeader from "../../layout/LandingLayout/Header/PublicHeader";
import PublicUserHistory from "../../UserHistory/PublicUserHistory";
import { alertMessage } from "../../../constants/alertMessage";
import { findNonProfit } from "../../../services/auth.service";
import { useSelector } from "react-redux";

const UserProfileView = () => {
  const [organization, setOrganization] = useState("");
  const { organizationName } = useParams();
  const auth = useSelector((state) => state.auth);
 const navigate= useNavigate()
  const handleReportNonProfit = async () => {
    try {
      const response = await findNonProfit(organizationName);

      if (response?.data?.status === 200) {
        setOrganization(response?.data?.data);
      }else{
        setOrganization(response);
      }
    } catch (error) {
      console.log(error);
      alertMessage("error", `${error}`);
    }
  };

  useEffect(() => {
    handleReportNonProfit();
  }, []);
  useEffect(() => {
    const {username,id } = auth||{};
    // if (!username &&!id) {
    //   navigate("/login");
    // }else 
    if(!username &&id){
      navigate("/get-started");
    }
  }, [auth]);
  return (
    <>
      <PublicHeader />
      <PublicUserHistory organization={organization} />
    </>
  );
};

export default UserProfileView;
