import React from "react";
import { Modal } from "antd";
import Classes from "./tabs.module.scss";
import { alertMessage } from "../../constants/alertMessage";
import { deactivateFiscalProject } from "../../services/auth.service";
import { useSelector, useDispatch } from "react-redux";
import { thunkLogin } from "../../store/_actions/user_action";

const DeactivateFiscalProjectModal = ({
  isModalOpen,
  handleCancel,
  fiscalProjectId,
}) => {
  const auth = useSelector((state) => state?.auth);
  const authToken = localStorage.getItem("authenticationToken");

  const dispatch = useDispatch();

  const handleDeactivateFiscalProject = async () => {
    try {
      const data = { fiscalProjectId, authToken };
      const projects = await deactivateFiscalProject(data);

      auth.fiscalProjects = auth.fiscalProjects.filter(
        (project) => project._id !== fiscalProjectId
      );
      if (projects?.success) {
        dispatch(
          thunkLogin(auth, () => {
            alertMessage("success", `${projects?.data?.message}`);
          })
        );
      }
    } catch (error) {
      console.log("Error", Error);
    }
  };

  return (
    <div>
      <Modal
        title="Delete Fiscal Project"
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
        className={Classes.projModal}
      >
        <div className={`${Classes.modalInner}`}>
          <div className={Classes.formInput}>
            <p className={Classes.label}>
              Are you sure you want to deactivate this Fiscal Project ?
            </p>
            <div className="position-relative"></div>
          </div>
        </div>
        <div className={`${Classes.btnDiv} d-flex gap-2 justify-content-between`}>
          <button
            className={`${Classes.myredBtn}`}
            onClick={() => handleCancel()}
          >
            No
          </button>
          <button
            className={`${Classes.myblueBtn}`}
            onClick={() => handleDeactivateFiscalProject()}
          >
            Yes
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DeactivateFiscalProjectModal;
