import React, { useEffect, useState } from 'react';
import { Row, Select } from 'antd';
import { Container } from 'react-bootstrap';
import SearchIcon from '../../../assets/images/icons/light-search.svg';
import FeaturedCard from '../../FeaturedCard';
import FeaturedUserCard from '../../FeaturedCard/FeaturedUserCard';
import { generalFilters } from '../../../services/general.service';
import moment from 'moment';
import LandingLayout from '../../layout/LandingLayout';
import NftsFilters from '../../../components/Filters/NftsFilters';

const Explore = () => {
  const [selectedOption, setSelectedOption] = useState('interest');
  const [filtersData, setFiltersData] = useState();
  const [params, setParams] = useState({ dateFilter: 1, search: '' });

  const handleGeneralFilters = async () => {
    try {
      const response = await generalFilters(params);
      if (response?.data?.data) {
        console.log('response?.data?.data :', response?.data?.data);
        setFiltersData(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleGeneralFilters();
  }, [params.dateFilter]);
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      handleGeneralFilters();
    }, 700);
    return () => clearTimeout(debounceTimeout);
  }, [params.search]);

  const handleChange = (value) => {
    setParams({ ...params, dateFilter: value });
    setSelectedOption(value);
  };

  console.log('filtersData :', filtersData);

  return (
    <>
      <LandingLayout>
        <Container style={{ marginTop: '50px' }}>
          <div className='pt-5'>
            <div
              className={`d-flex align-items-center justify-content-between gap-4 pb-5`}
            >
              <div className={`searchOuter d-flex gap-2`}>
                <img src={SearchIcon} alt='icon' />
                <input
                  className='w-100'
                  type='text'
                  placeholder='Search'
                  onChange={(e) => {
                    setParams({ ...params, search: e.target.value });
                  }}
                />
              </div>
              <div className={`d-flex gap-4`}>
                <Select
                  placeholder='Recently Added'
                  style={{
                    width: 180,
                  }}
                  onChange={handleChange}
                  options={[
                    {
                      value: moment()
                        .subtract(1, 'months')
                        .format('YYYY-MM-DD'),
                      label: 'One Month Ago',
                    },
                    {
                      value: moment()
                        .subtract(2, 'months')
                        .format('YYYY-MM-DD'),
                      label: 'Two Month Ago',
                    },
                    {
                      value: 1,
                      label: 'Recently Added',
                    },
                    {
                      value: -1,
                      label: 'Oldest',
                    },
                  ]}
                />
              </div>
            </div>

            {filtersData && !filtersData?.nfts ? (
              <div style={{ marginBottom: '30px' }}>
                <NftsFilters title={'NFTs'} data={filtersData} />
              </div>
            ) : (
              ''
            )}

            {filtersData?.nfts ? (
              <div>
                <NftsFilters title={'Filtered NFTs'} data={filtersData?.nfts} />
              </div>
            ) : (
              ''
            )}

            {filtersData?.collections ? (
              <div style={{ marginTop: '30px' }}>
                <h1 className='mb-4 filters_main_heading'>Collections</h1>
                <Row gutter={[20, 20]}>
                  {filtersData?.collections &&
                    filtersData?.collections?.length > 0 &&
                    filtersData?.collections?.map((collection) => {
                      return <FeaturedCard data={collection} />;
                    })}
                </Row>
              </div>
            ) : (
              ''
            )}
            {filtersData?.users ? (
              <div>
                <h1 className='mb-4 filters_main_heading'>Users</h1>
                <Row gutter={[20, 20]}>
                  {filtersData?.users &&
                    filtersData?.users?.length > 0 &&
                    filtersData?.users.map((user) => {
                      return <FeaturedUserCard data={user} />;
                    })}
                </Row>
              </div>
            ) : (
              ''
            )}
          </div>
        </Container>
      </LandingLayout>
    </>
  );
};

export default Explore;
