import React from 'react';
import SignUpComponent from '../../SignUp';

function SignUp() {
  return (
    <>
      <SignUpComponent />
    </>
  );
}

export default SignUp;
