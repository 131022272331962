import React, { useEffect, useState } from 'react';
import HeroSection from '../../HeroSection';
import { Row, Select } from 'antd';
import { Container } from 'react-bootstrap';
import SearchIcon from '../../../assets/images/icons/light-search.svg';
import { useSelector } from 'react-redux';
import FeaturedCard from '../../FeaturedCard';
import { allCollectionsListing } from '../../../services/collections.service';
import moment from 'moment';
import LandingLayout from '../../layout/LandingLayout';
import { useNavigate } from "react-router-dom";
function Collection() {
  const [selectedOption, setSelectedOption] = useState('interest');
  const [collections, setCollections] = useState();
  const [params, setParams] = useState({ dateFilter: -1, search: '' });
  const auth = useSelector((state) => state.auth);
  const navigate=useNavigate()
  const getCollections = async () => {
    try {
      const response = await allCollectionsListing(params);
      if (response?.data?.data) {
        setCollections(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const {username,id } = auth||{};
    if (!username &&!id) {
      navigate("/login");
    }else if(!username &&id){
      navigate("/get-started");
    }
  }, [auth]);
  useEffect(() => {
    getCollections();
  }, [params.dateFilter]);
  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      getCollections();
    }, 700);
    return () => clearTimeout(debounceTimeout);
  }, [params.search]);

  const handleChange = (value) => {
    setParams({ ...params, dateFilter: value });
    setSelectedOption(value);
  };
  console.log(params, 'params==>');
  return (
    <>
      <LandingLayout>
        <HeroSection />
        <Container>
          <div className='pt-5'>
            <h1 className='mb-4 main_heading'>Collections</h1>
            <div
              className={`d-flex align-items-center justify-content-between gap-4 pb-5`}
            >
              <div className={`searchOuter d-flex gap-2`}>
                <img src={SearchIcon} alt='icon' />
                <input
                  className='w-100'
                  type='text'
                  placeholder='Search'
                  onChange={(e) => {
                    setParams({ ...params, search: e.target.value });
                  }}
                />
              </div>
              <div className={`d-flex gap-4`}>
                <Select
                  placeholder='Recently Added'
                  style={{
                    width: 180,
                  }}
                  onChange={handleChange}
                  options={[
                    {
                      value: moment()
                        .subtract(1, 'months')
                        .format('YYYY-MM-DD'),
                      label: 'One Month Ago',
                    },
                    {
                      value: moment()
                        .subtract(2, 'months')
                        .format('YYYY-MM-DD'),
                      label: 'Two Month Ago',
                    },
                    {
                      value: -1,
                      label: 'Recently Added',
                    },
                    {
                      value: 1,
                      label: 'Oldest',
                    },
                  ]}
                />
              </div>
            </div>
            <div>
              <Row gutter={[20, 20]}>
                {collections &&
                  collections?.length > 0 &&
                  collections.map((collection) => {
                    return <FeaturedCard data={collection} />;
                  })}
              </Row>
            </div>
          </div>
        </Container>
      </LandingLayout>
    </>
  );
}

export default Collection;
