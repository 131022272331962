import { useEffect } from 'react';
import { setConnectedWalletAddress, setWalletAddressError } from '../store/_actions/wallet_action';
import { useDispatch,useSelector } from 'react-redux';
import { useAccount } from "wagmi";

export const useListenWalletAddress = () => {
  const { address: walletAddress } = useAccount();
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useEffect(()=>{
    if(auth?.walletAddress&&(auth?.walletAddress!==walletAddress)&&auth?.username){
      dispatch(setWalletAddressError(true))
      return dispatch(setConnectedWalletAddress(null));
    }
    dispatch(setConnectedWalletAddress(walletAddress));
  },[walletAddress])
  return {
    currentAddress:walletAddress,
  };
};
