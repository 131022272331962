/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import Classess from "../../scss/Footer.module.scss";
import { Container } from "react-bootstrap";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import Logo from "../../../../assets/images/section/logo.svg";
import { ENV } from "../../../../config";
import { useSelector } from "react-redux";
import Terms from "../../../Terms";

const Footer = () => {
  const authenticationToken = localStorage.getItem("authenticationToken");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const auth = useSelector((state) => state.auth);
  const { username, id } = auth || {};
  return (
    <>
      <footer className={Classess.footerOuter}>
        <div
          className={`${Classess.activity}  ${
            authenticationToken && Classess.joinSection
          }`}
        >
          <Container>
            <div className={Classess.joinNow}>
              <div className="d-flex justify-content-between flex-md-row flex-column gap-3 mb-4">
                <div className={Classess.infoCard}>
                  <h3>Explore or create NFTs</h3>
                  <p>
                    Discover NFTs dedicated to charitable causes across various
                    themes and missions
                  </p>
                </div>
                <div className={Classess.infoCard}>
                  <h3>Buy, sell, and donate NFTs</h3>
                  <p>
                    Easily contribute by selecting an NFTs from your wallet,
                    choosing a cause, and confirming your donation
                  </p>
                </div>
                <div className={Classess.infoCard}>
                  <h3>Track your Impact</h3>
                  <p>
                    Stay informed about the progress and results of your
                    charitable contributions
                  </p>
                </div>
              </div>
              <h1 className="text-center">Join Our Community </h1>
              <p className="text-center">
                The premier Charity NFT Marketplace for the nonprofit community
              </p>
              <Link to="/sign-up" className={Classess.joinBtn}>
                Join Now
              </Link>
            </div>
          </Container>
        </div>
        <div
          className={`${Classess.footer}  ${
            authenticationToken && Classess.loginFooter
          }`}
        >
          <Container>
            <Row gutter={[30, 20]}>
              <Col lg={12} md={24} sm={24} xs={24}>
                <div>
                  <Link className={Classess.logo} to="/">
                    <img src={Logo} alt="logo" />
                  </Link>
                  <p className={Classess.toptxt}>
                  CharityNFT is the premier marketplace for NFTs and crypto collectibles for nonprofit community engagement. Make new friends with common interests. Browse, create, buy, sell, auction, and donate NFTs using CharityNFT today.
                  </p>
                </div>
              </Col>
              <Col lg={6} md={24} sm={24} xs={24}>
                <h2 className={Classess.menuHeading}>My Account</h2>
                <Link
                  className={Classess.menuLink}
                  to={
                    id && !username
                      ? "/get-started"
                      : id && username
                      ? "/profile"
                      : "/login"
                  }
                >
                  Profile
                </Link>
                <Link 
                  className={Classess.menuLink} 
                  to="/profile"
                  state={{ tab: 'collections' }}
                >
                  My Collections
                </Link>
                <Link className={Classess.menuLink} to="/setting">
                  Settings
                </Link>
              </Col>
              <Col lg={6} md={24} sm={24} xs={24}>
                <h2 className={Classess.menuHeading}>Community</h2>
                <Link className={Classess.menuLink} to="/faq">
                  FAQ
                </Link>
                <Link className={Classess.menuLink} to="https://www.youtube.com/@CharityNFTorg" target="_blank">
                  How-to Videos
                </Link>
                <Link
                  className={Classess.menuLink}
                  to="https://www.paypal.com/donate/?hosted_button_id=TPLZJT4CAF78S"
                  target="_blank"
                >
                  Donate
                </Link>
                <Link
                  className={Classess.menuLink}
                  to={ENV.gmailUrl}
                  target="_blank"
                >
                  Contact Us
                </Link>
              </Col>
            </Row>
            <div className={Classess.privacyOuter}>
              <Link to="/term-and-conditions" className={Classess.link}>
                Terms and Conditions
              </Link>
              <Link to="/privacy-policy" className={Classess.link}>
                  Privacy Policy
              </Link>
            </div>
            
            <div>
              <span className={`${Classess.bottomTxt} text-center d-block`}>
                © Copyright 2024 &nbsp;
                <a
                  href="https://www.ifers.org/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Institute for Education, Research, and Scholarships
                </a>
              </span>
            </div>
          </Container>
        </div>
      </footer>
      <Terms
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default Footer;
