import React from 'react';
import Classes from './invite.module.scss';
import { Modal } from 'antd';
import {
  acceptInvitation,
  rejectInvitation,
} from '../../services/invites.service';
import { alertMessage } from '../../constants/alertMessage';

const RespondInviteModal = ({
  message,
  statusType,
  respondRecord,
  getInvitesList,
  isRespondModalOpen,
  handleRespondCancel,
}) => {
  const token = localStorage.getItem('authenticationToken');

  const handleInvite = async () => {
    try {
      const requestData = {
        email: respondRecord?.receiverEmail,
        status: statusType,
        token,
      };
      let message;
      let response;
      if (statusType === 'accepted') {
        response = await acceptInvitation(requestData);
        message = 'Invite accepted successfully';
      }
      if (statusType === 'rejected') {
        response = await rejectInvitation(requestData);
        message = 'Invite rejected successfully';
      }

      if (response?.data?.success) {
        handleRespondCancel();
        getInvitesList();
        alertMessage('success', message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Modal
      open={isRespondModalOpen}
      onCancel={handleRespondCancel}
      footer={null}
      title='Basic Modal'
    >
      <div className={`${Classes.addContent} py-2`}>
        <div className='mb-3'>
          <p className={Classes.label}>{message}</p>
        </div>
        <div className='d-flex justify-content-center align-items-center gap-3'>
          <button
            className={`${Classes.rejectbtn}`}
            onClick={handleRespondCancel}
          >
            No
          </button>
          <button
            className={`${Classes.sendbtn}`}
            onClick={() => handleInvite()}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default RespondInviteModal;
