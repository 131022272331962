import { SET_PLATFORM_SETTINGS_LISTING } from "../constants";

const initialWalletState = {
  platformSettings: null,
};

export const PlatformSettingsReducer = (state = initialWalletState, action) => {
  switch (action.type) {
    case SET_PLATFORM_SETTINGS_LISTING:
      return {
        ...state,
        platformSettings: action.payload,
      };
    default:
      return state;
  }
};
