import React from "react";
import Classes from "../Invite/invite.module.scss";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";

const DefaultFiscalSponsorModal = ({
    isModalDefaultSponsor,
    data
//   handleConsentCancel,
}) => {
  const navigate = useNavigate();

  return (
    <Modal
      title="FISCAL SPONSORSHIP"
      open={isModalDefaultSponsor}
    //   onCancel={handleConsentCancel}
      footer={null}
    >
      <>
        <h3 className="mb-4">
          <b>Thank you for your application. You will receive an email shortly. Meanwhile, please continue to create your profile and explore the Charity NFT Marketplace.</b>
        </h3>
        <div className="justify-content-center align-items-center gap-3">
          <div
            className={`${Classes.addContent} d-flex justify-content-center align-items-center gap-3`}
          >
            <button
              className={`${Classes.sendbtn}`}
              onClick={() =>  navigate("/build-organization-profile", { state: { fiscalSponsorAgreementData:data } })}
            >
              OK
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default DefaultFiscalSponsorModal;
