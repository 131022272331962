import React, { useEffect, useState, useContext } from "react";
import { Modal,  } from "antd";
import Classes from "../ApprovedPurchase/modal.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { placeBet } from "../../store/_actions/bet_actions";
import SocketContext from "../../context/socket";
import { alertMessage } from "../../constants/alertMessage";
import {
  HIDE_PLACE_BET_LOADER,
  SHOW_PLACE_BET_LOADER,
} from "../../store/constants";
import { decimalnumbervalidator,CHAIN_ID } from "../../helpers/common";
import usePlaceBidForUser from "../../hooks/usePlaceBid";
import CONTRACT_DETAILS from "../../contract/contractInfo";
import { ethers } from "ethers";
import { completeTx,checkPendingBids,deleteFailedBidFromDb, saveErrorLog } from "../../services/transaction.service";
import { decodeExample } from "../../helpers/ether";
import { FaVideo } from "react-icons/fa";
import NotificationAlert from "../../components/Alert";
import { useBalance,useNetwork } from 'wagmi';
import moment from "moment";
import SuccessModal from "../../components/CreateNftComp/SuccessModal";

const MakeOffer = ({
  isModalOpen,
  handleCancel,
  data,
  message,
  modalType,
  fetchData,
  connectedAddress,
  offers,
  activeAuction,
  price,
  setIsModalOpen
}) => {
  const [offer, setOffer] = useState({ price: "", date: "", time: "" });
  const { isPlacingBid, status } = useSelector((state) => state.bid);
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);
  const { name, file, currentPrice, blockchainType, primaryType } = data;
  const marketContractAddress = CONTRACT_DETAILS.market.address;
  const [showAlertMessage, setShowAlertMessage] = useState('');
  const [showModalTitle, setShowModalTitle] = useState('');
  const [isOfferSuccessModalOpen, setIsOfferSuccessModalOpen] = useState(false);
  const { exchangeRate, polyExchangeRate } = useSelector((state) => state.nft);
  const [isAlertVisible, setIsAlertVisible] = useState(false);
  const { chain } = useNetwork();
  const chainId = CHAIN_ID[process.env.REACT_APP_CONTRACT_ENV];


  const { data: tokenBlnc, isError, isLoading } = useBalance({
    address: connectedAddress,
  });
  const formattedBalance = tokenBlnc?.formatted
    ? parseFloat(tokenBlnc.formatted).toFixed(4)
    : '0';

  const executePendingBids = async()=>{
    const response = await checkPendingBids();
    if(response?.data?.count > 0){
      fetchData()
    }
  }
  useEffect(() => {
    if (status === "success" || status === "failed") {
      dispatch({ type: HIDE_PLACE_BET_LOADER });
    }
  }, [status]);

  useEffect(() => {
    executePendingBids()
    dispatch({ type: HIDE_PLACE_BET_LOADER });
    return () => {
      setOffer({ price: "", date: "", time: "" });
    };
  }, []);

  const showOfferSuccessModal = () => {
    setIsOfferSuccessModalOpen(true);
  };
  const handleOfferSuccessModalCancel = () => {
    setIsOfferSuccessModalOpen(false);
  };

  const createBet = async (txHash) => {
    try {
      const payload = {
        bidBy: JSON.parse(localStorage.getItem("userInfo"))?._id,
        fromAddress: connectedAddress,
        toAddress: data?.ownerAddress || data?.ownerWalletAddress,
        nftId: data?._id,
        bidAmount: Number(offer.price),
        auctionId: activeAuction?._id,
        txHash,
      };
      dispatch(placeBet(payload));
    } catch (err) {
      console.log(err, "error==.");
      dispatch({ type: HIDE_PLACE_BET_LOADER });
    }
  };

  const deleteFailedBid = async (txHash) => {
    try {
      const payload = {
        bidBy: JSON.parse(localStorage.getItem("userInfo"))?._id,
        fromAddress: connectedAddress,
        toAddress: data?.ownerAddress || data?.ownerWalletAddress,
        nftId: data?._id,
        bidAmount: Number(offer.price),
        auctionId: activeAuction?._id,
        txHash,
      };
      const response = await deleteFailedBidFromDb(payload)
      if(response?.data?.success){
        dispatch({ type: HIDE_PLACE_BET_LOADER });
        setIsModalOpen(false)
        fetchData()
      }
    } catch (err) {
      console.log(err, "error==.");
      dispatch({ type: HIDE_PLACE_BET_LOADER });
    }
  };

  const {
    placeBidForUser,
    placeBidUserSentTx,
    placeBidUserSentError,
    placeBidUserTxError,
    placeBidUserResp,
  } = usePlaceBidForUser();
  useEffect(() => {
    if (placeBidUserSentTx?.hash) {
      createBet(placeBidUserSentTx?.hash);
    }
  }, [placeBidUserSentTx]);
  useEffect(() => {
    if (placeBidUserResp) {
      const params = decodeExample(placeBidUserResp?.logs[1]?.data||placeBidUserResp?.logs[0]?.data);
      const _bidNo = params?.param1;
      const payload={
        event : {
          event:"Bided",
          returnValues: {
            _bidNo,
          },
        }
      } 
      const txHash = placeBidUserResp?.transactionHash;
      completeTx(txHash,payload)
    }
  }, [placeBidUserResp]);

  useEffect(() => {
    const error =
    placeBidUserSentError ||
    placeBidUserTxError;

      if ( error?.cause?.name === 'UserRejectedRequestError'){
        handleCancel();
        setShowAlertMessage('User had rejected the transaction.')
        setShowModalTitle('Transaction Cancelled')
        setIsAlertVisible(true);
        dispatch({ type: HIDE_PLACE_BET_LOADER });
        return;
      }  
      if (error?.message?.includes('The total cost (gas * gas fee + value) of executing this transaction exceeds the balance of the account')){
        handleCancel();
        setShowAlertMessage('There is insufficient fund to complete the transaction. Please add funds to your wallet and try again.')
        setShowModalTitle('Insufficient fund');
        setIsAlertVisible(true);
        dispatch({ type: HIDE_PLACE_BET_LOADER });
        return;
      }  
      if (error?.message?.includes('The contract function "startBid" reverted')){
        handleCancel();
        setShowAlertMessage('There is already higher or equal bid exist. Bid price should be greater than previous bid.')
        setShowModalTitle('Transaction Cancelled');
        setIsAlertVisible(true);
        dispatch({ type: HIDE_PLACE_BET_LOADER });
        return;
      } 

      if (error) {
        setShowModalTitle('Unexpected Error')
        setShowAlertMessage('We are unable to complete your request. Please try again later.');
        setIsAlertVisible(true);
        const errorLogData = {
          type: 'Bidding',
          inputPayload: {
          bidPrice: offer?.price,
          sellId: activeAuction?.sellId,
          userdId: JSON.parse(localStorage.getItem("userInfo"))?._id,
          nftId: data?._id,
          auctionId: activeAuction?._id
          },
          responsePayload: {
            cause: error?.cause,
            message: error?.message,
          },
        };
        saveErrorLog(errorLogData);
        dispatch({ type: HIDE_PLACE_BET_LOADER });
        deleteFailedBid(placeBidUserSentTx?.hash);
      }
  }, [
    placeBidUserSentError,
    placeBidUserTxError

  ]);
  const onSubmit = async () => {
    try {
      if(chainId!==chain?.id){
        setIsModalOpen(false);
        setShowModalTitle('Transaction Cancelled')
        setShowAlertMessage(`Please switch your wallet network to ${chainId===97 ? 'Binance Smart Chain Testnet':'Polygon'}.`);
        setIsAlertVisible(true);
        return;
      }
      setShowAlertMessage('')
      if (!offer?.price) {
        alertMessage("error", "Price is required.");
        return;
      }
      if (offer?.price <= offers[0]?.bidAmount) {
        alertMessage("error", "There is already higher or equal bid exist. Bid price should be greater than previous bid.");
        return;
      }
      if(moment()>moment(activeAuction?.sellingConfig?.duration?.endDate)){
        alertMessage("error", "Auction time ended, you are not allowed to place bid.");
        return;
      }
      alertMessage('warning','Please do not close or refresh page during transaction')
      dispatch({ type: SHOW_PLACE_BET_LOADER });
      placeBidForUser({
        args: [activeAuction?.sellId],
        from: connectedAddress,
        value: ethers.utils.parseEther(`${offer?.price}`),
      });
    } catch (err) {
      dispatch({ type: HIDE_PLACE_BET_LOADER });
      console.log(err, "error");
    }
  };
  useEffect(() => {
    if (socket) {
      const userId = JSON.parse(localStorage.getItem("userInfo"))?._id;
      console.log(userId, "userId=>");
      socket?.emit("join", `${userId}`);
      const bidPlaced = () => {
        console.log("bid placed successfullly=>");
        showOfferSuccessModal()
        dispatch({ type: HIDE_PLACE_BET_LOADER });
        fetchData();
        handleCancel();
      };
      socket?.on("bidPlaced", bidPlaced);
      return () => {
        socket?.off("bidPlaced", bidPlaced);
        socket.emit("leave", `${userId}`);
      };
    }
  }, [socket]);

  return (
    <>
    <Modal
      className={Classes.actionModal}
      title="Make an offer"
      open={isModalOpen}
      footer={null}
      onCancel={() => {
        setOffer({ price: "", date: "", time: "" });
        setError(null);
        handleCancel();
      }}
    >
      <div className={`${Classes.modalContent}`}>
        <div className={Classes.topRow}>
          <div className="d-flex align-items-center gap-3">
          {primaryType === "image" ? (
            <div className={`${Classes.preview}`}>
              <img src={file} alt="preview" />
            </div>
          ) : (
            <div className={`${Classes.preview} d-flex align-items-center justify-content-center`}>
              <FaVideo className={Classes.video} />
            </div>
          )}
            <div>
              <p>
                <b>{name}</b>
                {/* <img src={file} /> */}
              </p>
              <p>
                Chain:{" "}
                {blockchainType?.charAt(0).toUpperCase() +
                  blockchainType?.slice(1) || " "}
              </p>
            </div>
          </div>
          <div>
            <p>
              <b>{activeAuction?.sellingConfig?.startPrice?.amount} POL</b>
            </p>
            <p>${(exchangeRate*activeAuction?.sellingConfig?.startPrice?.amount).toFixed(5)}</p>
          </div>
        </div>
        <div className={`${Classes.outer} mt-4`}>
          <div className="d-flex align-items-center justify-content-between mb-2">
            <p>
              <b>Balance</b>
            </p>
            <p>
              <b>{formattedBalance} POL</b>
            </p>
          </div>
          {/* <div className="d-flex align-items-center justify-content-between mb-2">
            <p>
              <b>Floor price</b>
            </p>
            <p>
              <b>1.5 ETHs</b>
            </p>
          </div> */}
          {offers && offers?.length > 0 && (
            <div className="d-flex align-items-center justify-content-between">
              <p>
                <b>Best offer</b>
              </p>
              <p>
                <b>{offers[0]?.bidAmount || 0} POL</b>
              </p>
            </div>
          )}
        </div>
        <div className="pt-3 pb-3">
          <div className={`${Classes.metaInfo} d-flex align-items-center mb-2`}>
            <input
              className="pe-2"
              type="number"
              placeholder="Price"
              value={offer.price}
              onKeyDown={(e) => decimalnumbervalidator(e,true)}
              onChange={(e) => {
                if (
                  Number(e.target.value) <= price ||
                  (offers[offers?.length - 1]?.bidAmount &&
                    Number(e.target.value) <=
                      offers[offers?.length - 1]?.bidAmount)
                ) {
                  setError(true);
                } else {
                  setError(false);
                }
                setOffer((previous) => ({
                  ...previous,
                  price: e.target.value,
                }));
              }}
            />
            <b>POL</b>
          </div>
          <div className="d-flex justify-content-end">
            <p>Total offer amount: {offer?.price || 0} POL</p>
          </div>
        </div>
        <div>
          {(error || Number(formattedBalance) < Number(currentPrice)) && (
            <p className="text-center" style={{ color: "red" }}>
              {Number(formattedBalance) < Number(currentPrice)
                ? "Insuficient Funds"
                : ` Offer price should be greater than ${
                    offers[offers?.length - 1]?.bidAmount
                      ? offers[offers?.length - 1]?.bidAmount
                      : price
                  }`}
            </p>
          )}

          <button
            className={`${Classes.bluebtn} mt-4`}
            disabled={error || Number(formattedBalance) < Number(currentPrice)}
            onClick={onSubmit}
          >
            {isPlacingBid ? "Loading..." : "Place Bid"}
          </button>
        </div>
      </div>
    </Modal>
    {showAlertMessage && <NotificationAlert title={showModalTitle} content={showAlertMessage}  visible={isAlertVisible}
          onClose={() => setIsAlertVisible(false)}/>}
      <SuccessModal
      isModalOpen={isOfferSuccessModalOpen}
      nft={0}
      handleCancel={handleOfferSuccessModalCancel}
      modalTitle={'Offer Placed Successfully'}
      modalDescription={'You have successfully placed your offer.'}
    />
    </>
);

};

export default MakeOffer;
