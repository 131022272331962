import React, { useEffect, useState } from "react";
import Classes from "./offers.module.scss";
import PolygonIcon from "../../assets/images/icons/polygonIcon.svg";
import { createTransaction } from "../../store/_actions/transaction_actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { alertMessage } from "../../constants/alertMessage";
import useCancelBidForUser from "../../hooks/useCancelBid";
import useAcceptBidForUser from "../../hooks/useAcceptBid";
import { completeTx } from "../../services/transaction.service";
import TimeAgo from "react-timeago";


function Offers({ offers, data }) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState({ status: false, id: null });
  const loggedInUserId = JSON.parse(localStorage.getItem("userInfo"))?._id;
  const { ownerId, _id, ownerAddress } = data;
  const { connectedAddress } = useSelector((state) => state.wallet);
  const { exchangeRate } = useSelector((state) => state.nft);
  const [offer, setOffer] = useState(null);
  const isOwnerOfThisNft =
    ownerId === loggedInUserId &&
    ownerAddress?.toUpperCase() === connectedAddress?.toUpperCase();

  const {
    acceptBidForUser,
    acceptBidUserSentTx,
    acceptBidUserSentError,
    acceptBidUserTxErrors,
    isAcceptBidUserCompleted,
    acceptBidUserResp
  } = useAcceptBidForUser();

  useEffect(() => {
    if (acceptBidUserSentTx?.hash) {
      const {
        auctionId: { nftId },
        _id,
      } = offer;
      const payload = {
        data: {
          userId: loggedInUserId,
          nftId,
          bidId: _id,
        },
        txHash: acceptBidUserSentTx?.hash,
      };
      dispatch(createTransaction(payload));
    }
  }, [acceptBidUserSentTx]);
  useEffect(()=>{
    if(acceptBidUserResp?.transactionHash){
      const payload={
        event : {
          event:"SoldNft",
          returnValues: {
          },
        }
      }
      completeTx(acceptBidUserResp?.transactionHash,payload)
    }

  },[acceptBidUserResp])

  const accept = async (offer, index) => {
    alertMessage('warning','Please do not close or refresh page during transaction')
    try {
      setOffer(offer);
      const {
        auctionId: { sellId, nftId },
        _id,
        bidId,
      } = offer;
      console.log(offer, "offer");
      setLoader({ status: true, id: offer?._id });
      acceptBidForUser({
        args: [sellId, bidId],
        from: connectedAddress,
      });
      // const acceptOfferResponse = await acceptOffer(
      //   sellId,
      //   bidId,
      //   async (txHash) => {
      //     const address = await getConnectedAddress();
      //     const payload = {
      //       data: {
      //         userId: loggedInUserId,
      //         nftId,
      //         bidId: _id,
      //       },
      //       txHash,
      //     };
      //     dispatch(createTransaction(payload));
      //   }
      // );
      // if (!acceptOfferResponse?.blockHash) {
      //   setLoader({ status: false, id: null });
      //   if (acceptOfferResponse?.code === 4001) {
      //     alertMessage("error", "User denied transaction signature.");
      //   } else {
      //     alertMessage("error", acceptOfferResponse?.message);
      //   }
      // }
    } catch (err) {
      setLoader({ status: false, id: null });
      console.log(err, "error");
    }
  };
  const {
    cancelBidForUser,
    isCancelBidUserCompleted,
    cancelBidUserSentTx,
    cancelBidUserSentError,
    cancelBidUserTxError,
    cancelBidUserResp
  } = useCancelBidForUser();
  useEffect(() => {
    const error =
      cancelBidUserTxError ||
      cancelBidUserSentError ||
      acceptBidUserSentError ||
      acceptBidUserTxErrors;
    if (error) {
      alertMessage("error", `${error?.message}`);
      setLoader(null);
      setOffer(null);
    }
  }, [
    cancelBidUserTxError,
    cancelBidUserSentError,
    acceptBidUserSentError,
    acceptBidUserTxErrors,
  ]);
  useEffect(() => {
    if (isCancelBidUserCompleted) {
      dispatch(
        createTransaction({
          data: {
            userId: loggedInUserId,
            bidId: offer?._id,
          },
          txHash: cancelBidUserSentTx?.hash,
        })
      );
    }
  }, [isCancelBidUserCompleted]);
  useEffect(()=>{
    if(isAcceptBidUserCompleted||isCancelBidUserCompleted){
      setLoader(null)
    }

  },[isAcceptBidUserCompleted,isCancelBidUserCompleted])
  useEffect(()=>{
    if(cancelBidUserResp?.transactionHash){
      const payload={
        event : {
          event:"CancelBid",
          returnValues: {
          },
        }
      }
      completeTx(cancelBidUserResp?.transactionHash,payload)
    }
  },[cancelBidUserResp])
  const cancel = (offer) => {
    try {
      alertMessage('warning','Please do not close or refresh page during transaction')
      setOffer(offer);
      setLoader({ status: true, id: offer?._id });
      const {
        auctionId: { sellId, nftId },
        _id,
        bidId,
      } = offer;
      cancelBidForUser({
        args: [sellId],
        from: connectedAddress,
      });

      // cancelBid(sellId, (txHash) => {
      //   dispatch(
      //     createTransaction({
      //       data: {
      //         userId: loggedInUserId,
      //         bidId: _id,
      //       },
      //       txHash,
      //     })
      //   );
      // });
    } catch (err) {
      setLoader({ status: false, id: null });
      console.log(err, "error");
    }
  };
  return (
    <div className={Classes.offers}>
      <div className={Classes.header}>Offers</div>
      <div className={Classes.tableOuter}>
        <table>
          <tr className="">
            <th>Price (POL)</th>
            <th>USD Price</th>
            {/* <th>Floor Difference</th> */}
            {/* <th>Expiration</th> */}
            <th>From</th>
            <th>From Address</th>
            <th>Date & Time</th>
            {/* <th>Action</th> */}
          </tr>
          <tbody>
            {offers &&
              offers?.length > 0 &&
              offers.map((item, index) => (
                <tr kry={item?._id}>
                  <td>
                    <div className="d-flex gap-1 align-items-center">
                      <img src={PolygonIcon} alt="icon" />
                      <span className={Classes.boldtxt}>{item?.bidAmount}</span>
                    </div>
                  </td>
                  <td>
                    <span className={Classes.txt}>
                      ${(item?.bidAmount * exchangeRate)?.toFixed(4) || 0}
                    </span>
                  </td>
                  <td>
                    <span className={Classes.boldtxt}>
                      {item?.bidBy?.firstName}
                    </span>
                  </td>
                  <td>
                  <span className={Classes.txt}>
                      {item?.fromAddress.slice(0,6)?.toUpperCase()||""}
                    </span>
                  </td>
                  <td>
                  <span className={Classes.boldtxt}>
                    <TimeAgo date={item.createdAt}/>
                  </span>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default Offers;
