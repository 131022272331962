import React from 'react';
import { Helmet } from 'react-helmet';

const Head = () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;

  return (
    <Helmet>
      <meta charset="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="theme-color" content="#000000" />

      {/* Meta tags for SEO */}
      <meta name="title" content="CharityNFT, the NFT marketplace for nonprofit community engagement" />
      <meta name="description" content="CharityNFT is the premier marketplace for NFTs and crypto collectibles for nonprofit community engagement. Make new friends with common interests. Browse, create, buy, sell, auction, and donate NFTs using CharityNFT today." />
      <meta name="keywords" content="Charity NFTs, NFT donations, Blockchain charity projects, Crypto philanthropy, Digital assets for charity, NFT fundraising, Support charity through NFTs, Charity crypto donations, Non-fungible tokens for social impact, Ethical NFTs, NFT charitable initiatives, Donate NFTs to charity, Charity blockchain solutions, Philanthropy through NFTs, NFT marketplace for charity, Social good NFTs, NFT charity events, Crypto giving platforms, NFT art for charity, Charity-driven NFTs" />
      <meta name="author" content="CharityNFT" />
      <link rel="canonical" href={`${baseUrl}/`} />

      <title>CharityNFT, the NFT marketplace for nonprofit community engagement</title>
      <link rel="icon" type="image/svg+xml" href="/images/favicon.svg" />
    </Helmet>
  );
};

export default Head;
