import React from "react";
import { Modal } from "antd";
import Classes from "../ApprovedPurchase/modal.module.scss";
import Confirm from '../../assets/images/svg/confirm.svg'
const SuccessModal = ({ isModalOpen, handleCancel, message, modalType }) => {
  return (
    <>
      <Modal
        className={Classes.actionModal}
        open={isModalOpen}
        footer={null}
        onCancel={handleCancel}
      >
        <div className={`${Classes.modalContent} d-flex justify-content-center align-items-center flex-column gap-2`}>
            <img src={Confirm} alt="img" />
          <h2 className={`${Classes.title} m-0`}>Submited</h2>
          <p className={`${Classes.desc} px-4 mb-2`}>
            Thanks for your feedback. Item reported successfully. Our team will
            review it shortly.
          </p>
          <button onClick={handleCancel} className={Classes.bluebtn}>Back to page</button>
        </div>
      </Modal>
    </>
  );
};

export default SuccessModal;
