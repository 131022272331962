import React from "react";
import FriendsDetailsComp from "../../FriendsDetailsComp";
import LandingLayout from "../../layout/LandingLayout";

const FriendsDetails = () => {
  return (
    <LandingLayout>
      <FriendsDetailsComp />
    </LandingLayout>
  );
};

export default FriendsDetails;
