import React, { useEffect, useState } from 'react';
import Classes from './uploadProfile.module.scss';
import img11 from '../../../assets/images/section/orgProfile.svg';


const UploadProfile = ({ setProfilePicture, setFormFieldsData }) => {
  const [tabs, setTabs] = useState('avatar');
  const [profileImg, setProfileImg] = useState(img11);
  
  useEffect(()=>{
    setProfileImg(profileImg);
    setProfilePicture(`${process.env.REACT_APP_BASE_URL}${profileImg}`);
    setFormFieldsData((prevState) => ({
      ...prevState,
      profilePicture: `${process.env.REACT_APP_BASE_URL}${profileImg}`,
    }));
  },[])
  const handleImageClick = (imageUrl) => {
    setProfileImg(imageUrl);
    setProfilePicture(`${process.env.REACT_APP_BASE_URL}${imageUrl}`);
  };
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setProfilePicture(file);
      setFormFieldsData((prevState) => ({
        ...prevState,
        profilePicture: file,
      }));
      const reader = new FileReader();
      reader.onload = (event) => {
        const uploadedImageUrl = event.target.result;
        setProfileImg(uploadedImageUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className={Classes.uploadProfile}>
      <div>
        <img className={Classes.imgUpload} src={profileImg} alt='img' />
        <div className='position-relative'>
          <div className={`${Classes.uploadTxt} mt-3`}>
            Upload organization logo
          </div>
          <input
            className={Classes.inputFile}
            type='file'
            accept='image/*'
            onChange={handleImageUpload}
          />
        </div>
      </div>
      {/* <div className={Classes.imgesOuter}>
        <div className='d-flex gap-3 align-items-center mb-3'>
          <button
            onClick={() => setTabs('noProfile')}
            className={
              tabs === 'noProfile' ? `${Classes.activeTab}` : `${Classes.tab}`
            }
          >
            No Profile
          </button>
          <button
            onClick={() => setTabs('avatar')}
            className={
              tabs === 'avatar' ? `${Classes.activeTab}` : `${Classes.tab}`
            }
          >
            Avatar
          </button>
          <button
            onClick={() => setTabs('art')}
            className={
              tabs === 'art' ? `${Classes.activeTab}` : `${Classes.tab}`
            }
          >
            Art
          </button>
          <button
            onClick={() => setTabs('earth')}
            className={
              tabs === 'earth' ? `${Classes.activeTab}` : `${Classes.tab}`
            }
          >
            Earth
          </button>
          <button
            onClick={() => setTabs('colors')}
            className={
              tabs === 'colors' ? `${Classes.activeTab}` : `${Classes.tab}`
            }
          >
            Colors
          </button>
        </div>
        {tabs === 'avatar' && (
          <div className={Classes.images}>
            {imageUrls.map((imageUrl, i) => (
              <div
                key={i}
                className='grid-item'
                onClick={() => handleImageClick(imageUrl)}
              >
                <img
                  src={imageUrl}
                  alt={`Img ${i}`}
                  className={Classes.shortImg}
                />
              </div>
            ))}
          </div>
        )}
        {tabs === 'art' && (
          <div className={Classes.images}>
            <img src={img2} alt='img' />
          </div>
        )}
        {tabs === 'earth' && (
          <div className={Classes.images}>
            <img src={img5} alt='img' />
          </div>
        )}
        {tabs === 'colors' && (
          <div className={Classes.images}>
            <img src={img8} alt='img' />
          </div>
        )}
      </div> */}
    </div>
  );
};

export default UploadProfile;
