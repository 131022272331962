import React from 'react';
import Header from '../../layout/LandingLayout/Header';
import ListingComp from '../../ListingComp';

function Listing() {
  return (
    <>
      <Header />
      <ListingComp />
    </>
  );
}

export default Listing;
