import { ethers } from "ethers";
export const decodeExample=(data)=>{

// Remove the "0x" prefix if present
const cleanData = data?.startsWith('0x') ? data.slice(2) : data;

// Split the data into 32-byte (64 hex character) segments
const segments = [];
for (let i = 0; i < cleanData.length; i += 64) {
  segments.push(cleanData.slice(i, i + 64));
}

// Decode each segment
const decodedValues = segments.map((segment, index) => {
  if (index === 1) {
    // Address (last 20 bytes of the segment)
    return '0x' + segment.slice(24);
  } else {
    // Uint256
    return ethers.BigNumber.from('0x' + segment).toString();
  }
});


// Print the decoded values
console.log({
  param1: decodedValues[0],
  param2: decodedValues[1],
  param3: decodedValues[2],
  param4: decodedValues[3]
});
return {
    param1: decodedValues[0],
    param2: decodedValues[1],
    param3: decodedValues[2],
    param4: decodedValues[3]
  }
  }
