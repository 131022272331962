import React, { useEffect, useState, useContext } from "react";
import Classes from "../../scss/Header.module.scss";
import { Container } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Badge, Tooltip, Drawer } from "antd";
import { useSelector } from "react-redux";
import { FaAlignLeft, FaCircleArrowRight } from "react-icons/fa6";
import { ReactComponent as UserImg } from "../../../../assets/images/icons/useroutine.svg";
import { ReactComponent as NotificationIcon } from "../../../../assets/images/icons/notification.svg";
import { ReactComponent as SearchIcon } from "../../../../assets/images/icons/searchIcon.svg";
import { ReactComponent as SupportIcon } from "../../../../assets/images/icons/support.svg";
import { useDispatch } from "react-redux";
import { thunkLogout } from "../../../../store/_actions/user_action";
import SearchMain from "../../../SearchMain";
import { notificationsListing } from "../../../../services/notification.service";
import SocketContext from "../../../../context/socket";
import UserIcon from "../../../../assets/images/icons/userdropdown.svg";
import { ReactComponent as Logo } from "../../../../assets/images/section/logoLight.svg";
import Setting from "../../../../assets/images/icons/setting.svg";
import { ReactComponent as Notification } from "../../../../assets/images/icons/notification.svg";
import Logout from "../../../../assets/images/icons/logout.svg";
import {
  AiOutlineFileImage,
  AiOutlineFund,
  AiOutlinePicture,
  AiOutlineUserAdd,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";
import {
  SET_EXCHANGE_RATE,
  SET_POLY_EXCHANGE_RATE,
} from "../../../../store/constants";
import axios from "axios";
import { userNotificationList } from "../../../../store/_actions/notification_action";
import NotificationAlert from "../../../Alert";
import CreateNFTModal from "../../../views/Pages/CreateNFTModal";

const Header = () => {
  const [show, setshow] = useState(false);
  const [open, setOpen] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [openWallet, setOpenWallet] = useState(false);
  const [userNotifications, setUserNotifications] = useState([]);
  const [isWalletModalOpen, setIsWalletModalOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [modalType, setModalType] = useState("");
  const { connectedAddress, walletAddressError } = useSelector(
    (state) => state.wallet
  );
  const { notifications } = useSelector(
    (state) => state?.availableNotifications
  );

  const authToken = localStorage.getItem("authenticationToken");
  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const showWalletModal = () => {
    setIsWalletModalOpen(true);
  };
  const handleWalletModalCancel = () => {
    setIsWalletModalOpen(false);
  };
  const formatWalletAddress = (address) => {
    if (!address) return "";
    return `${address.slice(0, 8)}...${address.slice(-8)}`;
  };

  const location = useLocation();
  const isUserRoute =
    location.pathname === "/" ||
    location.pathname === "/nft-listing" ||
    location.pathname === "/collections" ||
    location.pathname === "/leaderboards" ||
    location.pathname === "/popular-nfts";

  const auth = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const socket = useContext(SocketContext);

  useEffect(() => {
    const changeBackground = () => {
      if (window.scrollY >= 100) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
    changeBackground();
    // adding the event when scroll change background
    window.addEventListener("scroll", changeBackground);

    return () => {
      window.removeEventListener("scroll", changeBackground);
    };
  }, []);

  useEffect(() => {
    if (socket) {
      socket.on("notification", (event) => {
        console.log("Socket event for notifications", event);
      });
    }
  }, [socket]);

  useEffect(() => {
    const getNotificationsList = async () => {
      const notificationArray = [];
      try {
        if (authToken) {
          const notificatios = await notificationsListing(authToken);
          console.log("notificatios :", notificatios);

          if (notificatios?.data) {
            const unSeenNotifications = notificatios?.data?.data?.filter(
              (noti) => noti?.isSeen === false
            );

            notificationArray.push(unSeenNotifications);
            console.log("unSeenNotifications :", unSeenNotifications);
            dispatch(userNotificationList(unSeenNotifications));
          }
        }
      } catch (error) {
        console.log("error");
      }
    };
    getNotificationsList();
  }, []);
  const logout = () => {
    dispatch(thunkLogout());
    navigate("/login");
  };

  const items = notifications?.length > 0 && [
    ...(notifications.length > 10
      ? [
          {
            key: "see-more",
            label: (
              <div
                className={`${Classes.seeMoreButton} d-flex align-items-center gap-2`}
              >
                <FaCircleArrowRight />
                <Link to="/notifications">See More</Link>
              </div>
            ),
          },
        ]
      : []),
    ...notifications.slice(0, 10).map((notification, index) => ({
      key: `${index + 1}`,
      label: (
        <Link to="/notifications" className={Classes.menuItem}>
          <div className={Classes.notify}>
            <p>{notification.notificationType}</p>
            <span>{notification.description}</span>
          </div>
        </Link>
      ),
    })),
  ];

  const userItems = [
    {
      key: "1",
      label: (
        <Link to="/profile">
          <div className={Classes.userdropdown}>
            <img src={UserIcon} alt="icon" />
            <span>Profile</span>
          </div>
        </Link>
      ),
    },

    {
      key: "2",
      label: (
        <Link to="/setting">
          <div className={Classes.userdropdown}>
            <img src={Setting} alt="icon" />
            <span>Settings</span>
          </div>
        </Link>
      ),
    },
    {
      key: "3",
      label: (
        <Link to="/notifications">
          <div className={Classes.userdropdown}>
            <Notification />
            <span>Notifications</span>
          </div>
        </Link>
      ),
    },
    {
      key: "4",
      label: (
        <>
          <div className={`${Classes.userdropdown}`} onClick={() => logout()}>
            <img src={Logout} alt="icon" />
            <span className={Classes.logout}>Logout</span>
          </div>
        </>
      ),
    },
  ];

  const handleAbc = async () => {
    socket.emit("notification", {
      name: "Talha",
      age: 21,
    });
  };
  useEffect(() => {
    if (socket) {
      socket?.emit("join");
      socket?.on("betPlaced", () => {});
      socket?.off("betPlaced", () => {});
    }

    return () => {};
  }, [socket]);
  useEffect(() => {
    // const bct: any = nftDetail?.blockchainType;
    // const coin = currencyData[bct];
    axios
      .get(
        `https://min-api.cryptocompare.com/data/price?fsym=POL&tsyms=POL,USD`
      )
      .then((res) => {
        const currentUSD = res.data.USD;
        dispatch({ type: SET_EXCHANGE_RATE, payload: currentUSD });
      });
    axios
      .get(
        `https://min-api.cryptocompare.com/data/price?fsym=USD&tsyms=USD,POL`
      )
      .then((res) => {
        const currentMATIC = res.data.POL;
        dispatch({ type: SET_POLY_EXCHANGE_RATE, payload: currentMATIC });
      });
  }, []);

  return (
    <>
      <header
        className={
          isUserRoute
            ? !scroll
              ? `${Classes.header}`
              : `${Classes.header2} ${Classes.header}`
            : `${Classes.header2} ${Classes.header}`
        }
      >
        <Container>
          <div
            className={`${Classes.headerOuter} d-flex align-items-center justify-content-between`}
          >
            <div className="d-flex align-items-center gap-md-3 gap-2">
              <FaAlignLeft className={Classes.barIcon} onClick={showDrawer} />
              <Link to="/" className={Classes.logo} onClick={() => handleAbc()}>
                <Logo />
              </Link>
              <div>
                <ul
                  className={`${Classes.menuOuter} d-flex align-items-center m-0`}
                >
                  <li>
                    <Link className={Classes.menuLik} to="/edit-cause">
                      Find a Cause
                    </Link>
                  </li>
                  <li>
                    <div
                      className={Classes.menuLik}
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        if (connectedAddress) {
                          navigate("/create-nft");
                        } else {
                          showWalletModal();
                          setMessage(
                            "Please go to your profile and click on “Connect wallet” in order to enable NFT creation."
                          );
                          setModalType("Create NFT");
                        }
                      }}
                    >
                      Create NFT
                    </div>
                  </li>
                  <li>
                    <Link className={Classes.menuLik} to="/collections">
                      See Collections
                    </Link>
                  </li>
                  {auth?.role !== "organization" && (
                    <li>
                      <Link
                        className={Classes.menuLik}
                        to={auth?.email ? "/friends-detail" : "/login"}
                      >
                        Make Friends
                      </Link>
                    </li>
                  )}
                </ul>
              </div>
            </div>
            <div>
              <ul
                className={`${Classes.menuOuter} d-flex align-items-center gap-5 m-0 p-0`}
              >
                <li className="d-flex align-items-center gap-md-3 gap-1">
                  <div
                    className={`${Classes.inputOuter} h-100 d-flex align-items-center gap-2`}
                    onClick={() => navigate("/nft-listing")}
                  >
                    <SearchIcon /> <span> NFTs </span>
                  </div>
                  {!auth.email && (
                    <Link
                      to="/sign-up"
                      className={`${Classes.join} d-flex align-items-center gap-2 px-md-3 px-2`}
                    >
                      <AiOutlineUsergroupAdd />
                      <span> Join Us </span>
                    </Link>
                  )}

                  <Link
                    to={
                      // 'https://www.paypal.com/donate/?hosted_button_id=TPLZJT4CAF78S'
                      process.env.REACT_APP_DONATION_URL
                    }
                    target="_blank"
                    className={`${Classes.baseButon} ${Classes.connect} d-flex align-items-center gap-2`}
                  >
                    <SupportIcon />
                    <span className={Classes.authBtn}>Donate</span>
                  </Link>
                  {auth?.email ? (
                    <>
                      {/* Bell Icon */}
                      {items?.length > 0 && (
                        <div className={`${Classes.notifyBlock}`}>
                          <Dropdown
                            menu={{
                              items: items,
                              className: "notification",
                            }}
                            placement="bottom"
                          >
                            <button className={`${Classes.baseButon}`}>
                              <Badge count={notifications?.length}>
                                <NotificationIcon />
                              </Badge>
                            </button>
                          </Dropdown>
                        </div>
                      )}

                      <Dropdown
                        menu={{ items: userItems }}
                        // open={true}
                        placement="bottom"
                        className={Classes.notifyDropdown}
                      >
                        <button
                          className={`${Classes.baseButon} d-flex align-items-center`}
                        >
                          <img
                            className={Classes.userImg}
                            src={auth?.profilePicture}
                            alt="userImg"
                          />
                        </button>
                      </Dropdown>
                    </>
                  ) : (
                    <Tooltip placement="bottom" title="Login">
                      <button
                        className={`${Classes.baseButon} d-flex align-items-center`}
                        onClick={() => navigate("/login")}
                      >
                        <UserImg className={Classes.userImg} />
                      </button>
                    </Tooltip>
                  )}
                </li>
              </ul>
            </div>
          </div>
          {show && <SearchMain />}
        </Container>
        <Drawer
          title="Charity NFT"
          placement="left"
          onClose={onClose}
          open={open}
        >
          <Link className={Classes.sidebarLik} to="/edit-cause">
            <AiOutlinePicture />
            Find a Cause
          </Link>
          <Link
            className={Classes.sidebarLik}
            onClick={() => {
              if (connectedAddress) {
                navigate("/create-nft");
              } else {
                showWalletModal();
                setMessage(
                  "Please go to your profile and click on “Connect wallet” in order to enable NFT creation."
                );
                setModalType("Create NFT");
              }
            }}
            // to={auth?.email ? "/create-nft" : "/login"}
          >
            <AiOutlineFund />
            Create NFT
          </Link>
          <Link className={Classes.sidebarLik} to="/collections">
            <AiOutlineFileImage />
            See Collections
          </Link>
          {auth?.role !== "organization" && (
            <Link className={Classes.sidebarLik} to="/friends-detail">
              <AiOutlineUserAdd />
              Make Friends
            </Link>
          )}

          <Link
            to={
              // 'https://www.paypal.com/donate/?hosted_button_id=TPLZJT4CAF78S'
              process.env.REACT_APP_DONATION_URL
            }
            target="_blank"
            className={`${Classes.sidebarLik}`}
          >
            <SupportIcon />
            <span className={Classes.authBtn}>Donate</span>
          </Link>
        </Drawer>
        <CreateNFTModal
          showModal={showWalletModal}
          isModalOpen={isWalletModalOpen}
          handleCancel={handleWalletModalCancel}
          message={message}
          modalType={modalType}
        />
      </header>
      {/* {auth?.walletAddress && connectedAddress !==auth?.walletAddress && <NotificationAlert title="Wallet Needs Reconnection" content={`Please go to your profile and re-connect your wallet to ${formatWalletAddress(auth?.walletAddress)}.`} />} */}
    </>
  );
};

export default Header;
