import React from 'react';
import CauseDetailComp from '../../CauseDetailComp';
import LandingLayout from '../../layout/LandingLayout';

const CauseDetails = () => {
  return (
    <>
      <LandingLayout>
        <CauseDetailComp title='Find A Cause'/>
      </LandingLayout>
    </>
  );
};

export default CauseDetails;
