import marketPlaceAbi from "./marketPlace/abi.json";
import nftAbi from "./nft/abi.json"
import { ENV } from "../config";

const CONTRACT_DETAILS = {
  market: {
    abi:marketPlaceAbi,
    address: ENV.marketPlaceAddr,
  },
  nft:{
    abi:nftAbi,
    address:ENV.nftAddr
  }
};
export default CONTRACT_DETAILS;
