// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import CONTRACT_DETAILS from "../contract/contractInfo";
const marketContractAddress = CONTRACT_DETAILS.market.address;
const marketplaceABI = CONTRACT_DETAILS.market.abi;
const useListNftForOrganization = () => {
  //approve contract

  const {
    isError: isListNftOrganizationSentError,
    data: listNftOrganizationSentTx,
    isLoading: isListNftOrganizationTxInProgress,
    isSuccess: isListNftOrganizationTxSent,
    write: listNftForOrganization,
    error: listNftOrganizationSentError
  } = useContractWrite({
    address: marketContractAddress,
    abi: marketplaceABI,
    functionName: "listForOrganizations",
  });
  const {
    isLoading: isListNftOrganizationWaiting,
    isSuccess: isListNftOrganizationCompleted,
    isError: isListNftOrganizationError,
    error: listNftOrganizationTxError,
    data: listNftOrganizationResp
  } = useWaitForTransaction({
    hash: listNftOrganizationSentTx?.hash,
  });

  return {
    //nft operation
    isListNftOrganizationSentError,
    listNftOrganizationSentTx,
    isListNftOrganizationTxInProgress,
    isListNftOrganizationTxSent,
    listNftForOrganization, // Renamed the function
    listNftOrganizationSentError,
    isListNftOrganizationWaiting,
    isListNftOrganizationCompleted,
    isListNftOrganizationError,
    listNftOrganizationTxError,
    listNftOrganizationResp
  };
};

export default useListNftForOrganization;
