import axios from 'axios';

export const listPlatformSettings = async () => {
  const authenticationToken = localStorage.getItem('authenticationToken');
  try {
    const settingsResponse = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/platform-settings`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authenticationToken}`,
        },
      }
    );
    return settingsResponse;
  } catch (error) {
    return error.response.data;
  }
};

export const listNotificationSettings = async (token) => {
  try {
    const settingsResponse = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/notification-settings`,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return settingsResponse;
  } catch (error) {
    return error.response.data;
  }
};
