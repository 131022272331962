import React, { useState } from 'react';
import Classes from './search.module.scss';
import SearchIcon from '../../assets/images/icons/searchdark.svg';

const SearchMain = ({ onChange,value }) => {
  const [selectedOption, setSelectedOption] = useState('collection');

  const handleChange = (value) => {
    setSelectedOption(value);
  };
  return (
    <div
      className={`${Classes.searchMain} d-flex gap-2 align-items-center mt-3`}
    >
      
      <div className={Classes.search}>
        <img className='pe-2' src={SearchIcon} alt='icon' />
        <input placeholder='Search....' onChange={onChange} value={value} />
        {/* <button className={Classes.blueBtn} type='button'>
          Search
        </button> */}
      </div>
      <div></div>
    </div>
  );
};

export default SearchMain;
