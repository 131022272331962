import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Classes from "./history.module.scss";
import check from "../../assets/images/icons/verified.svg";
import { Tooltip } from "antd";
import { Container } from "react-bootstrap";
import icon1 from "../../assets/images/icons/socialIcon1.svg";
import icon2 from "../../assets/images/icons/socialIcon2.svg";
import icon3 from "../../assets/images/icons/socialIcon3.svg";
import icon4 from "../../assets/images/icons/socialIcon4.svg";
import icon5 from "../../assets/images/icons/twitter-new.svg";
import icon6 from "../../assets/images/icons/socialIcon6.svg";
import icon7 from "../../assets/images/icons/socialIcon7.svg";
import icon8 from "../../assets/images/icons/socialIcon8.svg";
import icon9 from "../../assets/images/icons/socialIcon9.svg";
import share from "../../assets/images/icons/share.svg";
import img1 from "../../assets/images/section/user.svg";
import PublicUserTabs from "../UserTabs/PublicUserTabs";
import { useSelector } from "react-redux";
import Banner from "../../assets/images/section/defaultBG.png";
import ReportNonProfitModal from "./ReportNonProfitModal";
import { BsFillFlagFill } from "react-icons/bs";
import { FaLink } from "react-icons/fa";
import { Helmet } from "react-helmet";

const PublicUserHistory = ({ organization }) => {
  const socialMedia =
    organization?.socialMedia?.length && organization?.socialMedia[0];
  const [isModalOpen, setIsModalOpen] = useState(false);

  const auth = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const copyToClipboard = () => {
    const profileUrl = window.location.href;

    navigator.clipboard.writeText(profileUrl).then(() => {
      setTooltipVisible(true);
      setTimeout(() => {
        setTooltipVisible(false);
      }, 2000);
    });
  };

  useEffect(() => {
    if (organization?.success === false) {
      if (organization?.status === 403) {
        navigate("/hidden-profile");
      } else {
        navigate("/not-found");
      }
    }
  });

  return (
    <div className={`${Classes.history}`}>
      <Helmet>
        <title>{organization?.nonProfitName || organization?.username}</title>
        <meta
          name="title"
          content={organization?.nonProfitName || organization?.username}
        />
        <meta
          name="description"
          content={organization?.missionStatement || organization?.bio}
        />
        <link
          rel="canonical"
          href={`${process.env.REACT_APP_BASE_URL}/user/${organization?.username}`}
        />
      </Helmet>
      <div
        className={Classes.myBannerImg}
        style={{
          backgroundImage: `url(${organization?.bannerImage || Banner})`,
        }}
      ></div>

      <div className={`${Classes.userDetails}`}>
        <Container>
          <>
            <div className={`${Classes.userInfo}`}>
              <div className="d-flex align-items-md-center gap-md-3 gap-1 flex-sm-row flex-column">
                <div
                  className={
                    organization?.role === "user"
                      ? Classes.userImg
                      : Classes.orgImg
                  }
                >
                  <img
                    src={organization?.profilePicture || img1}
                    alt="Loading..."
                  />
                </div>
                <div>
                  <div className="pt-sm-5 pt-0 w-100">
                    <div className="d-flex align-items-center gap-2 mb-2">
                      <h3 className={Classes.userName}>
                        {organization?.nonProfitName || organization?.username
                          ? `${organization?.nonProfitName || organization?.username}`
                          : "Loading..."}
                      </h3>
                      {organization &&
                        organization?.role !== "user" &&
                        organization?.verificationStatus === "approved" && (
                          <img
                            className={Classes.varified}
                            src={check}
                            alt="icon"
                          />
                        )}
                    </div>
                    {organization && organization?.role !== "user" && (
                      <div className={Classes.metaInfo}>
                        {organization?.organizationType &&
                        organization?.organizationType.includes(
                          "fiscal sponsor"
                        ) &&
                        (!organization?.verificationStatus ||
                          organization?.verificationStatus === "disapproved")
                          ? "Pending approval by fiscal sponsor"
                          : organization?.organizationType
                            ? organization?.organizationType ===
                              "Nonprofit seeking a fiscal sponsor or 501(c)(3) status"
                              ? "Nonprofit with 501(c)(3) fiscal sponsor"
                              : organization?.organizationType
                            : ""}
                      </div>
                    )}
                  </div>
                  <div
                    className={`${Classes.socialIcons} d-flex align-items-center pt-2 flex-sm-row flex-column`}
                  >
                    <div
                      className={`${Classes.outer} d-flex align-items-center justify-content-sm-start justify-content-between gap-4 py-2 pe-sm-4 pe-0 me-sm-4 me-0`}
                    >
                      {socialMedia?.facebook && (
                        <Tooltip title="Facebook">
                          <a
                            href={socialMedia?.facebook}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon7} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.youtube && (
                        <Tooltip title="YouTube">
                          <a
                            href={socialMedia?.youtube}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon8} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.linkedIn && (
                        <Tooltip title="LinkedIn">
                          <a
                            href={socialMedia?.linkedIn}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon9} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.discord && (
                        <Tooltip title="Discord">
                          <a
                            href={socialMedia?.discord}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon3} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.tiktok && (
                        <Tooltip title="TikTok">
                          <a
                            href={socialMedia?.tiktok}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon6} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.website && (
                        <Tooltip title="Website">
                          <a
                            href={socialMedia?.website}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon2} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.instagram && (
                        <Tooltip title="Instagram">
                          <a
                            href={socialMedia?.instagram}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon4} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {socialMedia?.twitter && (
                        <Tooltip title="Twitter">
                          <a
                            href={socialMedia?.twitter}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <img src={icon5} alt="icon" />
                          </a>
                        </Tooltip>
                      )}
                      {auth?.email && (
                        <Tooltip title="Report">
                          <div
                            className="cursor_pointer"
                            onClick={() => showModal()}
                          >
                            <BsFillFlagFill
                              color="#000"
                              className={Classes.flagIcon}
                            />
                          </div>
                        </Tooltip>
                      )}
                    </div>
                    <button
                      className={Classes.linkBtn}
                      onClick={copyToClipboard}
                    >
                      <div className={`d-flex align-items-center gap-2`}>
                        <FaLink />
                        <p>
                          {tooltipVisible ? "Copied!" : "Copy profile link"}
                        </p>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
          <PublicUserTabs organization={organization} />
        </Container>

        <ReportNonProfitModal
          organization={organization}
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
        />
      </div>
    </div>
  );
};

export default PublicUserHistory;
