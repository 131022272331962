// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import CONTRACT_DETAILS from "../contract/contractInfo";

const marketContractAddress = CONTRACT_DETAILS.market.address;
const marketplaceABI = CONTRACT_DETAILS.market.abi;

const useCancelListingForUser = () => {
  //approve contract
  const {
    isError: isCancelListingUserSentError,
    data: cancelListingUserSentTx,
    isLoading: isCancelListingUserTxInProgress,
    isSuccess: isCancelListingUserTxSent,
    write: cancelListingForUser,
    error: cancelListingUserSentError
  } = useContractWrite({
    address: marketContractAddress,
    abi: marketplaceABI,
    functionName: "cancellListingForlist", // Changed the functionName to cancelListing
  });

  const {
    isLoading: isCancelListingUserWaiting,
    isSuccess: isCancelListingUserCompleted,
    isError: isCancelListingUserError,
    error: cancelListingUserTxError,
    data: cancelListingUserResp
  } = useWaitForTransaction({
    hash: cancelListingUserSentTx?.hash,
  });

  return {
    //nft operation
    isCancelListingUserSentError,
    cancelListingUserSentTx,
    isCancelListingUserTxInProgress,
    isCancelListingUserTxSent,
    cancelListingForUser, // Renamed the function
    cancelListingUserSentError,
    isCancelListingUserWaiting,
    isCancelListingUserCompleted,
    isCancelListingUserError,
    cancelListingUserTxError,
    cancelListingUserResp
  };
};

export default useCancelListingForUser;
