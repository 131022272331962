import axios from 'axios';

export const getUserFiscalProjects = async (authenticationToken, id, startDate, endDate, verificationStatus, page = 1, limit = 10, searchTerm = "") => {
  try {
    let url = `${process.env.REACT_APP_API_BASE_URL}/fiscal-projects/org-fiscal-projects/${id}?`;

    // Add startDate and endDate to the URL if they are provided
    if (startDate && endDate) {
      url += `startDate=${encodeURIComponent(startDate)}&endDate=${encodeURIComponent(endDate)}`;
    }
    if (verificationStatus) {
      url += `&verificationStatus=${verificationStatus}`;
    }
    // Add pagination parameters
    url += `&page=${page}&limit=${limit}`;
    if (searchTerm) {
      url += `&searchTerm=${searchTerm}`;
    }

    const userProjects = await axios.get(url, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authenticationToken}`,
      },
    });
    return userProjects;
  } catch (error) {
    return error.response.data;
  }
};

export const createFiscalProject = async (data, authenticationToken) => {
  try {
    const formData = new FormData();
    formData.append('name', data?.name);
    formData.append('email', data?.email);
    formData.append('organizationName', data?.organizationName);
    formData.append('einNumber', data?.einNumber);
    formData.append('percentage', data?.percentage);
    formData.append('userId', data?.userId);
    // Append the file if it exists
    if (data.image) {
      formData.append('fiscalProjectImage', data.image);
    }

    const user = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/fiscal-projects/create`,
      formData,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authenticationToken}`,
        },
      }
    );
    return user;
  } catch (error) {
    return error.response.data;
  }
};


export const getFiscalProjectStats = async ({dateFilter,id,startDate,endDate}) => {
  try {
    const userProjects = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}/stats/donations`,
      {
        params: {
          ...(!startDate && !endDate&& { dateFilter }),
          type:"organization",
          ...(id && { id }),
          ...(startDate && { startDate }),
          ...(endDate && { endDate }),

        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authenticationToken')}`,
        },
      }
    );
    return userProjects;
  } catch (error) {
    return error.response.data;
  }
};