import React from 'react';
import Classes from '../UserHistory/history.module.scss';
import { Container } from 'react-bootstrap';
import OrgImg from '../../assets/images/section/orgImg.svg';
import icon2 from '../../assets/images/icons/socialIcon2.svg';
import icon4 from '../../assets/images/icons/socialIcon4.svg';
import icon5 from '../../assets/images/icons/socialIcon5.svg';
import favourite from '../../assets/images/icons/favourite.svg';
import share from '../../assets/images/icons/share.svg';
import more from '../../assets/images/icons/more.svg';
import Check from '../../assets/images/icons/check.svg';
import OrgTabs from '../OrgTabs';
import { useSelector } from 'react-redux';

const OrgHistory = () => {
  const auth = useSelector((state) => state.auth);
  return (
    <div className={`${Classes.history}`}>
      <div className={`${Classes.bannerImg} ${Classes.bannerImg2}`}></div>
      <div className={`${Classes.userDetails}`}>
        <Container>
          <>
            <div
              className={`${Classes.userInfo} d-flex justify-content-between align-items-center`}
            >
              <div className='d-flex align-items-center gap-3'>
                <div className={Classes.orgImg}>
                  <img src={auth?.profilePicture || OrgImg} alt='imgs' />
                </div>
                <div className='pt-5'>
                  <div className='d-flex align-items-center gap-2 mb-2'>
                    <h3
                      className={Classes.userName}
                    >{`${auth?.firstName} ${auth?.lastName}`}</h3>
                    <img className={Classes.varified} src={Check} alt='icon' />
                  </div>
                </div>
              </div>
              <div
                className={`${Classes.socialIcons} d-flex align-items-center pt-4 flex-sm-row flex-column`}
              >
                <div
                  className={`${Classes.outer} d-flex align-items-center justify-content-sm-start justify-content-between gap-4 py-2 pe-sm-4 pe-0 me-sm-4 me-0`}
                >
                  <a href='#'>
                    <img src={icon2} alt='icon' />
                  </a>
                  <a href='#'>
                    <img src={icon4} alt='icon' />
                  </a>
                  <a href='#'>
                    <img src={icon5} alt='icon' />
                  </a>
                </div>
                <div className='d-flex align-items-center gap-4'>
                  <img src={favourite} alt='icon' />
                  <img src={share} alt='icon' />
                  <img src={more} alt='icon' />
                  <button
                    className={`${Classes.blueBtn} d-flex gap-2`}
                    type='button'
                  >
                    Edit Profile
                  </button>
                </div>
              </div>
            </div>
            {/* <div>
            <ul
              className={`${Classes.items} d-flex align-items-center gap-5 p-0`}
            >
              <li>
                <span className={Classes.item}>Items</span>
                <b className={Classes.value}>10.2K</b>
              </li>
              <li>
                <span className={Classes.item}>Created</span>
                <b className={Classes.value}>Oct 2023</b>
              </li>
              <li>
                <span className={Classes.item}>Creator earnings </span>
                <b className={Classes.value}>3.33%</b>
              </li>
              <li>
                <span className={Classes.item}>Chain </span>
                <b className={Classes.value}>Polygon</b>
              </li>c
              <li>
                <span className={Classes.item}>Category </span>
                <b className={Classes.value}>PFPs</b>
              </li>
            </ul>
            <p className={Classes.desc}>
              y00ts is a generative art project of 15,000 NFTs. y00topia is a
              curated community of builders and creators..…
            </p>
          </div> */}
          </>
          <OrgTabs />
        </Container>
      </div>
    </div>
  );
};

export default OrgHistory;
