import React, { useEffect, useState, useContext, useRef } from "react";
import Classes from "./nftDetail.module.scss";
import { Col, Row, Tooltip } from "antd";
import NFTImg from "../../assets/images/section/nftPreview.svg";
import HeartIcon from "../../assets/images/icons/heartIcon.svg";
import Check from "../../assets/images/icons/checkfill.svg";
import timer from "../../assets/images/icons/timer.svg";
import PriceHistory from "../PriceHistory";
import ApprovedPurchase from "../../models/ApprovedPurchase";
import MakeOffer from "../../models/MakeOffer";
import  VideoComponent  from "../../helpers/VideoPreview";
import { createTransaction } from "../../store/_actions/transaction_actions";
import moment from "moment";
import Report from "../../assets/images/icons/report.svg";
import ReportModal from "../../models/ReportModal";
import { useNavigate, useLocation,useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import SocketContext from "../../context/socket";
import { alertMessage } from "../../constants/alertMessage";
import { useSelector } from "react-redux";
import { getBids } from "../../store/_actions/bet_actions";
import { CHAIN_CURRENCY,CHAIN_ID } from "../../helpers/common";
import { AiOutlineCopy } from "react-icons/ai";
import { addNftToFav, removeNftToFav } from "../../services/auth.service";
import { thunkLogin } from "../../store/_actions/user_action";
import { ReactComponent as Heart } from "../../assets/images/icons/heart.svg";
import useCancelListingForUser from "../../hooks/useCancelList";
import useAuctionEnding from "../../hooks/useEndAuction";
import useBuyNftForUser from "../../hooks/useBuyFixedNft";
import { ethers } from "ethers";
import { completeTx,saveErrorLog } from "../../services/transaction.service";
import { ThreeDots } from "react-loader-spinner";
import momentTz from "moment-timezone";
import NotificationAlert from "../../components/Alert";
import CommonModal from "../views/Pages/CommonModal";
import { useNetwork } from 'wagmi';
import SuccessModal from "../CreateNftComp/SuccessModal.jsx";


const NFTDetail = ({
  data,
  fetchData,
  offers,
  priceData,
  activeAuction,
  price,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const { name, nftImage, description } = data;
  const [isModalOpen2, setIsModalOpen2] = useState(false);
  const [isListNftModalOpen, setIsListNftModalOpen] = useState(false);
  const [isPurcaseModalOpen, setIsPurcaseModalOpen] = useState(false);
  const [purchaseData, setPurchaseData] = useState(null);
  const [purchaseLoader, setPurchaseLoader] = useState(false);
  const [cancelListingLoader, setCancelListingLoader] = useState(false);
  const [auctionStatus, setAuctionStatus] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const authToken = localStorage.getItem("authenticationToken");
  const [isFav, setIsFav] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [loading, setLoading] = useState(true);
  const { id } = useParams(); 
  const [showAlertMessage, setShowAlertMessage] = useState('');
  const [showModalTitle, setShowModalTitle] = useState('');
  const [auctionEnded, setAuctionEnded] = useState(false);
  const [isNftRestrictModalOpen, setIsNftRestrictModalOpen] = useState(false);
  const [noOfCopies, setNoOfCopies] = useState(1);
  const [nftRestrictMessage, setNftRestrictMessage] = useState("Please contact your fiscal sponsor to approve your profile so that you can mint NFTs and access all the features on this platform.");
  const [modalType, setModalType] = useState("Listing Prohibited");
  const [successModalTitle, setSuccessModalTitle] = useState("");
  const [successModalDescription, setSuccessModalDescription] = useState("");
  const [isAlertVisible, setIsAlertVisible] = useState(false);

  const { chain } = useNetwork();
  const chainId = CHAIN_ID[process.env.REACT_APP_CONTRACT_ENV];
  const socket = useContext(SocketContext);
  const { connectedAddress } = useSelector((state) => state.wallet);
  const { exchangeRate } = useSelector((state) => state.nft);
  const auth = useSelector((state) => state.auth);
  const {
    name,
    nftImage,
    file,
    description,
    collectionId,
    currentPrice,
    // activeAuction,
    ownerId,
    ownerAddress,
    userId,
    tokenId,
    _id,
    remainingCopies,
    totalCopiesForSale,
    totalListings,
    categoryId,
    blockchainType,
    primaryType,
    donationByNft,
    nftResaleCount,
    isBan
    // isAuctionInProgress
  } = data || { name: "", description: "" };
  const nftSuccess = location.state?.optionalNftParam; 
  
  useEffect(() => {
    if(nftSuccess ==='nftListedSuccessfully'){
      setSuccessModalTitle('NFT Listed Successfully')
      setSuccessModalDescription('You have successfully listed your NFT.')
      setIsListNftModalOpen(true)
      return;
    }else if(nftSuccess ==='nftPurchasedSuccessfully'){
      setSuccessModalTitle('NFT Purchased Successfully')
      setSuccessModalDescription('You have successfully purchased your NFT.')
      setIsListNftModalOpen(true)
      fetchData();
      return;}
}, [nftSuccess,navigate,location.pathname]);

  useEffect(() => {
    setLoading(true);
}, [id]);

  useEffect(() => {
    
    if (data) {
      const currentUrl = window.location.href;
      const idFromUrl = currentUrl.split('/').pop();   
      if(data?._id===idFromUrl){
        setLoading(false);
      }
      if(data?._id?.length !== idFromUrl?.length)
      {
        navigate('/nft-not-found')
      }
    }
  }, [data]);
  const sortedCategories = categoryId?.length && categoryId?.slice().sort((a, b) => {
    if (a.categoryName < b.categoryName) return -1;
    if (a.categoryName > b.categoryName) return 1;
    return 0;
  });

  const categoryNames =
  sortedCategories && sortedCategories.map((category) => category.categoryName);

  const inputRef = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  useEffect(() => {
    const {username,id } = auth||{};
    if (!username &&!id) {
      navigate("/login");
    }else if(!username &&id){
      navigate("/get-started");
    }
  }, [auth]);

  const showNftRestrictModal = () => {
    setIsNftRestrictModalOpen(true);
  };
  const handleNftRestrictModalCancel = () => {
    setIsNftRestrictModalOpen(false);
  };
  const showListNftModal = () => {
    setIsListNftModalOpen(true);
  };
  const handleListNftModalCancel = () => {
    setIsListNftModalOpen(false);
    navigate(window.location.pathname, { replace: true });
  };
 
  const copyToClipboard = () => {
    const currentUrl = window.location.href;

    navigator.clipboard.writeText(currentUrl).then(() => {
      setTooltipVisible(true);
      setTimeout(() => {
        setTooltipVisible(false);
      }, 2000);
    });
  };

  useEffect(() => {
    if (activeAuction) {
      dispatch(getBids(activeAuction?._id));
    }
  }, [activeAuction]);

  const loggedInUserId = JSON.parse(localStorage.getItem("userInfo"))?._id;

  const utcStartDateFromBackend = moment(
    activeAuction?.sellingConfig?.duration?.startDate
  );
  const utcEndDateFromBackend = moment(
    activeAuction?.sellingConfig?.duration?.endDate
  );
  const isAuctionInProgress =
    activeAuction?.sellingMethod === 2
      ? moment() > utcStartDateFromBackend && moment() < utcEndDateFromBackend
      : null;
  const isAuctionTimeExpired =
    activeAuction?.sellingMethod === 2 && activeAuction?.status === "active"
      ? moment() >= utcEndDateFromBackend
      : null;
  const isAuctionNotStartedYet =
    activeAuction?.sellingMethod === 2 && activeAuction?.status === "active"
      ? moment() < utcStartDateFromBackend
      : null;
  useEffect(() => {
    if (
      activeAuction &&
      activeAuction?.sellingMethod === 2 &&
      isAuctionInProgress
    ) {
      setAuctionStatus(
        `Sale ends at ` +
        momentTz(utcEndDateFromBackend).tz('UTC').format("D MMMM YYYY [at] h:mm a [UTC]Z")
      );
    } else if (isAuctionNotStartedYet) {
      setAuctionStatus(
        `Auction will start at ` + momentTz(utcStartDateFromBackend).tz('UTC').format("D MMMM YYYY [at] h:mm a [UTC]Z")
      );
    }else if(isAuctionTimeExpired){
      setAuctionStatus(
        `Auction ended at ` + 
        momentTz(utcEndDateFromBackend).tz('UTC').format("D MMMM YYYY [at] h:mm a [UTC]Z")
      );
    }else{
      setAuctionStatus(
        null
      );
    }
  }, [activeAuction, isAuctionInProgress]);
  const showModal = () => {
    if(userId?.isBan || isBan){
      alertMessage('error','Sorry, This NFT is currently prohibited. You can not perform any transaction on it.') 
    }else{
    setIsModalOpen(true);}
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const showModal2 = () => {
    setIsModalOpen2(true);
  };
  const handleCancel2 = () => {
    setIsModalOpen2(false);
  };
  const toggleActive = () => {
    setIsActive(!isActive);
  };

  const btnHandler = () => {
    const isAuctionEnded =
      new Date() >= new Date(activeAuction?.sellingConfig?.duration?.endDate) &&
      new Date() >= new Date(activeAuction?.sellingConfig?.duration?.startDate);

    const sellingMethod = activeAuction?.sellingMethod;

    if (sellingMethod === 2) {
      return auctionPriceText();
    } else if (sellingMethod === 1) {
      return fixedPriceText();
    } else {
      return listBtns();
    }
  };
  const createNewTransactionForCancelListing = (txHash) => {
    try {
      alertMessage('warning','Please do not close or refresh page during transaction')
      const payload = {
        data: {
          userId: loggedInUserId,
          nftId: _id,
          auctionId: activeAuction?._id,
        },
        txHash,
      };
      dispatch(createTransaction(payload));
    } catch (err) {
      console.log(err, "err");
      setCancelListingLoader(false);
    }
  };
  const {
    cancelListingForUser,
    cancelListingUserSentTx,
    isCancelListingUserTxInProgress,
    isCancelListingUserWaiting,
    cancelListingUserSentError,
    cancelListingUserTxError,
    cancelListingUserResp
  } = useCancelListingForUser();

  
  useEffect(() => {
    if ((cancelListingUserSentError || cancelListingUserTxError)) {
      const error = cancelListingUserSentError || cancelListingUserTxError;
      if (error?.cause?.name === 'UserRejectedRequestError'){
        setShowAlertMessage('User had rejected the transaction.')
        setShowModalTitle('Transaction Cancelled')
        setIsAlertVisible(true);
        return;
      }
      setShowModalTitle('Unexpected Error')
      setShowAlertMessage('We are unable to complete your request. Please try again later.');
      setIsAlertVisible(true);
      const errorLogData = {
        type: 'Cancel Listing',
        inputPayload: {
        sellId: activeAuction.sellId,
        userdId: auth?.id,
        nftId: _id,
        auctionId: activeAuction?._id
        },
        responsePayload: {
          cause: error?.cause,
          message: error?.message,
        },
      };
      saveErrorLog(errorLogData);
      return;
    }
  }, [cancelListingUserSentError, cancelListingUserTxError]);
  useEffect(() => {
    if (cancelListingUserSentTx?.hash) {
      createNewTransactionForCancelListing(cancelListingUserSentTx?.hash);
    }
  }, [cancelListingUserSentTx]);
  useEffect(()=>{
    if(cancelListingUserResp?.transactionHash){
      const payload={
        event : {
          event:"CancelList",
          returnValues: {
          },
        }
      }
      completeTx(cancelListingUserResp?.transactionHash,payload)
    }

  },[cancelListingUserResp])

  const {
    auctionEnding,
    auctionEndingSentTx,
    isAuctionEndingTxInProgress,
    isAuctionEndingWaiting,
    auctionEndingSentError,
    auctionEndingTxError,
    auctionEndingResp
  } = useAuctionEnding();

  useEffect(() => {
    if ((auctionEndingSentError, auctionEndingTxError)) {
      const error = auctionEndingSentError || auctionEndingTxError;
      alertMessage("error", error?.message);
    }
  }, [auctionEndingSentError, auctionEndingTxError]);
  useEffect(() => {
    if (auctionEndingSentTx?.hash) {
        const payload = {
          data: {
            userId: loggedInUserId,
            nftId: activeAuction?.nftId,
            bidId: offers[0]?._id,
          },
          txHash: auctionEndingSentTx?.hash,
        };
        dispatch(createTransaction(payload));
    }
  }, [auctionEndingSentTx]);
  useEffect(()=>{
    if(auctionEndingResp?.transactionHash){
      const payload={
        event : {
          event:"SoldNft",
          returnValues: {
          },
        }
      }
      completeTx(auctionEndingResp?.transactionHash,payload)
    }

  },[auctionEndingResp])

  const createNewBuyTransaction = async (txHash) => {
    
    setPurchaseLoader(true);
    // const address = await getConnectedAddress();
    const payload = {
      data: {
        userId: loggedInUserId,
        nftId: _id,
        tokenId: +tokenId,
        ownerAddress: connectedAddress,
        auctionId: activeAuction?._id,
        copiesToBuy: noOfCopies,
      },
      txHash,
    };
    dispatch(createTransaction(payload));
  };

  const {
    buyNftForUser,
    buyNftUserSentTx,
    buyNftUserSentError,
    buyNftUserTxError,
    buyNftUserResp
  } = useBuyNftForUser();

  useEffect(() => {
    if (buyNftUserTxError || buyNftUserSentError) {
      const error = buyNftUserTxError || buyNftUserSentError;
      if ( error?.cause?.name === 'UserRejectedRequestError'){
        setShowAlertMessage('User had rejected the transaction.')
        setShowModalTitle('Transaction Cancelled')
        setIsAlertVisible(true);
        setIsPurcaseModalOpen(false);
        return;
      }
      if (error?.message?.includes('The total cost (gas * gas fee + value) of executing this transaction exceeds the balance of the account')){
        setShowAlertMessage('There is insufficient fund to complete the transaction. Please add funds to your wallet and try again.')
        setShowModalTitle('Insufficient fund')
        setIsAlertVisible(true);
        setIsPurcaseModalOpen(false);
        return;
      }   
      setShowModalTitle('Unexpected Error')
      setShowAlertMessage('We are unable to complete your request. Please try again later.');
      setIsAlertVisible(true);
      setIsPurcaseModalOpen(false);
      // New error logging logic
      const errorLogData = {
        type: 'Buying',
        inputPayload: {
        sellId: activeAuction.sellId,
        copies:noOfCopies,
        from: connectedAddress,
        price:noOfCopies * price,
        userdId: auth?.id
        },
        responsePayload: {
          cause: error?.cause,
          message: error?.message,
        },
      };
      saveErrorLog(errorLogData);
    }
  }, [buyNftUserSentError, buyNftUserTxError]);
  useEffect(() => {
    if (buyNftUserSentTx?.hash) {
      createNewBuyTransaction(buyNftUserSentTx?.hash);
    }
  }, [buyNftUserSentTx]);
  useEffect(() => {
    if (buyNftUserResp) {
      const payload={
        event : {
          event:"SoldNft",
          returnValues: {
          },
        }
      } 
      const txHash = buyNftUserResp?.transactionHash;
      completeTx(txHash,payload)
    }
  }, [buyNftUserResp]);

  const buyNow = async () => {
    setShowAlertMessage('')
    setShowModalTitle('')
    alertMessage('warning','Please do not close or refresh page during transaction')  
    try {
      buyNftForUser({
        args: [activeAuction.sellId,noOfCopies],
        from: connectedAddress,
        value: ethers.utils.parseEther(`${noOfCopies * price}`),
      });
      // await buyFixedPriceItem(
      //   activeAuction.sellId,
      //   price,
      //   createNewBuyTransaction
      // );
    } catch (err) {
      setIsPurcaseModalOpen(false);
      setPurchaseLoader(false);
    }
  };

  const fixedPriceText = () => {
    let text = "";
    let text2 = "";
    let action = (text) => {};
    if (ownerAddress === connectedAddress) {
      text =
        isCancelListingUserTxInProgress || isCancelListingUserWaiting
          ? "Loading...."
          : "Cancel listing";
      text2 = remainingCopies - totalCopiesForSale > 0 ? "List remaining copies" : null;
      // action = cancellListing;
      action = () =>
        {
          if(chainId!==chain?.id){
            setShowModalTitle('Transaction Cancelled')
            setShowAlertMessage(`Please switch your wallet network to ${chainId===97 ? 'Binance Smart Chain Testnet':'Polygon'}.`);
            setIsAlertVisible(true);
            return;
          }
          console.log(activeAuction?.sellId,"activeAuction?.sellId=>")
          cancelListingForUser({
            args: [activeAuction?.sellId],
            from: connectedAddress,
          });
        }
      

    } else if (ownerAddress !== connectedAddress && connectedAddress) {
      text = "Buy 1 Now";
      action = () => {
        if(userId?.isBan || isBan){
          alertMessage('error','Sorry, This NFT is currently prohibited. You can not perform any transaction on it.') 
        }else{
          if(chainId!==chain?.id){
            setShowModalTitle('Transaction Cancelled')
            setShowAlertMessage(`Please switch your wallet network to ${chainId===97 ? 'Binance Smart Chain Testnet':'Polygon'}.`);
            setIsAlertVisible(true);
            return;
          }
            buyNow();
            setPurchaseData(data);
            setIsPurcaseModalOpen(true);
        }
        
      };
    }
    if (text) {
      return (
        <>
          <button
            disabled={
              isCancelListingUserTxInProgress || isCancelListingUserWaiting
            }
            onClick={() => action(text)}
            className={Classes.lightBtn}
          >
            {text}
          </button>
          {text2 && (
            <button
              onClick={() => {
                if (auth?.verificationStatus === 'disapproved') {
                  showNftRestrictModal()
                } else {
                  navigate(`/listing/${_id}`, { state: { data } });
                }
              }}
              className={`${Classes.lightBtn} mt-3`}
            >
              {text2}
            </button>
          )}
        </>
      );
    }
  };

  const endAuction =()=>{
    if(chainId!==chain?.id){
      setShowModalTitle('Transaction Cancelled')
      setShowAlertMessage(`Please switch your wallet network to ${chainId===97 ? 'Binance Smart Chain Testnet':'Polygon'}.`);
      setIsAlertVisible(true);
      return;
    }
    auctionEnding({
      args: [activeAuction?.sellId],
      from: connectedAddress,
    });
  }

  const auctionPriceText = () => {
    let text = "";
    let text2 = "";
    let text3 = "";
    let text4 = "";
    let action = (text) => {};
    if (
      ownerAddress === connectedAddress &&
      (isAuctionInProgress || isAuctionTimeExpired || isAuctionNotStartedYet)
    ) {
      text = isAuctionNotStartedYet
      ? null
      : isCancelListingUserTxInProgress || isCancelListingUserWaiting
      ? "Loading...."
      : "Cancel listing";
      
      if((activeAuction?.bids.length>0 && ownerAddress === connectedAddress) || (isAuctionTimeExpired && activeAuction?.bids.length>0)){
        text=null;
      }    
      text2 = remainingCopies - totalCopiesForSale > 0 ? "List remaining copies" : null;
      text3 = ownerAddress === connectedAddress && activeAuction?.sellingMethod === 2 && activeAuction?.status === "active"
      && moment() >= utcEndDateFromBackend && activeAuction?.bids?.length>0 ? "End Auction"
      : null
      if(text3 ==='End Auction' && (isAuctionEndingTxInProgress || isAuctionEndingWaiting)){
        text3 = 'Loading....'
      }
     
      action = () =>{
        if(chainId!==chain?.id){
          setShowModalTitle('Transaction Cancelled')
          setShowAlertMessage(`Please switch your wallet network to ${chainId===97 ? 'Binance Smart Chain Testnet':'Polygon'}.`);
          setIsAlertVisible(true);
          return;
        }

        cancelListingForUser({
          args: [activeAuction?.sellId],
          from: connectedAddress,
        });
      }
    } else if (
      ownerAddress !== connectedAddress &&
      isAuctionInProgress &&
      connectedAddress
    ) {
      text = "Place Offer";
      action = showModal;
    }
    if(activeAuction?.highestBidder === connectedAddress ){
      text4 = activeAuction?.highestBidder === connectedAddress && activeAuction?.sellingMethod === 2 && activeAuction?.status === "active"
      && moment() >= utcEndDateFromBackend && activeAuction?.bids?.length>0 ? "Claim NFT"
      : null
      if(text4 ==='Claim NFT' && (isAuctionEndingTxInProgress || isAuctionEndingWaiting)){
        text4 = 'Loading....'
      }

    }
    if (text || text2 || text3 || text4) {
      return (
        <>
          {text && (
            <>
              {" "}
              <button
                disabled={
                  isCancelListingUserTxInProgress || isCancelListingUserWaiting
                }
                onClick={() => action(text)}
                className={Classes.lightBtn}
              >
                {text}
              </button>
            </>
          )}

          {text2 && (
            <>
              <button
                // disabled={cancelListingLoader}
                onClick={() => {
                  if (auth?.verificationStatus === 'disapproved') {
                    showNftRestrictModal()
                  } else {
                    navigate(`/listing/${_id}`, { state: { data } });
                  }
                }}
                className={`${Classes.lightBtn} mt-3`}
              >
                {text2}
              </button>
            </>
          )}

          {text3 && (
            <>
              {" "}
              <button
                disabled={
                  isAuctionEndingTxInProgress || isAuctionEndingWaiting
                }
                onClick={() => endAuction()}
                className={`${Classes.lightBtn} mt-3`}
              >
                {text3}
              </button>
            </>
          )}
          {text4 && (
            <>
              {" "}
              <button
                disabled={
                  isAuctionEndingTxInProgress || isAuctionEndingWaiting
                }
                onClick={() => endAuction()}
                className={`${Classes.lightBtn} mt-3`}
              >
                {text4}
              </button>
            </>
          )}
        </>
      );
    }
  };
      

  const listBtns = () => {
    let text = "";
    let action = "";
    if (ownerAddress === connectedAddress) {
      text = "List NFT";
      action = () => {
        if (auth?.verificationStatus === 'disapproved') {
          showNftRestrictModal()
        } else {
          navigate(`/listing/${_id}`, { state: { data } });
        }
      };
    }
    if (text) {
      return (
        <button onClick={() => action()} className={Classes.lightBtn}>
          {text}
        </button>
      );
    }
  };
  const checkIsFavourites = async () => {
    const isNftInFavorites = auth?.favourites?.includes(_id);
    setIsFav(isNftInFavorites);
    return Promise.resolve(isNftInFavorites);
  };
  const handleAddToFavourite = async (nftId) => {
    try {
      if (authToken) {
        const data = {
          nftId,
          authToken,
        };
        const response = await addNftToFav(data);

        if (response?.data) {
          checkIsFavourites();
          dispatch(
            thunkLogin(response?.data?.data, () => {
              alertMessage("success", `${response?.data?.message}`);
            })
          );
        }
      }
    } catch (error) {
      console.log("error :", error);
    }
  };
  const handleRemoveToFavourite = async (nftId) => {
    try {
      if (authToken) {
        const data = {
          nftId,
          authToken,
        };
        const response = await removeNftToFav(data);

        if (response?.data) {
          checkIsFavourites();
          dispatch(
            thunkLogin(response?.data?.data, () => {
              alertMessage("success", `${response?.data?.message}`);
            })
          );
        }
      }
    } catch (error) {
      console.log("error :", error);
    }
  };

  const formatWalletAddress = (address) => {
    if (!address) return '';
    return `${address.slice(0, 8)}...${address.slice(-8)}`;
  };

  useEffect(() => {
    if (auth?.walletAddress && connectedAddress !== auth?.walletAddress) {
      setIsAlertVisible(true);  // Show the alert
    } else {
      setIsAlertVisible(false); // Hide the alert if addresses match
    }
  }, [auth?.walletAddress, connectedAddress]);
  
  useEffect(() => {
    if (socket) {
      const userId = JSON.parse(localStorage.getItem("userInfo"))?._id;
      socket?.emit("join", `${userId}`);
      socket?.emit("join", `${_id}`);
      // socket?.emit("join", `${activeAuction?._id}`);
      const handleNftPurchase = ({ nftId }) => {
        
        setPurchaseLoader(false);
        setIsPurcaseModalOpen(false);
        navigate(`/nft/${nftId}`, { state: { optionalNftParam: "nftPurchasedSuccessfully" } });

      };
      const listingCancelled = () => {
        alertMessage("success", "Listing cancelled successfully");
        setCancelListingLoader(false);
        fetchData();
      };
      const offerAccepted = () => {
        // alertMessage("success", "Auction ended successfully");
        setSuccessModalTitle('Auction Ended Successfully')
        setSuccessModalDescription('You have successfully ended your auction.')
        setIsListNftModalOpen(true)
        fetchData();
      };
      const bidCancelled = () => {
        alertMessage("success", "Offer cancelled successfully");
        fetchData();
      };
      const auctionTimeEnded = () => {
        console.log("=====>>> check auction ended event")
        fetchData();
      };
      const auctionTimeStarted = () => {
        console.log("=====>>> check auction started event")
        fetchData();
      };
      const nftTransfer = () => {
        console.log("=====>>> check auction started event")
        fetchData();
      };
      const bidPlacedOnAuction = () => {
        console.log("=====>>> bid placed event")
        fetchData();
      };

      socket.on('auctionTimeEnded',auctionTimeEnded)
      socket.on('auctionTimeStarted',auctionTimeStarted)
      socket.on('nftTransfer',nftTransfer)
      socket.on('bidPlacedOnAuction',bidPlacedOnAuction)
      socket?.on("nftPurchased", handleNftPurchase);
      socket?.on("listingCancelled", listingCancelled);
      socket?.on("offerAccepted", offerAccepted);
      socket?.on("bidCancelled", bidCancelled);

      return () => {
        socket?.off("nftPurchased", handleNftPurchase);
        socket?.off("listingCancelled", listingCancelled);
        socket?.off("offerAccepted", offerAccepted);
        socket?.off("bidCancelled", bidCancelled);
        socket?.off("auctionTimeEnded", auctionTimeEnded);
        socket?.off("auctionTimeStarted", auctionTimeStarted);
        socket.off('nftTransfer',nftTransfer);
        socket.off('bidPlacedOnAuction',bidPlacedOnAuction)
        socket.emit("leave", `${loggedInUserId}`);
      };
    }
  }, [socket]);

  useEffect(() => {
    const updateIsFav = async () => {
      const isFavorite = await checkIsFavourites();
      if (isFav !== isFavorite) {
        setIsFav(isFavorite);
      }
    };

    updateIsFav();
  }, [auth.favourites, _id, isFav]);
console.log(activeAuction,"activeAuction==>")
  return (
    <div className={Classes.nftDetail}>
       {loading ? (
        <div className="d-flex justify-content-center align-items-center">
          <ThreeDots
            height="80"
            width="80"
            radius="9"
            color="#d9d9d9"
            ariaLabel="three-dots-loading"
            visible={true}
          />
        </div>
      ) : (
      <Row gutter={[25, 25]}>
        <Col lg={10} md={24} sm={24} xs={24}>
          <div className="position-relative">
            {primaryType === "image" ? (
              <img
                className={Classes.nftImg}
                src={file || nftImage || NFTImg}
                alt="img"
              />
            ) : (
              <VideoComponent source={file} />
            )}
            {/* <div className={Classes.metaInfo}>
              <img height={20} src={HeartIcon} alt="icon" />
              <span>68</span>
            </div> */}
          </div>
        </Col>

        <Col lg={14} md={24} sm={24} xs={24}>
          <div className="d-flex align-items-center justify-content-between">
            <div>
              <div className="d-flex align-items-center gap-1"  onClick={() => navigate(`/collection-detail/${collectionId?._id}`)} style={{cursor:'pointer'}}>
                <p className={Classes.nameOuter}>
                  {collectionId?.collectionName || ""}
                </p>
                <img src={Check} alt="icon" />
              </div>
              <h3 className={Classes.name}>{name || ""}</h3>
            </div>
            <div className="d-flex gap-4 align-items-center">
              {authToken ? (
                <div className={Classes.actionBtn}>
                  {isFav ? (
                    <div
                      onClick={() => {
                        toggleActive();
                        handleRemoveToFavourite(_id);
                      }}
                      className={Classes.bg + " " + Classes.heartActive}
                    >
                      <Heart />
                    </div>
                  ) : (
                    <div
                      onClick={() => {
                        toggleActive();
                        handleAddToFavourite(_id);
                      }}
                      className={Classes.bg + " " + Classes.heart}
                    >
                      <img src={HeartIcon} height="20px" alt="icon" />
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}

              <Tooltip
                placement="bottom"
                title={tooltipVisible ? "Copied!" : "Copy link"}
              >
                <button className={Classes.reportbtn} onClick={copyToClipboard}>
                  <AiOutlineCopy className={Classes.icon} />{" "}
                  <input
                    ref={inputRef}
                    type="text"
                    value={window.location.href}
                    readOnly
                    style={{ position: "absolute", left: "-9999px" }}
                  />
                </button>
              </Tooltip>

              <Tooltip placement="bottom" title="Report">
                <button onClick={showModal2} className={Classes.reportbtn}>
                  <img src={Report} alt="icon" />
                </button>
              </Tooltip>
            </div>
          </div>
          <div className="mb-2"  onClick={() => {
                if (connectedAddress === ownerAddress) {
                  navigate("/profile");
                } else {
                  navigate(`/user/${userId?.username}`);
                }
              }} style={{cursor:'pointer'}}>
            <p className={`${Classes.txt} ${Classes.desc}`}>
             {userId?.role==="user"? "Owned by user ":"Owned by nonprofit "}
             &nbsp;
              <span>
                {connectedAddress === ownerAddress ? "You" : userId?.role === "user" ? userId?.username:userId?.nonProfitName}
              </span>
            </p>
          </div>
          <div className="mb-2">
            <p className={`${Classes.txt} ${Classes.desc}`}>
              Copies:&nbsp;
              <span>{remainingCopies || 0}</span>
            </p>
          </div>
          <div className="mb-2">
            <p className={`${Classes.txt} ${Classes.desc}`}>
            Charity Donations:&nbsp;
              <span>{donationByNft?.toFixed(6) || 0} POL</span>
            </p>
          </div>
          <div className="mb-2">
            <p className={`${Classes.txt} ${Classes.desc}`}>
            NFT Sales:&nbsp;
              <span>{nftResaleCount || 0}</span>
            </p>
          </div>
          {((activeAuction && activeAuction?.sellingMethod === 1) ||
            (activeAuction?.sellingMethod === 2 && isAuctionInProgress)) && (
            <div className="mb-2">
              <p className={`${Classes.txt} ${Classes.desc}`}>
                Copies on sale:&nbsp;
                <span>{activeAuction?.copiesForAuction || 0}</span>
              </p>
            </div>
          )}
          {categoryNames && (
            <div className="d-flex align-items-center gap-4 mb-4">
              {/* <div className="d-flex gap-2 align-items-center"> */}
              {/* <img src={EyeIcon} alt="img" /> */}
              {/* <span>85 views</span> */}
              {/* </div> */}
              <div className="d-flex gap-2">
                {/* <img src={ArtIcon} alt="img" /> */}
                <p>
               Causes:</p>
                <span style={{paddingTop:"2px"}}>
                  {categoryNames?.length > 1
                    ? categoryNames.join(", ")
                    : categoryNames[0]}
                </span>
              </div>
            </div>
          )}
         
          <div className={`${Classes.card} mb-4`}>
            {auctionStatus && (
              <div
                className={`${Classes.border_bottom} d-flex align-items-center gap-2 pb-3`}
              >
                <img src={timer} alt="icon" />
                <p>{auctionStatus || ""}</p>
              </div>
            )}

            <div className={`${Classes.prodInfo} pt-3`}>
              <p className={Classes.txt}>Current price</p>
              <h3 className={Classes.amount}>
                {price || 0} {CHAIN_CURRENCY[blockchainType]}{" "}
                {exchangeRate && price && (
                  <span className={Classes.txt}>
                    ${(exchangeRate * price)?.toFixed(2)}
                  </span>
                )}
              </h3>
              {btnHandler()}
             
            </div>
          </div>
          {/* {priceData && priceData?.length >= 2 && ( */}
          <PriceHistory data={priceData} />
          {/* )} */}
        </Col>
      </Row>
      )}
      {/* modall here */}
      {purchaseData && (
        <ApprovedPurchase
          showModal={() => {
            setIsPurcaseModalOpen(true);
          }}
          isModalOpen={isPurcaseModalOpen}
          handleCancel={() => {
            if (purchaseLoader) {
              return;
            }
            setIsPurcaseModalOpen(false);
          }}
          data={purchaseData}
          purchaseLoader={purchaseLoader}
          price={price}
        />
      )}

      <MakeOffer
        showModal={showModal}
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        data={data}
        activeAuction={activeAuction}
        fetchData={fetchData}
        connectedAddress={connectedAddress}
        offers={offers}
        price={price}
        setIsModalOpen={setIsModalOpen}
      />
      
      <ReportModal
        showModal={showModal2}
        isModalOpen={isModalOpen2}
        handleCancel={handleCancel2}
        nftDetail={data}
      />
    {showAlertMessage && <NotificationAlert title={showModalTitle} content={showAlertMessage}  visible={isAlertVisible}
          onClose={() => setIsAlertVisible(false)} />}
    {auth?.walletAddress && connectedAddress !==auth?.walletAddress && <NotificationAlert title="Wallet Needs Reconnection" content={`Please go to your profile and re-connect your wallet to ${formatWalletAddress(auth?.walletAddress)}.`}  visible={isAlertVisible}
          onClose={() => setIsAlertVisible(false)}/>}
    <CommonModal
        showModal={showNftRestrictModal}
        isModalOpen={isNftRestrictModalOpen}
        handleCancel={handleNftRestrictModalCancel}
        message={nftRestrictMessage}
        modalType={modalType}
      />
      <SuccessModal
        isModalOpen={isListNftModalOpen}
        nft={0}
        handleCancel={handleListNftModalCancel}
        modalTitle={successModalTitle}
        modalDescription={successModalDescription}
      />
    </div>
  );
};

export default NFTDetail;
