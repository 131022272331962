import React, { useState, Fragment, useEffect } from "react";
import { Modal } from "antd";
import Classes from "./tabs.module.scss";
import { alertMessage } from "../../constants/alertMessage";
import { updateFiscalProject } from "../../services/auth.service";
import { useSelector, useDispatch } from "react-redux";
import { thunkLogin } from "../../store/_actions/user_action";
import useSetFiscalSponsor from "../../hooks/useSetFiscalSponsorPercentage";

const EditFiscalProjectModal = ({
  isEditModalOpen,
  handleCancel,
  fiscalProject,
  getOrganizationFiscalProjects
}) => {
  const auth = useSelector((state) => state?.auth);
  const authToken = localStorage.getItem("authenticationToken");
  const [isLoading, setIsLoading] = useState(false);
  const [sponsorshipFee, setSponsorshipFee] = useState("");
  const [sponsorshipFeeValidation, setSponsorshipFeeValidation] = useState("");
  const { connectedAddress } = useSelector((state) => state.wallet);

  const dispatch = useDispatch();
  const {
    setFiscalSponsor,
    isFiscalSponsorCompleted,
    fiscalSponsorSentError,
    fiscalSponsorTxError,
  } = useSetFiscalSponsor();
  useEffect(() => {
    if (isFiscalSponsorCompleted) {
      const handleUpdateFiscalSponser = async () => {
        const editResponse = await updateFiscalProject(
          fiscalProject?._id,
          authToken,
          {
            sponsorshipFee: sponsorshipFee,
          }
        );
        if (editResponse?.data?.success) {
          setIsLoading(false);
          handleCancel();
          getOrganizationFiscalProjects();
          dispatch(
            thunkLogin(auth, () => {
              alertMessage("success", `${editResponse?.data?.message}`);
            })
          );
        }
      };
      handleUpdateFiscalSponser();
    }
  }, [isFiscalSponsorCompleted]);
  useEffect(() => {
    setIsLoading(false)
    const error = fiscalSponsorSentError || fiscalSponsorTxError;
    if (error) {
      alertMessage("success", `${error?.message}`);
    }
  }, [fiscalSponsorSentError, fiscalSponsorTxError]);

  const handleSave = async () => {
   
    setIsLoading(true);
    if (!connectedAddress) {
      alertMessage("error", "Please connect wallet.");
      setIsLoading(false);
      return;
    }
    if (!sponsorshipFee) {
      setSponsorshipFeeValidation("Sponsorship fee is required.");
      setIsLoading(false);
      return;
    }
    if (sponsorshipFee < 1 || sponsorshipFee > 10) {
      setSponsorshipFeeValidation("Sponsorship fee must be between 1 to 10.");
      setIsLoading(false);
      return;
    }
    alertMessage('warning','Please do not close or refresh page during transaction')
    setSponsorshipFeeValidation("");
    const data = {
      fiscalProjectOrganization:
        fiscalProject?.walletAddress ||
        "0x0000000000000000000000000000000000000000",
      sponsorshipPercentage: sponsorshipFee * 100 || 0,
    };
    setFiscalSponsor({
      args: [data.fiscalProjectOrganization, data.sponsorshipPercentage],
    });
    
  };
  useEffect(() => {
    if (fiscalProject?.sponsorshipFee) {
      setSponsorshipFee(fiscalProject?.sponsorshipFee);
    }
  }, [fiscalProject]);

  return (
    <div>
      <Modal
        title="Set Sponsorship Fee"
        open={isEditModalOpen}
        footer={null}
        onCancel={handleCancel}
        className={Classes.projModal}
      >
        <div className={`${Classes.modalInner}`}>
          <div className={Classes.formInput}>
            {/* <p className={Classes.label}>Set sponsorship fee:</p> */}
            <input
              type="number"
              placeholder="Enter sponsorship fee percentage"
              value={sponsorshipFee}
              onChange={(e) => setSponsorshipFee(e.target.value)}
              className={Classes.inputField}
            />
            <span
              className="mt-1 d-block"
              size={"small"}
              style={{ color: "red" }}
            >
              {sponsorshipFeeValidation ? (
                <Fragment>{sponsorshipFeeValidation}</Fragment>
              ) : null}
            </span>
          </div>
          <button
            className={`${Classes.singleBlueBtn}`}
            onClick={handleSave}
            disabled={isLoading}
          >
            {isLoading ? "Loading..." : "Save"}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default EditFiscalProjectModal;
