import React, { useState } from "react";
import Classes from "./uploader.module.scss";
import { message, Upload } from "antd";
import UploadImg from "../../assets/images/svg/upload.svg";
import { alertMessage } from "../../constants/alertMessage";

const Uploader = ({ isoDocument, setIsoDocument }) => {
  const [hasAlerted, setHasAlerted] = useState(false);
  const { Dragger } = Upload;

  const props = {
    name: "file",
    maxCount: 1,
    accept: ".pdf,.jpeg,.png,.jpg,.jpg",
    onChange(info) {
      const { status } = info.file;

      // Reset the alert flag when a new file is being processed
      setHasAlerted(false);
      setIsoDocument(info?.file?.originFileObj);

      // Check file type
      const isSupportedFormat =
        info.file.type === "application/pdf" ||
        info.file.type === "image/jpeg" ||
        info.file.type === "image/png" ||
        info.file.type === "image/jpg";
        
      if (!isSupportedFormat) {
        if (!hasAlerted) {
          alertMessage(
            "error",
            "Unsupported file format. Please upload a PDF, JPEG, PNG, or JPG file."
          );
          setHasAlerted(true);
        }
        setIsoDocument("");
        return;
      }

      // Check file size
      const maxSize = 25 * 1024 * 1024; // 25 MB
      if (info?.file?.size > maxSize) {
        if (!hasAlerted) {
          alertMessage(
            "error",
            "File size exceeds the maximum limit (25 MB). Please choose a smaller file."
          );
          setHasAlerted(true);
        }
        setIsoDocument("");
        return;
      }

      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    customRequest(options) {
      options.onSuccess();
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div className={Classes.uploader}>
      <div className="d-flex align-items-center justify-content-between mb-1">
        <p className={Classes.bottomTxt}>
          Supported Formats: PDF, JPEG, PNG, JPG
        </p>
        <p className={Classes.bottomTxt}>Maximum size: 25MB</p>
      </div>
      <Dragger {...props} className="mb-2">
        <p className="ant-upload-drag-icon mb-4">
          <img src={UploadImg} alt="img" />
        </p>
        <p className="ant-upload-text">
          Drag & Drop your file here. or <span> Choose file</span>
        </p>
      </Dragger>
    </div>
  );
};

export default Uploader;
