import React from "react";
import Classes from "../Invite/invite.module.scss";
import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { findOrganizationByEin } from "../../services/auth.service";

const FiscalSponsorConsentModal = ({
  isModalConsent,
  handleConsentCancel,
  setSelectedOption,
  showAgreementModal,
  consentState,
}) => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state?.auth);
  const token = localStorage.getItem("authenticationToken");
  const handleSendInvite = async () => {
    try {
      const requestData = {
        ein: process.env.REACT_APP_IFERS_EIN,
        token,
      };
      const response = await findOrganizationByEin(requestData);

      if (response?.data?.data) {
        return response?.data?.data;
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleFiscalSponsorAccept = async () => {
    const fiscalSponsorId = await handleSendInvite();

    if (fiscalSponsorId && fiscalSponsorId?._id) {
      const data = {
        accountType: consentState?.accountType,
        organizationType: consentState?.organizationType,
        role: consentState?.role,
        fiscalProjects: [auth?.id],
        fiscalSponsorId: fiscalSponsorId?._id,
        fiscalSponsorEmail: fiscalSponsorId?.email,
      };
      navigate("/fiscal-sponsor-agreement", { state: { data } });
    }
  };
  const handleFiscalSponsorReject = async () => {
    handleConsentCancel();
  };

  return (
    <Modal
      title="Fiscal Sponsorship Consent"
      open={isModalConsent}
      onCancel={handleConsentCancel}
      footer={null}
    >
      <>
        <h3 className="mb-4">
          <b>Do you want to be assigned a fiscal sponsor now ?</b>
        </h3>
        <div className="justify-content-center align-items-center gap-3">
          <div
            className={`${Classes.addContent} d-flex justify-content-center align-items-center gap-3`}
          >
            <button
              className={`${Classes.rejectbtn} ${Classes.reject}`}
              onClick={() => handleFiscalSponsorReject()}
            >
              No
            </button>
            <button
              className={`${Classes.sendbtn}`}
              // to='/fiscal-sponsor-agreement'
              // to='/fiscal-sponsor-agreement'
              onClick={() => handleFiscalSponsorAccept()}
            >
              Yes
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default FiscalSponsorConsentModal;
