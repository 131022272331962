import { SET_CREATE_TRANSTIOIN_LOADER } from "../constants";
import { postRequest } from "../../helpers/request";
import { ENV } from "../../config";
export const createTransaction = (data) => (dispatch) => {
  let url =ENV.apiBaseUrl+"/transaction/";
  dispatch({
    type: SET_CREATE_TRANSTIOIN_LOADER,
    payload: { loader: true, status: "pending" },
  });
  postRequest(url, data)
    .then((res) => {
      console.log(res, "res of create transaction");
      dispatch({
        type: SET_CREATE_TRANSTIOIN_LOADER,
        payload: { loader: false, status: "success" },
      });
    })
    .catch((err) => {
      console.log(err, "err=>");
      dispatch({
        type: SET_CREATE_TRANSTIOIN_LOADER,
        payload: { loader: false, status: "failed" },
      });
    });
};
    