import React, { useState } from "react";
import { Col, Row, Modal, Tooltip } from "antd";
import Classes from "./friendsDetails.module.scss";
import Varified from "../../assets/images/icons/varified.svg";
import { respondFriendRequest } from "../../services/friends.service";
import { alertMessage } from "../../constants/alertMessage";
import { useSelector } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";

const FriendRequests = ({
  showModal,
  isModalOpen,
  handleCancel,
  pendingFriendRequests,
  getFriendsListing,
}) => {
  const navigate = useNavigate();
  const [requestData, setRequestData] = useState({});
  const [loading, setLoading] = useState({});
  const [isRejectloading, setIsRejectLoading] = useState(false);

  const auth = useSelector((state) => state.auth);

  const handleFriendRequest = async (friendId, requestStatus, id) => {
    try {
      setLoading((prevLoading) => ({ ...prevLoading, [friendId]: true }));
      const data = {
        userId: friendId,
        friendId: auth?.id,
        requestStatus,
      }

      if (requestStatus === "reject") {
        setIsRejectLoading(true);
      }
      const requests = await respondFriendRequest(data);

      if (requests?.data) {
        setLoading((prevLoading) => ({ ...prevLoading, [friendId]: false }));
        if (requestStatus === "reject") {
          setIsRejectLoading(false);
          handleCancel();
        }
        requestStatus === "reject"
          ? alertMessage("success", "Friend request rejected successfully.")
          : alertMessage("success", "Friend request accepted successfully.");
        getFriendsListing();
      }
    } catch (error) {
      alertMessage("error", "Something went wrong.");
      setLoading((prevLoading) => ({ ...prevLoading, [friendId]: true }));
      setIsRejectLoading(false);
      console.log("error.response", error.response.message);
    }
  };

  const rejection = async () => {
    await handleFriendRequest(
      requestData?.friendId,
      requestData?.requestStatus
    );
  };

  return (
    <>
      <Row gutter={[20, 20]}>
        {pendingFriendRequests?.length ? (
          pendingFriendRequests?.map((pendingFriendRequest, index) => (
            <Col lg={12} md={24} sm={24} xs={24} key={index}>
              <div className={Classes.columnContainer}>
                <div className={Classes.column}>
                  <div
                    className={`${Classes.item} pb-4`}
                    style={{ cursor: "pointer" }}
                  >
                    <div
                      className="d-flex align-items-center gap-4"
                      onClick={() =>
                        navigate(
                          `/user/${pendingFriendRequest?.user?.username}`
                        )
                      }
                    >
                      <span className={Classes.number}></span>
                      <div className="position-relative">
                        <img
                          className={Classes.userImg}
                          src={pendingFriendRequest?.user?.profilePicture}
                          alt="img"
                        />
                        <img
                          className={Classes.varified}
                          src={Varified}
                          alt="img"
                        />
                      </div>
                      <div>
                        <h3
                          className={Classes.title}
                        >{`${pendingFriendRequest?.user?.firstName} ${pendingFriendRequest?.user?.lastName}`}</h3>
                        <div className="d-flex align-items-center gap-2">
                          <span className={Classes.subTxt}>Shared Causes</span>
                          <div className="d-flex gap-50">
                            {pendingFriendRequest?.user?.categories
                              .slice(0, 3)
                              .map((item, key) => (
                                <Tooltip
                                  placement="top"
                                  title={item?.categoryName}
                                  key={key}
                                >
                                  <img
                                    className={Classes.friendImg}
                                    src={item?.categoryImage}
                                    alt="img"
                                  />
                                </Tooltip>
                              ))}
                            {pendingFriendRequest?.user?.categories.length >
                              3 && (
                              <Tooltip
                                placement="top"
                                title={`+${pendingFriendRequest?.user?.categories.length - 3} more`}
                              >
                                <div className={Classes.plusIcon}>
                                  +
                                  {pendingFriendRequest?.user?.categories
                                    .length - 3}
                                </div>
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex align-items-center gap-3">
                      <button
                        type="button"
                        className={`${Classes.btn} ${Classes.accept}`}
                        onClick={() =>
                          handleFriendRequest(
                            pendingFriendRequest?.userId,
                            "approved"
                          )
                        }
                      >
                        {loading[pendingFriendRequest?.userId] ? (
                          <div className="d-flex justify-content-center align-items-center">
                            <ThreeDots
                              height="30"
                              width="30"
                              radius="9"
                              color="white"
                              ariaLabel="three-dots-loading"
                              wrapperStyle={{}}
                              wrapperClassName=""
                              visible={true}
                            />
                          </div>
                        ) : (
                          "Accept"
                        )}
                      </button>
                      <button
                        onClick={() => {
                          showModal();
                          setRequestData({
                            friendId: pendingFriendRequest?.friendId,
                            requestStatus: "reject",
                          });
                        }}
                        type="button"
                        className={`${Classes.btn} ${Classes.reject}`}
                      >
                        Reject
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          ))
        ) : (
          <p style={{ marginLeft: "40%" }}>
            You don't have any friend requests yet
          </p>
        )}
      </Row>
      <Modal open={isModalOpen} footer={null} onCancel={handleCancel}>
        <div
          className={`${Classes.modalContent} d-flex justify-content-center align-items-center gap-3 flex-column py-2`}
        >
          <h1>Reject!</h1>
          <p className="mb-3">
            Are you sure you want to reject this person’s friend request?
          </p>
          <div className="d-flex gap-3">
            <button
              className={`${Classes.btn} ${Classes.accept}`}
              onClick={handleCancel}
            >
              No
            </button>
            <button
              className={`${Classes.btn} ${Classes.reject}`}
              onClick={() => rejection()}
            >
              {isRejectloading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <ThreeDots
                    height="30"
                    width="30"
                    radius="9"
                    color="red"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div>
              ) : (
                "Yes"
              )}
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default FriendRequests;
