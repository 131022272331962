// ** WEB3 Imports
import { useContractWrite, useWaitForTransaction } from "wagmi";
import CONTRACT_DETAILS from "../contract/contractInfo";

const marketContractAddress = CONTRACT_DETAILS.market.address;
const marketplaceABI = CONTRACT_DETAILS.market.abi;

const usePlaceBidForUser = () => {
  //approve contract
  const {
    isError: isPlaceBidUserSentError,
    data: placeBidUserSentTx,
    isLoading: isPlaceBidUserTxInProgress,
    isSuccess: isPlaceBidUserTxSent,
    write: placeBidForUser,
    error: placeBidUserSentError
  } = useContractWrite({
    address: marketContractAddress,
    abi: marketplaceABI,
    functionName: "startBid", // Changed the functionName to placeBid
  });

  const {
    isLoading: isPlaceBidUserWaiting,
    isSuccess: isPlaceBidUserCompleted,
    isError: isPlaceBidUserError,
    error: placeBidUserTxError,
    data: placeBidUserResp
  } = useWaitForTransaction({
    hash: placeBidUserSentTx?.hash,
  });

  return {
    //nft operation
    isPlaceBidUserSentError,
    placeBidUserSentTx,
    isPlaceBidUserTxInProgress,
    isPlaceBidUserTxSent,
    placeBidForUser, // Renamed the function
    placeBidUserSentError,
    isPlaceBidUserWaiting,
    isPlaceBidUserCompleted,
    isPlaceBidUserError,
    placeBidUserTxError,
    placeBidUserResp
  };
};

export default usePlaceBidForUser;
