import React, { useState } from "react";
import { Modal } from "antd";
import Classes from "../FriendsDetailsComp/friendsDetails.module.scss";
import { ThreeDots } from "react-loader-spinner";


const InProgressModal = ({ title, content, visible }) => {  

  return (
    <>
      <Modal
        open={visible}
        footer={null}
        closable={false}
      >
        <div
          className={`${Classes.modalContent} d-flex justify-content-center align-items-center gap-3 flex-column py-2`}
        >
          <h1>{title}</h1>
          <p className="mb-3" dangerouslySetInnerHTML={{ __html: content }} />
          <button
            className={`${Classes.btn} ${Classes.reject}`}
          >
            <div className="d-flex justify-content-center align-items-center">
                <ThreeDots
                    height="30"
                    width="30"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                />
                </div>
          </button>
        </div>
      </Modal>
    </>
  );
};

export default InProgressModal;