import React, { Fragment, useState } from 'react';
import Classes from './sponsorship.module.scss';
import { Modal } from 'antd';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ThreeDots } from 'react-loader-spinner';
import { sendInvitation } from '../../services/invites.service';
import { alertMessage } from '../../constants/alertMessage';

const SendInviteModal = ({ isModalOpen, handleCancel, getInvitesList }) => {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem('authenticationToken');

  const handleSendInvite = async (data, resetForm) => {
    try {
      setLoading(true);
      const requestData = { email: data?.email, token };
      const response = await sendInvitation(requestData);
      if (response?.status === 200) {
        resetForm({ values: '' });
        handleCancel();
        getInvitesList();
        alertMessage('success', `${response?.data?.message}`);
        setLoading(false);
      } else {
        alertMessage('error', `${response?.message}`);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      alertMessage('error', `${error}`);
      setLoading(false);
    }
  };
  return (
    <Modal open={isModalOpen} onCancel={handleCancel} footer={null}>
      <Formik
        initialValues={{
          email: '',
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Email is invalid.')
            .required('Email is required.'),
        })}
        onSubmit={(values, { resetForm }) => {
          const data = {
            email: values.email,
          };
          handleSendInvite(data, resetForm);
        }}
        textAlign='center'
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values,
        }) => (
          <form
            onSubmit={handleSubmit}
            className='mt-8 space-y-6'
            noValidate
            autoComplete='off'
          >
            <div className={`${Classes.addContent} py-2`}>
              <div className='mb-3'>
                <p className={Classes.label}>Email Address</p>
                <input
                  type='email'
                  name='email'
                  placeholder='Enter email'
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.email}
                />
                <span
                  className='mt-1 d-block'
                  size={'small'}
                  style={{ color: 'red' }}
                >
                  {errors.email && touched.email ? (
                    <Fragment>{errors.email}</Fragment>
                  ) : null}
                </span>
              </div>
              <div className='d-flex justify-content-center align-items-center gap-3'>
                <button
                  className={`${Classes.rejectbtn}`}
                  onClick={handleCancel}
                >
                  No
                </button>
                <button className={`${Classes.sendbtn}`} type='submit'>
                  {loading ? (
                    <div className='d-flex justify-content-center align-items-center'>
                      <ThreeDots
                        height='30'
                        width='30'
                        radius='9'
                        color='white'
                        ariaLabel='three-dots-loading'
                        wrapperStyle={{}}
                        wrapperClassName=''
                        visible={true}
                      />
                    </div>
                  ) : (
                    'Send Invite'
                  )}
                </button>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </Modal>
  );
};

export default SendInviteModal;
