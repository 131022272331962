import React, { useEffect, useState, useContext } from 'react';
import Classes from './invite.module.scss';
import { Button, Tooltip, Tag } from 'antd';
import { AiOutlinePlus } from 'react-icons/ai';
import DataTable from 'react-data-table-component';
import { CiCircleCheck } from 'react-icons/ci';
import { MdOutlineCancel } from 'react-icons/md';
import { BsArrowRepeat } from 'react-icons/bs';
import moment from 'moment';
import Search from '../../assets/images/icons/searchdark.svg';
import { invitesList, resendInvitation } from '../../services/invites.service';
import SendInviteModal from './SendInviteModal';
import RespondInviteModal from './RespondInviteModal';
import { alertMessage } from '../../constants/alertMessage';
import SocketContext from '../../context/socket';

const Invite = () => {
  const [isRespondModalOpen, setIsRespondModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [sendedInvites, setSendedInvites] = useState([]);
  const [statusType, setTtatusType] = useState('');
  const [respondRecord, setRespondRecord] = useState('');
  const [filterText, setFilterText] = useState('');
  const token = localStorage.getItem('authenticationToken');
  const socket = useContext(SocketContext);

  const getInvitesList = async () => {
    try {
      const data = await invitesList(token);
      if (data?.data?.success === true) {
        setSendedInvites(data?.data?.data?.sendedInvites);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getInvitesList();
  }, []);

  const showRespondModal = () => {
    setIsRespondModalOpen(true);
  };

  const handleRespondCancel = () => {
    setIsRespondModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleResendInvite = async (data) => {
    try {
      const requestData = {
        email: data?.receiverEmail,
        token,
      };
      const response = await resendInvitation(requestData);
      if (response?.data?.success === true) {
        alertMessage('success', `${response?.data?.message}`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      name: 'Email address',
      selector: (row) => row.receiverEmail,
    },
    {
      name: 'Status',
      cell: (row) => (
        <Tag
          color={
            row.status === 'accepted'
              ? 'green'
              : row.status === 'rejected'
              ? 'red'
              : 'orange'
          }
        >
          {row.status}
        </Tag>
      ),
    },
    {
      name: 'Sended date',
      selector: (row) => moment(row.createdAt).format('MMM DD, YYYY'),
    },
    // {
    //   name: 'Actions',
    //   cell: (row) => (
    //     <div>
    //       {row.status === 'pending' ? (
    //         <>
    //           <Tooltip title='Accept'>
    //             <Button
    //               type='primary'
    //               // loading={loadings[1]}
    //               onClick={() => {
    //                 handleEdit(row);
    //                 setMessage('Are you sure you want to accept this invite ?');
    //                 showRespondModal();
    //                 setTtatusType('accepted');
    //               }}
    //             >
    //               <CiCircleCheck />
    //             </Button>
    //           </Tooltip>
    //           &nbsp;&nbsp;
    //           <Tooltip title='Reject'>
    //             <Button
    //               type='danger'
    //               // loading={loadings[1]}
    //               onClick={() => {
    //                 handleDelete(row);
    //                 setMessage('Are you sure you want to reject this invite ?');
    //                 showRespondModal();
    //                 setTtatusType('rejected');
    //               }}
    //             >
    //               <MdOutlineCancel />
    //             </Button>
    //           </Tooltip>
    //           &nbsp;&nbsp;
    //           <Tooltip title='Resend'>
    //             <Button
    //               type='success'
    //               // loading={loadings[1]}
    //               onClick={() => handleResendInvite(row)}
    //             >
    //               <BsArrowRepeat />
    //             </Button>
    //           </Tooltip>
    //         </>
    //       ) : (
    //         ''
    //       )}
    //     </div>
    //   ),
    // },
  ];

  const filteredData =
    sendedInvites &&
    sendedInvites?.filter((item) =>
      item.receiverEmail.toLowerCase().includes(filterText.toLowerCase())
    );

  const handleChange = (e) => {
    setFilterText(e.target.value);
  };

  const handleEdit = (row) => {
    setRespondRecord(row);
  };

  const handleDelete = (row) => {
    setRespondRecord(row);
  };

  const customStyles = {
    headCells: {
      style: {
        fontWeight: 'bold', // Make the header text bold
        fontSize: 14,
        background: '#f1f1f1',
      },
    },
    table: {
      style: {
        border: '1px solid #ddd', // Add border around the whole table
      },
    },
  };

  useEffect(() => {
    if (socket) {
      socket.on('sendInviteNotification', (event) => {
        console.log('Socket event for sendInviteNotification', event);
      });
    }

    // socket.on('commentUpdated', (data: any) => {
    //   console.log('commentUpdated');
    // });
    // return () => {
    //   socket.off('new-comment');
    //   socket.off('commentUpdated');
    // };
  }, [socket]);

  return (
    <div className={`${Classes.invite}`}>
      <h1 className={Classes.title}>Friend Invite</h1>
      <div className='d-flex align-items-center justify-content-between flex-md-row flex-column my-3 gap-2'>
        <div className={Classes.inputOuter}>
          <img src={Search} alt='search' />
          <input placeholder='Search by email...' onChange={handleChange} />
        </div>
        <button onClick={showModal} className={Classes.addBtn}>
          <AiOutlinePlus />
          Add
        </button>
      </div>
      <DataTable
        columns={columns}
        customStyles={customStyles}
        data={filteredData}
        pagination
      />
      <SendInviteModal
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
        getInvitesList={getInvitesList}
      />
      <RespondInviteModal
        isRespondModalOpen={isRespondModalOpen}
        handleRespondCancel={handleRespondCancel}
        message={message}
        respondRecord={respondRecord}
        statusType={statusType}
        getInvitesList={getInvitesList}
      />
    </div>
  );
};

export default Invite;
