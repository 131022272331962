import { io } from 'socket.io-client';
import { ENV } from '../config';
let socket = null;

const connectSocket = () => {
  if (!socket) {
    const socketInstance = new io(ENV.socketUrl);
    // const socketInstance = new io(ENV?.serverUrl || 'http://localhost:7000');

    return socketInstance;
  }
};

export default { connectSocket, socket };
