import axios from 'axios';

const authenticationToken = localStorage.getItem('authenticationToken');

// export const nftListing = async () => {
//   try {
//     const ownerWalletAddress = localStorage.getItem('walletAddress');
//     const pinataResponse = await axios.get(
//       `${process.env.REACT_APP_API_BASE_URL}/nfts/user?ownerWalletAddress=${ownerWalletAddress}`,
//       {
//         headers: {
//           'Content-Type': 'application/json',
//           Authorization: `Bearer ${authenticationToken}`,
//         },
//       }
//     );
//     return pinataResponse;
//   } catch (error) {
//     return error.response.data;
//   }
// };

// export const processNftToPinata = async (data) => {
//   try {
//     const formData = new FormData();

//     formData.append('name', data?.name);
//     formData.append('external_link', data?.external_link);
//     formData.append('description', data?.description);

//     // Append the file if it exists
//     if (data?.file) {
//       formData.append('file', data.file);
//     }
//     const pinataResponse = await axios.post(
//       `${process.env.REACT_APP_API_BASE_URL}/nfts/pinata-upoload-nft`,
//       formData,
//       {
//         headers: {
//           'Content-Type': 'multipart/form-data',
//           Authorization: `Bearer ${authenticationToken}`,
//         },
//       }
//     );
//     return pinataResponse;
//   } catch (error) {
//     return error.response.data;
//   }
// };

export const createAuction = async (data) => {
  try {
    const auction = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/auction/create`,
      data,
      {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authenticationToken}`,
        },
      }
    );
    return auction;
  } catch (error) {
    return error.response.data;
  }
};
